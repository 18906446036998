import React from 'react';

export default () => (
  <svg
    width="29"
    height="27"
    viewBox="0 0 29 27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="yang"
      d="M14.5535 12.6562V0.875391C14.5535 0.400781 14.9152 0 15.3802 0C21.7714 0 26.9536 5.28926 26.9536 11.8125C26.9536 12.2871 26.561 12.6562 26.096 12.6562H14.5535ZM0.5 14.3438C0.5 7.94707 5.15522 2.65254 11.1951 1.80879C11.6705 1.74023 12.0735 2.13047 12.0735 2.6209V15.1875L20.1594 23.4404C20.5056 23.7937 20.4797 24.3738 20.0819 24.6586C18.0565 26.1352 15.5765 27 12.9001 27C6.05423 27 0.5 21.3363 0.5 14.3438ZM27.6976 15.1875C28.1781 15.1875 28.5553 15.5988 28.4933 16.084C28.0955 19.0318 26.7056 21.6527 24.6751 23.5881C24.3651 23.8834 23.8794 23.8623 23.5798 23.5512L15.3802 15.1875H27.6976Z"
    />
  </svg>
);
