export type CountryEntry = {
  label: string;
  value: {
    ISO3: string;
    ISO2: string;
  };
};

export const countries: Record<string, Array<CountryEntry>> = {
  fr_FR: [
    { label: 'Afghanistan', value: { ISO3: 'AFG', ISO2: 'AF' } },
    { label: 'Afrique du Sud', value: { ISO3: 'ZAF', ISO2: 'ZA' } },
    { label: 'Ahvenanmaa', value: { ISO3: 'ALA', ISO2: 'AX' } },
    { label: 'Albanie', value: { ISO3: 'ALB', ISO2: 'AL' } },
    { label: 'Algérie', value: { ISO3: 'DZA', ISO2: 'DZ' } },
    { label: 'Allemagne', value: { ISO3: 'DEU', ISO2: 'DE' } },
    { label: 'Andorre', value: { ISO3: 'AND', ISO2: 'AD' } },
    { label: 'Angola', value: { ISO3: 'AGO', ISO2: 'AO' } },
    { label: 'Anguilla', value: { ISO3: 'AIA', ISO2: 'AI' } },
    { label: 'Antarctique', value: { ISO3: 'ATA', ISO2: 'AQ' } },
    { label: 'Antigua-et-Barbuda', value: { ISO3: 'ATG', ISO2: 'AG' } },
    { label: 'Arabie Saoudite', value: { ISO3: 'SAU', ISO2: 'SA' } },
    { label: 'Argentine', value: { ISO3: 'ARG', ISO2: 'AR' } },
    { label: 'Arménie', value: { ISO3: 'ARM', ISO2: 'AM' } },
    { label: 'Aruba', value: { ISO3: 'ABW', ISO2: 'AW' } },
    { label: 'Australie', value: { ISO3: 'AUS', ISO2: 'AU' } },
    { label: 'Autriche', value: { ISO3: 'AUT', ISO2: 'AT' } },
    { label: 'Azerbaïdjan', value: { ISO3: 'AZE', ISO2: 'AZ' } },
    { label: 'Bahamas', value: { ISO3: 'BHS', ISO2: 'BS' } },
    { label: 'Bahreïn', value: { ISO3: 'BHR', ISO2: 'BH' } },
    { label: 'Bangladesh', value: { ISO3: 'BGD', ISO2: 'BD' } },
    { label: 'Barbade', value: { ISO3: 'BRB', ISO2: 'BB' } },
    { label: 'Belgique', value: { ISO3: 'BEL', ISO2: 'BE' } },
    { label: 'Belize', value: { ISO3: 'BLZ', ISO2: 'BZ' } },
    { label: 'Bermudes', value: { ISO3: 'BMU', ISO2: 'BM' } },
    { label: 'Bhoutan', value: { ISO3: 'BTN', ISO2: 'BT' } },
    { label: 'Birmanie', value: { ISO3: 'MMR', ISO2: 'MM' } },
    { label: 'Biélorussie', value: { ISO3: 'BLR', ISO2: 'BY' } },
    { label: 'Bolivie', value: { ISO3: 'BOL', ISO2: 'BO' } },
    { label: 'Bosnie-Herzégovine', value: { ISO3: 'BIH', ISO2: 'BA' } },
    { label: 'Botswana', value: { ISO3: 'BWA', ISO2: 'BW' } },
    { label: 'Brunei', value: { ISO3: 'BRN', ISO2: 'BN' } },
    { label: 'Brésil', value: { ISO3: 'BRA', ISO2: 'BR' } },
    { label: 'Bulgarie', value: { ISO3: 'BGR', ISO2: 'BG' } },
    { label: 'Burkina Faso', value: { ISO3: 'BFA', ISO2: 'BF' } },
    { label: 'Burundi', value: { ISO3: 'BDI', ISO2: 'BI' } },
    { label: 'Bénin', value: { ISO3: 'BEN', ISO2: 'BJ' } },
    { label: 'Cambodge', value: { ISO3: 'KHM', ISO2: 'KH' } },
    { label: 'Cameroun', value: { ISO3: 'CMR', ISO2: 'CM' } },
    { label: 'Canada', value: { ISO3: 'CAN', ISO2: 'CA' } },
    { label: 'Chili', value: { ISO3: 'CHL', ISO2: 'CL' } },
    { label: 'Chine', value: { ISO3: 'CHN', ISO2: 'CN' } },
    { label: 'Chypre', value: { ISO3: 'CYP', ISO2: 'CY' } },
    { label: 'Cité du Vatican', value: { ISO3: 'VAT', ISO2: 'VA' } },
    { label: 'Colombie', value: { ISO3: 'COL', ISO2: 'CO' } },
    { label: 'Comores', value: { ISO3: 'COM', ISO2: 'KM' } },
    { label: 'Congo', value: { ISO3: 'COG', ISO2: 'CG' } },
    { label: 'Congo (Rép. dém.)', value: { ISO3: 'COD', ISO2: 'CD' } },
    { label: 'Corée du Nord', value: { ISO3: 'PRK', ISO2: 'KP' } },
    { label: 'Corée du Sud', value: { ISO3: 'KOR', ISO2: 'KR' } },
    { label: 'Costa Rica', value: { ISO3: 'CRI', ISO2: 'CR' } },
    { label: 'Croatie', value: { ISO3: 'HRV', ISO2: 'HR' } },
    { label: 'Cuba', value: { ISO3: 'CUB', ISO2: 'CU' } },
    { label: 'Curaçao', value: { ISO3: 'CUW', ISO2: 'CW' } },
    { label: "Côte d'Ivoire", value: { ISO3: 'CIV', ISO2: 'CI' } },
    { label: 'Danemark', value: { ISO3: 'DNK', ISO2: 'DK' } },
    { label: 'Djibouti', value: { ISO3: 'DJI', ISO2: 'DJ' } },
    { label: 'Dominique', value: { ISO3: 'DMA', ISO2: 'DM' } },
    { label: 'Espagne', value: { ISO3: 'ESP', ISO2: 'ES' } },
    { label: 'Estonie', value: { ISO3: 'EST', ISO2: 'EE' } },
    { label: 'Fidji', value: { ISO3: 'FJI', ISO2: 'FJ' } },
    { label: 'Finlande', value: { ISO3: 'FIN', ISO2: 'FI' } },
    { label: 'France', value: { ISO3: 'FRA', ISO2: 'FR' } },
    { label: 'Gabon', value: { ISO3: 'GAB', ISO2: 'GA' } },
    { label: 'Gambie', value: { ISO3: 'GMB', ISO2: 'GM' } },
    { label: 'Ghana', value: { ISO3: 'GHA', ISO2: 'GH' } },
    { label: 'Gibraltar', value: { ISO3: 'GIB', ISO2: 'GI' } },
    { label: 'Grenade', value: { ISO3: 'GRD', ISO2: 'GD' } },
    { label: 'Groenland', value: { ISO3: 'GRL', ISO2: 'GL' } },
    { label: 'Grèce', value: { ISO3: 'GRC', ISO2: 'GR' } },
    { label: 'Guadeloupe', value: { ISO3: 'GLP', ISO2: 'GP' } },
    { label: 'Guam', value: { ISO3: 'GUM', ISO2: 'GU' } },
    { label: 'Guatemala', value: { ISO3: 'GTM', ISO2: 'GT' } },
    { label: 'Guernesey', value: { ISO3: 'GGY', ISO2: 'GG' } },
    { label: 'Guinée', value: { ISO3: 'GIN', ISO2: 'GN' } },
    { label: 'Guinée équatoriale', value: { ISO3: 'GNQ', ISO2: 'GQ' } },
    { label: 'Guinée-Bissau', value: { ISO3: 'GNB', ISO2: 'GW' } },
    { label: 'Guyana', value: { ISO3: 'GUY', ISO2: 'GY' } },
    { label: 'Guyane', value: { ISO3: 'GUF', ISO2: 'GF' } },
    { label: 'Géorgie', value: { ISO3: 'GEO', ISO2: 'GE' } },
    {
      label: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
      value: { ISO3: 'SGS', ISO2: 'GS' },
    },
    { label: 'Haïti', value: { ISO3: 'HTI', ISO2: 'HT' } },
    { label: 'Honduras', value: { ISO3: 'HND', ISO2: 'HN' } },
    { label: 'Hong Kong', value: { ISO3: 'HKG', ISO2: 'HK' } },
    { label: 'Hongrie', value: { ISO3: 'HUN', ISO2: 'HU' } },
    { label: 'Inde', value: { ISO3: 'IND', ISO2: 'IN' } },
    { label: 'Indonésie', value: { ISO3: 'IDN', ISO2: 'ID' } },
    { label: 'Irak', value: { ISO3: 'IRQ', ISO2: 'IQ' } },
    { label: 'Iran', value: { ISO3: 'IRN', ISO2: 'IR' } },
    { label: 'Irlande', value: { ISO3: 'IRL', ISO2: 'IE' } },
    { label: 'Islande', value: { ISO3: 'ISL', ISO2: 'IS' } },
    { label: 'Israël', value: { ISO3: 'ISR', ISO2: 'IL' } },
    { label: 'Italie', value: { ISO3: 'ITA', ISO2: 'IT' } },
    { label: 'Jamaïque', value: { ISO3: 'JAM', ISO2: 'JM' } },
    { label: 'Japon', value: { ISO3: 'JPN', ISO2: 'JP' } },
    { label: 'Jersey', value: { ISO3: 'JEY', ISO2: 'JE' } },
    { label: 'Jordanie', value: { ISO3: 'JOR', ISO2: 'JO' } },
    { label: 'Kazakhstan', value: { ISO3: 'KAZ', ISO2: 'KZ' } },
    { label: 'Kenya', value: { ISO3: 'KEN', ISO2: 'KE' } },
    { label: 'Kirghizistan', value: { ISO3: 'KGZ', ISO2: 'KG' } },
    { label: 'Kiribati', value: { ISO3: 'KIR', ISO2: 'KI' } },
    { label: 'Kosovo', value: { ISO3: 'XKX', ISO2: 'XK' } },
    { label: 'Koweït', value: { ISO3: 'KWT', ISO2: 'KW' } },
    { label: 'Laos', value: { ISO3: 'LAO', ISO2: 'LA' } },
    { label: 'Lesotho', value: { ISO3: 'LSO', ISO2: 'LS' } },
    { label: 'Lettonie', value: { ISO3: 'LVA', ISO2: 'LV' } },
    { label: 'Lebanon', value: { ISO3: 'LBN', ISO2: 'LB' } },
    { label: 'Liban', value: { ISO3: 'LBN', ISO2: 'LB' } },
    { label: 'Liberia', value: { ISO3: 'LBR', ISO2: 'LR' } },
    { label: 'Libye', value: { ISO3: 'LBY', ISO2: 'LY' } },
    { label: 'Liechtenstein', value: { ISO3: 'LIE', ISO2: 'LI' } },
    { label: 'Lituanie', value: { ISO3: 'LTU', ISO2: 'LT' } },
    { label: 'Luxembourg', value: { ISO3: 'LUX', ISO2: 'LU' } },
    { label: 'Macédoine du Nord', value: { ISO3: 'MKD', ISO2: 'MK' } },
    { label: 'Madagascar', value: { ISO3: 'MDG', ISO2: 'MG' } },
    { label: 'Malaisie', value: { ISO3: 'MYS', ISO2: 'MY' } },
    { label: 'Malawi', value: { ISO3: 'MWI', ISO2: 'MW' } },
    { label: 'Maldives', value: { ISO3: 'MDV', ISO2: 'MV' } },
    { label: 'Mali', value: { ISO3: 'MLI', ISO2: 'ML' } },
    { label: 'Malte', value: { ISO3: 'MLT', ISO2: 'MT' } },
    { label: 'Man', value: { ISO3: 'IMN', ISO2: 'IM' } },
    { label: 'Maroc', value: { ISO3: 'MAR', ISO2: 'MA' } },
    { label: 'Marshall', value: { ISO3: 'MHL', ISO2: 'MH' } },
    { label: 'Martinique', value: { ISO3: 'MTQ', ISO2: 'MQ' } },
    { label: 'Maurice', value: { ISO3: 'MUS', ISO2: 'MU' } },
    { label: 'Mauritanie', value: { ISO3: 'MRT', ISO2: 'MR' } },
    { label: 'Mayotte', value: { ISO3: 'MYT', ISO2: 'YT' } },
    { label: 'Mexique', value: { ISO3: 'MEX', ISO2: 'MX' } },
    { label: 'Micronésie', value: { ISO3: 'FSM', ISO2: 'FM' } },
    { label: 'Moldavie', value: { ISO3: 'MDA', ISO2: 'MD' } },
    { label: 'Monaco', value: { ISO3: 'MCO', ISO2: 'MC' } },
    { label: 'Mongolie', value: { ISO3: 'MNG', ISO2: 'MN' } },
    { label: 'Monténégro', value: { ISO3: 'MNE', ISO2: 'ME' } },
    { label: 'Montserrat', value: { ISO3: 'MSR', ISO2: 'MS' } },
    { label: 'Mozambique', value: { ISO3: 'MOZ', ISO2: 'MZ' } },
    { label: 'Namibie', value: { ISO3: 'NAM', ISO2: 'NA' } },
    { label: 'Nauru', value: { ISO3: 'NRU', ISO2: 'NR' } },
    { label: 'Népal', value: { ISO3: 'NPL', ISO2: 'NP' } },
    { label: 'Nicaragua', value: { ISO3: 'NIC', ISO2: 'NI' } },
    { label: 'Niger', value: { ISO3: 'NER', ISO2: 'NE' } },
    { label: 'Nigeria', value: { ISO3: 'NGA', ISO2: 'NG' } },
    { label: 'Niue', value: { ISO3: 'NIU', ISO2: 'NU' } },
    { label: 'Norvège', value: { ISO3: 'NOR', ISO2: 'NO' } },
    { label: 'Nouvelle-Calédonie', value: { ISO3: 'NCL', ISO2: 'NC' } },
    { label: 'Nouvelle-Zélande', value: { ISO3: 'NZL', ISO2: 'NZ' } },
    { label: 'Oman', value: { ISO3: 'OMN', ISO2: 'OM' } },
    { label: 'Ouganda', value: { ISO3: 'UGA', ISO2: 'UG' } },
    { label: 'Ouzbékistan', value: { ISO3: 'UZB', ISO2: 'UZ' } },
    { label: 'Pakistan', value: { ISO3: 'PAK', ISO2: 'PK' } },
    { label: 'Palau', value: { ISO3: 'PLW', ISO2: 'PW' } },
    { label: 'Palestine', value: { ISO3: 'PSE', ISO2: 'PS' } },
    { label: 'Panama', value: { ISO3: 'PAN', ISO2: 'PA' } },
    { label: 'Papouasie-Nouvelle-Guinée', value: { ISO3: 'PNG', ISO2: 'PG' } },
    { label: 'Paraguay', value: { ISO3: 'PRY', ISO2: 'PY' } },
    { label: 'Pays-Bas', value: { ISO3: 'NLD', ISO2: 'NL' } },
    { label: 'Pérou', value: { ISO3: 'PER', ISO2: 'PE' } },
    { label: 'Philippines', value: { ISO3: 'PHL', ISO2: 'PH' } },
    { label: 'Pologne', value: { ISO3: 'POL', ISO2: 'PL' } },
    { label: 'Polynésie française', value: { ISO3: 'PYF', ISO2: 'PF' } },
    { label: 'Porto Rico', value: { ISO3: 'PRI', ISO2: 'PR' } },
    { label: 'Portugal', value: { ISO3: 'PRT', ISO2: 'PT' } },
    { label: 'Qatar', value: { ISO3: 'QAT', ISO2: 'QA' } },
    { label: 'République centrafricaine', value: { ISO3: 'CAF', ISO2: 'CF' } },
    { label: 'République dominicaine', value: { ISO3: 'DOM', ISO2: 'DO' } },
    { label: 'République tchèque', value: { ISO3: 'CZE', ISO2: 'CZ' } },
    { label: 'Roumanie', value: { ISO3: 'ROU', ISO2: 'RO' } },
    { label: 'Royaume-Uni', value: { ISO3: 'GBR', ISO2: 'GB' } },
    { label: 'Russie', value: { ISO3: 'RUS', ISO2: 'RU' } },
    { label: 'Rwanda', value: { ISO3: 'RWA', ISO2: 'RW' } },
    { label: 'Saint-Christophe-et-Niévès', value: { ISO3: 'KNA', ISO2: 'KN' } },
    { label: 'Saint-Marin', value: { ISO3: 'SMR', ISO2: 'SM' } },
    {
      label: 'Saint-Vincent-et-les-Grenadines',
      value: { ISO3: 'VCT', ISO2: 'VC' },
    },
    { label: 'Sainte-Lucie', value: { ISO3: 'LCA', ISO2: 'LC' } },
    { label: 'Saint-Pierre-et-Miquelon', value: { ISO3: 'SPM', ISO2: 'PM' } },
    { label: 'Samoa', value: { ISO3: 'WSM', ISO2: 'WS' } },
    { label: 'Samoa américaines', value: { ISO3: 'ASM', ISO2: 'AS' } },
    { label: 'Sao Tomé-et-Principe', value: { ISO3: 'STP', ISO2: 'ST' } },
    { label: 'Sénégal', value: { ISO3: 'SEN', ISO2: 'SN' } },
    { label: 'Serbie', value: { ISO3: 'SRB', ISO2: 'RS' } },
    { label: 'Seychelles', value: { ISO3: 'SYC', ISO2: 'SC' } },
    { label: 'Sierra Leone', value: { ISO3: 'SLE', ISO2: 'SL' } },
    { label: 'Singapour', value: { ISO3: 'SGP', ISO2: 'SG' } },
    { label: 'Slovaquie', value: { ISO3: 'SVK', ISO2: 'SK' } },
    { label: 'Slovénie', value: { ISO3: 'SVN', ISO2: 'SI' } },
    { label: 'Somalie', value: { ISO3: 'SOM', ISO2: 'SO' } },
    { label: 'Soudan', value: { ISO3: 'SDN', ISO2: 'SD' } },
    { label: 'Soudan du Sud', value: { ISO3: 'SSD', ISO2: 'SS' } },
    { label: 'Sri Lanka', value: { ISO3: 'LKA', ISO2: 'LK' } },
    { label: 'Suède', value: { ISO3: 'SWE', ISO2: 'SE' } },
    { label: 'Suisse', value: { ISO3: 'CHE', ISO2: 'CH' } },
    { label: 'Suriname', value: { ISO3: 'SUR', ISO2: 'SR' } },
    { label: 'Svalbard et Île Jan Mayen', value: { ISO3: 'SJM', ISO2: 'SJ' } },
    { label: 'Swaziland', value: { ISO3: 'SWZ', ISO2: 'SZ' } },
    { label: 'Syrie', value: { ISO3: 'SYR', ISO2: 'SY' } },
    { label: 'Tadjikistan', value: { ISO3: 'TJK', ISO2: 'TJ' } },
    { label: 'Taïwan', value: { ISO3: 'TWN', ISO2: 'TW' } },
    { label: 'Tanzanie', value: { ISO3: 'TZA', ISO2: 'TZ' } },
    { label: 'Tchad', value: { ISO3: 'TCD', ISO2: 'TD' } },
    { label: 'Thaïlande', value: { ISO3: 'THA', ISO2: 'TH' } },
    { label: 'Timor oriental', value: { ISO3: 'TLS', ISO2: 'TL' } },
    { label: 'Togo', value: { ISO3: 'TGO', ISO2: 'TG' } },
    { label: 'Tokelau', value: { ISO3: 'TKL', ISO2: 'TK' } },
    { label: 'Tonga', value: { ISO3: 'TON', ISO2: 'TO' } },
    { label: 'Trinité-et-Tobago', value: { ISO3: 'TTO', ISO2: 'TT' } },
    { label: 'Tunisie', value: { ISO3: 'TUN', ISO2: 'TN' } },
    { label: 'Turkménistan', value: { ISO3: 'TKM', ISO2: 'TM' } },
    { label: 'Turquie', value: { ISO3: 'TUR', ISO2: 'TR' } },
    { label: 'Tuvalu', value: { ISO3: 'TUV', ISO2: 'TV' } },
    { label: 'Ukraine', value: { ISO3: 'UKR', ISO2: 'UA' } },
    { label: 'Uruguay', value: { ISO3: 'URY', ISO2: 'UY' } },
    { label: 'Vanuatu', value: { ISO3: 'VUT', ISO2: 'VU' } },
    { label: 'Vatican', value: { ISO3: 'VAT', ISO2: 'VA' } },
    { label: 'Venezuela', value: { ISO3: 'VEN', ISO2: 'VE' } },
    { label: 'Viêt Nam', value: { ISO3: 'VNM', ISO2: 'VN' } },
    { label: 'Wallis-et-Futuna', value: { ISO3: 'WLF', ISO2: 'WF' } },
    { label: 'Yémen', value: { ISO3: 'YEM', ISO2: 'YE' } },
    { label: 'Zambie', value: { ISO3: 'ZMB', ISO2: 'ZM' } },
    { label: 'Zimbabwe', value: { ISO3: 'ZWE', ISO2: 'ZW' } },
  ],
  en_EN: [
    { label: 'Afghanistan', value: { ISO3: 'AFG', ISO2: 'AF' } },
    { label: 'Albania', value: { ISO3: 'ALB', ISO2: 'AL' } },
    { label: 'Algeria', value: { ISO3: 'DZA', ISO2: 'DZ' } },
    { label: 'American Samoa', value: { ISO3: 'ASM', ISO2: 'AS' } },
    { label: 'Andorra', value: { ISO3: 'AND', ISO2: 'AD' } },
    { label: 'Angola', value: { ISO3: 'AGO', ISO2: 'AO' } },
    { label: 'Anguilla', value: { ISO3: 'AIA', ISO2: 'AI' } },
    { label: 'Antarctica', value: { ISO3: 'ATA', ISO2: 'AQ' } },
    { label: 'Antigua and Barbuda', value: { ISO3: 'ATG', ISO2: 'AG' } },
    { label: 'Argentina', value: { ISO3: 'ARG', ISO2: 'AR' } },
    { label: 'Armenia', value: { ISO3: 'ARM', ISO2: 'AM' } },
    { label: 'Aruba', value: { ISO3: 'ABW', ISO2: 'AW' } },
    { label: 'Australia', value: { ISO3: 'AUS', ISO2: 'AU' } },
    { label: 'Austria', value: { ISO3: 'AUT', ISO2: 'AT' } },
    { label: 'Azerbaijan', value: { ISO3: 'AZE', ISO2: 'AZ' } },
    { label: 'Bahamas', value: { ISO3: 'BHS', ISO2: 'BS' } },
    { label: 'Bahrain', value: { ISO3: 'BHR', ISO2: 'BH' } },
    { label: 'Bangladesh', value: { ISO3: 'BGD', ISO2: 'BD' } },
    { label: 'Barbados', value: { ISO3: 'BRB', ISO2: 'BB' } },
    { label: 'Belarus', value: { ISO3: 'BLR', ISO2: 'BY' } },
    { label: 'Belgium', value: { ISO3: 'BEL', ISO2: 'BE' } },
    { label: 'Belize', value: { ISO3: 'BLZ', ISO2: 'BZ' } },
    { label: 'Benin', value: { ISO3: 'BEN', ISO2: 'BJ' } },
    { label: 'Bermuda', value: { ISO3: 'BMU', ISO2: 'BM' } },
    { label: 'Bhutan', value: { ISO3: 'BTN', ISO2: 'BT' } },
    { label: 'Bolivia', value: { ISO3: 'BOL', ISO2: 'BO' } },
    { label: 'Bosnia and Herzegovina', value: { ISO3: 'BIH', ISO2: 'BA' } },
    { label: 'Botswana', value: { ISO3: 'BWA', ISO2: 'BW' } },
    { label: 'Bouvet Island', value: { ISO3: 'BVT', ISO2: 'BV' } },
    { label: 'Brazil', value: { ISO3: 'BRA', ISO2: 'BR' } },
    {
      label: 'British Indian Ocean Territory',
      value: { ISO3: 'IOT', ISO2: 'IO' },
    },
    { label: 'Brunei Darussalam', value: { ISO3: 'BRN', ISO2: 'BN' } },
    { label: 'Bulgaria', value: { ISO3: 'BGR', ISO2: 'BG' } },
    { label: 'Burkina Faso', value: { ISO3: 'BFA', ISO2: 'BF' } },
    { label: 'Burundi', value: { ISO3: 'BDI', ISO2: 'BI' } },
    { label: 'Cambodia', value: { ISO3: 'KHM', ISO2: 'KH' } },
    { label: 'Cameroon', value: { ISO3: 'CMR', ISO2: 'CM' } },
    { label: 'Canada', value: { ISO3: 'CAN', ISO2: 'CA' } },
    { label: 'Cape Verde', value: { ISO3: 'CPV', ISO2: 'CV' } },
    { label: 'Cayman Islands', value: { ISO3: 'CYM', ISO2: 'KY' } },
    { label: 'Central African Republic', value: { ISO3: 'CAF', ISO2: 'CF' } },
    { label: 'Chad', value: { ISO3: 'TCD', ISO2: 'TD' } },
    { label: 'Chile', value: { ISO3: 'CHL', ISO2: 'CL' } },
    { label: 'China', value: { ISO3: 'CHN', ISO2: 'CN' } },
    { label: 'Christmas Island', value: { ISO3: 'CXR', ISO2: 'CX' } },
    { label: 'Cocos (Keeling) Islands', value: { ISO3: 'CCK', ISO2: 'CC' } },
    { label: 'Colombia', value: { ISO3: 'COL', ISO2: 'CO' } },
    { label: 'Comoros', value: { ISO3: 'COM', ISO2: 'KM' } },
    { label: 'Congo', value: { ISO3: 'COG', ISO2: 'CG' } },
    {
      label: 'Congo, The Democratic Republic of the',
      value: { ISO3: 'COD', ISO2: 'CD' },
    },
    { label: 'Cook Islands', value: { ISO3: 'COK', ISO2: 'CK' } },
    { label: 'Costa Rica', value: { ISO3: 'CRI', ISO2: 'CR' } },
    { label: "Cote D'Ivoire", value: { ISO3: 'CIV', ISO2: 'CI' } },
    { label: 'Croatia', value: { ISO3: 'HRV', ISO2: 'HR' } },
    { label: 'Cuba', value: { ISO3: 'CUB', ISO2: 'CU' } },
    { label: 'Cyprus', value: { ISO3: 'CYP', ISO2: 'CY' } },
    { label: 'Czech Republic', value: { ISO3: 'CZE', ISO2: 'CZ' } },
    { label: 'Denmark', value: { ISO3: 'DNK', ISO2: 'DK' } },
    { label: 'Djibouti', value: { ISO3: 'DJI', ISO2: 'DJ' } },
    { label: 'Dominica', value: { ISO3: 'DMA', ISO2: 'DM' } },
    { label: 'Dominican Republic', value: { ISO3: 'DOM', ISO2: 'DO' } },
    { label: 'Ecuador', value: { ISO3: 'ECU', ISO2: 'EC' } },
    { label: 'Egypt', value: { ISO3: 'EGY', ISO2: 'EG' } },
    { label: 'El Salvador', value: { ISO3: 'SLV', ISO2: 'SV' } },
    { label: 'Equatorial Guinea', value: { ISO3: 'GNQ', ISO2: 'GQ' } },
    { label: 'Eritrea', value: { ISO3: 'ERI', ISO2: 'ER' } },
    { label: 'Estonia', value: { ISO3: 'EST', ISO2: 'EE' } },
    { label: 'Ethiopia', value: { ISO3: 'ETH', ISO2: 'ET' } },
    {
      label: 'Falkland Islands (Malvinas)',
      value: { ISO3: 'FLK', ISO2: 'FK' },
    },
    { label: 'Faroe Islands', value: { ISO3: 'FRO', ISO2: 'FO' } },
    { label: 'Fiji', value: { ISO3: 'FJI', ISO2: 'FJ' } },
    { label: 'Finland', value: { ISO3: 'FIN', ISO2: 'FI' } },
    { label: 'France', value: { ISO3: 'FRA', ISO2: 'FR' } },

    { label: 'French Guiana', value: { ISO3: 'GUF', ISO2: 'GF' } },
    { label: 'French Polynesia', value: { ISO3: 'PYF', ISO2: 'PF' } },
    {
      label: 'French Southern Territories',
      value: { ISO3: 'ATF', ISO2: 'TF' },
    },
    { label: 'Gabon', value: { ISO3: 'GAB', ISO2: 'GA' } },
    { label: 'Gambia', value: { ISO3: 'GMB', ISO2: 'GM' } },
    { label: 'Georgia', value: { ISO3: 'GEO', ISO2: 'GE' } },
    { label: 'Germany', value: { ISO3: 'DEU', ISO2: 'DE' } },
    { label: 'Ghana', value: { ISO3: 'GHA', ISO2: 'GH' } },
    { label: 'Gibraltar', value: { ISO3: 'GIB', ISO2: 'GI' } },
    { label: 'Greece', value: { ISO3: 'GRC', ISO2: 'GR' } },
    { label: 'Greenland', value: { ISO3: 'GRL', ISO2: 'GL' } },
    { label: 'Grenada', value: { ISO3: 'GRD', ISO2: 'GD' } },
    { label: 'Guadeloupe', value: { ISO3: 'GLP', ISO2: 'GP' } },
    { label: 'Guam', value: { ISO3: 'GUM', ISO2: 'GU' } },
    { label: 'Guatemala', value: { ISO3: 'GTM', ISO2: 'GT' } },
    { label: 'Guernsey', value: { ISO3: 'GGY', ISO2: 'GG' } },
    { label: 'Guinea', value: { ISO3: 'GIN', ISO2: 'GN' } },
    { label: 'Guinea-Bissau', value: { ISO3: 'GNB', ISO2: 'GW' } },
    { label: 'Guyana', value: { ISO3: 'GUY', ISO2: 'GY' } },
    { label: 'Haiti', value: { ISO3: 'HTI', ISO2: 'HT' } },
    {
      label: 'Heard Island and Mcdonald Islands',
      value: { ISO3: 'HMD', ISO2: 'HM' },
    },

    {
      label: 'Holy See (Vatican City State)',
      value: { ISO3: 'VAT', ISO2: 'VA' },
    },
    { label: 'Honduras', value: { ISO3: 'HND', ISO2: 'HN' } },
    { label: 'Hong Kong', value: { ISO3: 'HKG', ISO2: 'HK' } },
    { label: 'Hungary', value: { ISO3: 'HUN', ISO2: 'HU' } },
    { label: 'Iceland', value: { ISO3: 'ISL', ISO2: 'IS' } },
    { label: 'India', value: { ISO3: 'IND', ISO2: 'IN' } },
    { label: 'Indonesia', value: { ISO3: 'IDN', ISO2: 'ID' } },
    { label: 'Iran, Islamic Republic of', value: { ISO3: 'IRN', ISO2: 'IR' } },
    { label: 'Iraq', value: { ISO3: 'IRQ', ISO2: 'IQ' } },
    { label: 'Ireland', value: { ISO3: 'IRL', ISO2: 'IE' } },
    { label: 'Isle of Man', value: { ISO3: 'IMN', ISO2: 'IM' } },
    { label: 'Israel', value: { ISO3: 'ISR', ISO2: 'IL' } },
    { label: 'Italy', value: { ISO3: 'ITA', ISO2: 'IT' } },
    { label: 'Jamaica', value: { ISO3: 'JAM', ISO2: 'JM' } },
    { label: 'Japan', value: { ISO3: 'JPN', ISO2: 'JP' } },
    { label: 'Jersey', value: { ISO3: 'JEY', ISO2: 'JE' } },
    { label: 'Jordan', value: { ISO3: 'JOR', ISO2: 'JO' } },
    { label: 'Kazakhstan', value: { ISO3: 'KAZ', ISO2: 'KZ' } },
    { label: 'Kenya', value: { ISO3: 'KEN', ISO2: 'KE' } },
    { label: 'Kiribati', value: { ISO3: 'KIR', ISO2: 'KI' } },
    {
      label: "Korea, Democratic People's Republic of",
      value: { ISO3: 'PRK', ISO2: 'KP' },
    },
    { label: 'Korea, Republic of', value: { ISO3: 'KOR', ISO2: 'KR' } },
    { label: 'Kuwait', value: { ISO3: 'KWT', ISO2: 'KW' } },
    { label: 'Kyrgyzstan', value: { ISO3: 'KGZ', ISO2: 'KG' } },
    {
      label: "Lao People's Democratic Republic",
      value: { ISO3: 'LAO', ISO2: 'LA' },
    },
    { label: 'Latvia', value: { ISO3: 'LVA', ISO2: 'LV' } },
    { label: 'Lebanon', value: { ISO3: 'LBN', ISO2: 'LB' } },
    { label: 'Lesotho', value: { ISO3: 'LSO', ISO2: 'LS' } },
    { label: 'Liberia', value: { ISO3: 'LBR', ISO2: 'LR' } },
    { label: 'Libyan Arab Jamahiriya', value: { ISO3: 'LBY', ISO2: 'LY' } },
    { label: 'Liechtenstein', value: { ISO3: 'LIE', ISO2: 'LI' } },
    { label: 'Lithuania', value: { ISO3: 'LTU', ISO2: 'LT' } },
    { label: 'Luxembourg', value: { ISO3: 'LUX', ISO2: 'LU' } },
    { label: 'Macao', value: { ISO3: 'MAC', ISO2: 'MO' } },
    {
      label: 'Macedonia, The Former Yugoslav Republic of',
      value: { ISO3: 'MKD', ISO2: 'MK' },
    },
    { label: 'Madagascar', value: { ISO3: 'MDG', ISO2: 'MG' } },
    { label: 'Malawi', value: { ISO3: 'MWI', ISO2: 'MW' } },
    { label: 'Malaysia', value: { ISO3: 'MYS', ISO2: 'MY' } },
    { label: 'Maldives', value: { ISO3: 'MDV', ISO2: 'MV' } },
    { label: 'Mali', value: { ISO3: 'MLI', ISO2: 'ML' } },
    { label: 'Malta', value: { ISO3: 'MLT', ISO2: 'MT' } },
    { label: 'Marshall Islands', value: { ISO3: 'MHL', ISO2: 'MH' } },
    { label: 'Martinique', value: { ISO3: 'MTQ', ISO2: 'MQ' } },
    { label: 'Mauritania', value: { ISO3: 'MRT', ISO2: 'MR' } },
    { label: 'Mauritius', value: { ISO3: 'MUS', ISO2: 'MU' } },
    { label: 'Mayotte', value: { ISO3: 'MYT', ISO2: 'YT' } },
    { label: 'Mexico', value: { ISO3: 'MEX', ISO2: 'MX' } },
    {
      label: 'Micronesia, Federated States of',
      value: { ISO3: 'FSM', ISO2: 'FM' },
    },
    { label: 'Moldova, Republic of', value: { ISO3: 'MDA', ISO2: 'MD' } },
    { label: 'Monaco', value: { ISO3: 'MCO', ISO2: 'MC' } },
    { label: 'Mongolia', value: { ISO3: 'MNG', ISO2: 'MN' } },
    { label: 'Montenegro', value: { ISO3: 'MNE', ISO2: 'ME' } },
    { label: 'Montserrat', value: { ISO3: 'MSR', ISO2: 'MS' } },
    { label: 'Morocco', value: { ISO3: 'MAR', ISO2: 'MA' } },
    { label: 'Mozambique', value: { ISO3: 'MOZ', ISO2: 'MZ' } },
    { label: 'Myanmar', value: { ISO3: 'MMR', ISO2: 'MM' } },
    { label: 'Namibia', value: { ISO3: 'NAM', ISO2: 'NA' } },
    { label: 'Nauru', value: { ISO3: 'NRU', ISO2: 'NR' } },
    { label: 'Nepal', value: { ISO3: 'NPL', ISO2: 'NP' } },
    { label: 'Netherlands', value: { ISO3: 'NLD', ISO2: 'NL' } },
    { label: 'Netherlands Antilles', value: { ISO3: 'ANT', ISO2: 'AN' } },
    { label: 'New Caledonia', value: { ISO3: 'NCL', ISO2: 'NC' } },
    { label: 'New Zealand', value: { ISO3: 'NZL', ISO2: 'NZ' } },
    { label: 'Nicaragua', value: { ISO3: 'NIC', ISO2: 'NI' } },
    { label: 'Niger', value: { ISO3: 'NER', ISO2: 'NE' } },
    { label: 'Nigeria', value: { ISO3: 'NGA', ISO2: 'NG' } },
    { label: 'Niue', value: { ISO3: 'NIU', ISO2: 'NU' } },
    { label: 'Norfolk Island', value: { ISO3: 'NFK', ISO2: 'NF' } },
    { label: 'Northern Mariana Islands', value: { ISO3: 'MNP', ISO2: 'MP' } },
    { label: 'Norway', value: { ISO3: 'NOR', ISO2: 'NO' } },
    { label: 'Oman', value: { ISO3: 'OMN', ISO2: 'OM' } },
    { label: 'Pakistan', value: { ISO3: 'PAK', ISO2: 'PK' } },
    { label: 'Palau', value: { ISO3: 'PLW', ISO2: 'PW' } },
    {
      label: 'Palestinian Territory, Occupied',
      value: { ISO3: 'PSE', ISO2: 'PS' },
    },
    { label: 'Panama', value: { ISO3: 'PAN', ISO2: 'PA' } },
    { label: 'Papua New Guinea', value: { ISO3: 'PNG', ISO2: 'PG' } },
    { label: 'Paraguay', value: { ISO3: 'PRY', ISO2: 'PY' } },
    { label: 'Peru', value: { ISO3: 'PER', ISO2: 'PE' } },
    { label: 'Philippines', value: { ISO3: 'PHL', ISO2: 'PH' } },
    { label: 'Pitcairn', value: { ISO3: 'PCN', ISO2: 'PN' } },
    { label: 'Poland', value: { ISO3: 'POL', ISO2: 'PL' } },
    { label: 'Portugal', value: { ISO3: 'PRT', ISO2: 'PT' } },
    { label: 'Puerto Rico', value: { ISO3: 'PRI', ISO2: 'PR' } },
    { label: 'Qatar', value: { ISO3: 'QAT', ISO2: 'QA' } },
    { label: 'Reunion', value: { ISO3: 'REU', ISO2: 'RE' } },
    { label: 'Romania', value: { ISO3: 'ROU', ISO2: 'RO' } },
    { label: 'Russian Federation', value: { ISO3: 'RUS', ISO2: 'RU' } },
    { label: 'Rwanda', value: { ISO3: 'RWA', ISO2: 'RW' } },
    { label: 'Saint Barthelemy', value: { ISO3: 'BLM', ISO2: 'BL' } },
    { label: 'Saint Helena', value: { ISO3: 'SHN', ISO2: 'SH' } },
    { label: 'Saint Kitts and Nevis', value: { ISO3: 'KNA', ISO2: 'KN' } },
    { label: 'Saint Lucia', value: { ISO3: 'LCA', ISO2: 'LC' } },
    { label: 'Saint Martin', value: { ISO3: 'MAF', ISO2: 'MF' } },
    { label: 'Saint Pierre and Miquelon', value: { ISO3: 'SPM', ISO2: 'PM' } },
    {
      label: 'Saint Vincent and the Grenadines',
      value: { ISO3: 'VCT', ISO2: 'VC' },
    },
    { label: 'Samoa', value: { ISO3: 'WSM', ISO2: 'WS' } },
    { label: 'San Marino', value: { ISO3: 'SMR', ISO2: 'SM' } },
    { label: 'Sao Tome and Principe', value: { ISO3: 'STP', ISO2: 'ST' } },
    { label: 'Saudi Arabia', value: { ISO3: 'SAU', ISO2: 'SA' } },
    { label: 'Senegal', value: { ISO3: 'SEN', ISO2: 'SN' } },
    { label: 'Serbia', value: { ISO3: 'SRB', ISO2: 'RS' } },
    { label: 'Seychelles', value: { ISO3: 'SYC', ISO2: 'SC' } },
    { label: 'Sierra Leone', value: { ISO3: 'SLE', ISO2: 'SL' } },
    { label: 'Singapore', value: { ISO3: 'SGP', ISO2: 'SG' } },
    { label: 'Slovakia', value: { ISO3: 'SVK', ISO2: 'SK' } },
    { label: 'Slovenia', value: { ISO3: 'SVN', ISO2: 'SI' } },
    { label: 'Solomon Islands', value: { ISO3: 'SLB', ISO2: 'SB' } },
    { label: 'Somalia', value: { ISO3: 'SOM', ISO2: 'SO' } },
    { label: 'South Africa', value: { ISO3: 'ZAF', ISO2: 'ZA' } },
    {
      label: 'South Georgia and the South Sandwich Islands',
      value: { ISO3: 'SGS', ISO2: 'GS' },
    },
    { label: 'Spain', value: { ISO3: 'ESP', ISO2: 'ES' } },
    { label: 'Sri Lanka', value: { ISO3: 'LKA', ISO2: 'LK' } },
    { label: 'Sudan', value: { ISO3: 'SDN', ISO2: 'SD' } },
    { label: 'Suriname', value: { ISO3: 'SUR', ISO2: 'SR' } },
    { label: 'Svalbard and Jan Mayen', value: { ISO3: 'SJM', ISO2: 'SJ' } },
    { label: 'Swaziland', value: { ISO3: 'SWZ', ISO2: 'SZ' } },
    { label: 'Sweden', value: { ISO3: 'SWE', ISO2: 'SE' } },
    { label: 'Switzerland', value: { ISO3: 'CHE', ISO2: 'CH' } },
    { label: 'Syrian Arab Republic', value: { ISO3: 'SYR', ISO2: 'SY' } },
    { label: 'Taiwan', value: { ISO3: 'TWN', ISO2: 'TW' } },
    { label: 'Tajikistan', value: { ISO3: 'TJK', ISO2: 'TJ' } },
    {
      label: 'Tanzania, United Republic of',
      value: { ISO3: 'TZA', ISO2: 'TZ' },
    },
    { label: 'Thailand', value: { ISO3: 'THA', ISO2: 'TH' } },
    { label: 'Timor-Leste', value: { ISO3: 'TLS', ISO2: 'TL' } },
    { label: 'Togo', value: { ISO3: 'TGO', ISO2: 'TG' } },
    { label: 'Tokelau', value: { ISO3: 'TKL', ISO2: 'TK' } },
    { label: 'Tonga', value: { ISO3: 'TON', ISO2: 'TO' } },
    { label: 'Trinidad and Tobago', value: { ISO3: 'TTO', ISO2: 'TT' } },
    { label: 'Tunisia', value: { ISO3: 'TUN', ISO2: 'TN' } },
    { label: 'Turkey', value: { ISO3: 'TUR', ISO2: 'TR' } },
    { label: 'Turkmenistan', value: { ISO3: 'TKM', ISO2: 'TM' } },
    { label: 'Turks and Caicos Islands', value: { ISO3: 'TCA', ISO2: 'TC' } },
    { label: 'Tuvalu', value: { ISO3: 'TUV', ISO2: 'TV' } },
    { label: 'Uganda', value: { ISO3: 'UGA', ISO2: 'UG' } },
    { label: 'Ukraine', value: { ISO3: 'UKR', ISO2: 'UA' } },
    { label: 'United Arab Emirates', value: { ISO3: 'ARE', ISO2: 'AE' } },
    { label: 'United Kingdom', value: { ISO3: 'GBR', ISO2: 'GB' } },
    { label: 'United States', value: { ISO3: 'USA', ISO2: 'US' } },
    {
      label: 'United States Minor Outlying Islands',
      value: { ISO3: 'UMI', ISO2: 'UM' },
    },
    { label: 'Uruguay', value: { ISO3: 'URY', ISO2: 'UY' } },
    { label: 'Uzbekistan', value: { ISO3: 'UZB', ISO2: 'UZ' } },
    { label: 'Vanuatu', value: { ISO3: 'VUT', ISO2: 'VU' } },
    { label: 'Venezuela', value: { ISO3: 'VEN', ISO2: 'VE' } },
    { label: 'Viet Nam', value: { ISO3: 'VNM', ISO2: 'VN' } },
    { label: 'Virgin Islands, British', value: { ISO3: 'VGB', ISO2: 'VG' } },
    { label: 'Virgin Islands, U.S.', value: { ISO3: 'VIR', ISO2: 'VI' } },
    { label: 'Wallis and Futuna', value: { ISO3: 'WLF', ISO2: 'WF' } },
    { label: 'Western Sahara', value: { ISO3: 'ESH', ISO2: 'EH' } },
    { label: 'Yemen', value: { ISO3: 'YEM', ISO2: 'YE' } },
    { label: 'Zambia', value: { ISO3: 'ZMB', ISO2: 'ZM' } },
    { label: 'Zimbabwe', value: { ISO3: 'ZWE', ISO2: 'ZW' } },
  ],
  de_DE: [
    { label: 'Afghanistan', value: { ISO3: 'AFG', ISO2: 'AF' } },
    { label: 'Albanien', value: { ISO3: 'ALB', ISO2: 'AL' } },
    { label: 'Algerien', value: { ISO3: 'DZA', ISO2: 'DZ' } },
    { label: 'Amerikanisch-Samoa', value: { ISO3: 'ASM', ISO2: 'AS' } },
    {
      label: 'Amerikanische Jungferninseln',
      value: { ISO3: 'VIR', ISO2: 'VI' },
    },
    { label: 'Andorra', value: { ISO3: 'AND', ISO2: 'AD' } },
    { label: 'Angola', value: { ISO3: 'AGO', ISO2: 'AO' } },
    { label: 'Anguilla', value: { ISO3: 'AIA', ISO2: 'AI' } },
    { label: 'Antarktis', value: { ISO3: 'ATA', ISO2: 'AQ' } },
    { label: 'Antigua und Barbuda', value: { ISO3: 'ATG', ISO2: 'AG' } },
    { label: 'Aquitanien', value: { ISO3: 'ALA', ISO2: 'XX' } },
    { label: 'Argentinien', value: { ISO3: 'ARG', ISO2: 'AR' } },
    { label: 'Armenien', value: { ISO3: 'ARM', ISO2: 'AM' } },
    { label: 'Aruba', value: { ISO3: 'ABW', ISO2: 'AW' } },
    { label: 'Aserbaidschan', value: { ISO3: 'AZE', ISO2: 'AZ' } },
    { label: 'Australien', value: { ISO3: 'AUS', ISO2: 'AU' } },
    { label: 'Bahamas', value: { ISO3: 'BHS', ISO2: 'BS' } },
    { label: 'Bahrain', value: { ISO3: 'BHR', ISO2: 'BH' } },
    { label: 'Bangladesch', value: { ISO3: 'BGD', ISO2: 'BD' } },
    { label: 'Barbados', value: { ISO3: 'BRB', ISO2: 'BB' } },
    { label: 'Belarus', value: { ISO3: 'BLR', ISO2: 'BY' } },
    { label: 'Belgien', value: { ISO3: 'BEL', ISO2: 'BE' } },
    { label: 'Belize', value: { ISO3: 'BLZ', ISO2: 'BZ' } },
    { label: 'Benin', value: { ISO3: 'BEN', ISO2: 'BJ' } },
    { label: 'Bermuda', value: { ISO3: 'BMU', ISO2: 'BM' } },
    { label: 'Bhutan', value: { ISO3: 'BTN', ISO2: 'BT' } },
    { label: 'Bolivien', value: { ISO3: 'BOL', ISO2: 'BO' } },
    { label: 'Bosnien und Herzegowina', value: { ISO3: 'BIH', ISO2: 'BA' } },
    { label: 'Botswana', value: { ISO3: 'BWA', ISO2: 'BW' } },
    { label: 'Bouvetinsel', value: { ISO3: 'BVT', ISO2: 'BV' } },
    { label: 'Brasilien', value: { ISO3: 'BRA', ISO2: 'BR' } },
    {
      label: 'Britisches Territorium im Indischen Ozean',
      value: { ISO3: 'IOT', ISO2: 'IO' },
    },
    { label: 'Brunei Darussalam', value: { ISO3: 'BRN', ISO2: 'BN' } },
    { label: 'Bulgarien', value: { ISO3: 'BGR', ISO2: 'BG' } },
    { label: 'Burkina Faso', value: { ISO3: 'BFA', ISO2: 'BF' } },
    { label: 'Burundi', value: { ISO3: 'BDI', ISO2: 'BI' } },
    { label: 'Chile', value: { ISO3: 'CHL', ISO2: 'CL' } },
    { label: 'China', value: { ISO3: 'CHN', ISO2: 'CN' } },
    { label: 'Cookinseln', value: { ISO3: 'COK', ISO2: 'CK' } },
    { label: 'Costa Rica', value: { ISO3: 'CRI', ISO2: 'CR' } },
    { label: 'Curaçao', value: { ISO3: 'CUW', ISO2: 'CW' } },
    { label: 'Dänemark', value: { ISO3: 'DNK', ISO2: 'DK' } },
    { label: 'Deutschland', value: { ISO3: 'DEU', ISO2: 'DE' } },
    { label: 'Dominica', value: { ISO3: 'DMA', ISO2: 'DM' } },
    { label: 'Dominikanische Republik', value: { ISO3: 'DOM', ISO2: 'DO' } },
    { label: 'Dschibuti', value: { ISO3: 'DJI', ISO2: 'DJ' } },
    { label: 'Ecuador', value: { ISO3: 'ECU', ISO2: 'EC' } },
    { label: 'Elfenbeinküste', value: { ISO3: 'CIV', ISO2: 'CI' } },
    { label: 'El Salvador', value: { ISO3: 'SLV', ISO2: 'SV' } },
    { label: 'Eritrea', value: { ISO3: 'ERI', ISO2: 'ER' } },
    { label: 'Estland', value: { ISO3: 'EST', ISO2: 'EE' } },
    { label: 'Eswatini', value: { ISO3: 'SWZ', ISO2: 'SZ' } },
    { label: 'Falklandinseln', value: { ISO3: 'FLK', ISO2: 'FK' } },
    { label: 'Färöer', value: { ISO3: 'FRO', ISO2: 'FO' } },
    { label: 'Fidschi', value: { ISO3: 'FJI', ISO2: 'FJ' } },
    { label: 'Finnland', value: { ISO3: 'FIN', ISO2: 'FI' } },
    { label: 'Frankreich', value: { ISO3: 'FRA', ISO2: 'FR' } },
    { label: 'Französisch-Guayana', value: { ISO3: 'GUF', ISO2: 'GF' } },
    { label: 'Französisch-Polynesien', value: { ISO3: 'PYF', ISO2: 'PF' } },
    {
      label: 'Französische Süd- und Antarktisgebiete',
      value: { ISO3: 'ATF', ISO2: 'TF' },
    },
    { label: 'Gabun', value: { ISO3: 'GAB', ISO2: 'GA' } },
    { label: 'Gambia', value: { ISO3: 'GMB', ISO2: 'GM' } },
    { label: 'Georgien', value: { ISO3: 'GEO', ISO2: 'GE' } },
    { label: 'Ghana', value: { ISO3: 'GHA', ISO2: 'GH' } },
    { label: 'Gibraltar', value: { ISO3: 'GIB', ISO2: 'GI' } },
    { label: 'Grenada', value: { ISO3: 'GRD', ISO2: 'GD' } },
    { label: 'Griechenland', value: { ISO3: 'GRC', ISO2: 'GR' } },
    { label: 'Grönland', value: { ISO3: 'GRL', ISO2: 'GL' } },
    { label: 'Guadeloupe', value: { ISO3: 'GLP', ISO2: 'GP' } },
    { label: 'Guam', value: { ISO3: 'GUM', ISO2: 'GU' } },
    { label: 'Guatemala', value: { ISO3: 'GTM', ISO2: 'GT' } },
    { label: 'Guernsey', value: { ISO3: 'GGY', ISO2: 'GG' } },
    { label: 'Guinea', value: { ISO3: 'GIN', ISO2: 'GN' } },
    { label: 'Guinea-Bissau', value: { ISO3: 'GNB', ISO2: 'GW' } },
    { label: 'Guyana', value: { ISO3: 'GUY', ISO2: 'GY' } },
    { label: 'Haiti', value: { ISO3: 'HTI', ISO2: 'HT' } },
    { label: 'Heard und McDonaldinseln', value: { ISO3: 'HMD', ISO2: 'HM' } },
    { label: 'Honduras', value: { ISO3: 'HND', ISO2: 'HN' } },
    { label: 'Hongkong', value: { ISO3: 'HKG', ISO2: 'HK' } },
    { label: 'Indien', value: { ISO3: 'IND', ISO2: 'IN' } },
    { label: 'Indonesien', value: { ISO3: 'IDN', ISO2: 'ID' } },
    { label: 'Irak', value: { ISO3: 'IRQ', ISO2: 'IQ' } },
    { label: 'Iran, Islamische Republik', value: { ISO3: 'IRN', ISO2: 'IR' } },
    { label: 'Irland', value: { ISO3: 'IRL', ISO2: 'IE' } },
    { label: 'Island', value: { ISO3: 'ISL', ISO2: 'IS' } },
    { label: 'Isle of Man', value: { ISO3: 'IMN', ISO2: 'IM' } },
    { label: 'Israel', value: { ISO3: 'ISR', ISO2: 'IL' } },
    { label: 'Italien', value: { ISO3: 'ITA', ISO2: 'IT' } },
    { label: 'Jamaika', value: { ISO3: 'JAM', ISO2: 'JM' } },
    { label: 'Japan', value: { ISO3: 'JPN', ISO2: 'JP' } },
    { label: 'Jemen', value: { ISO3: 'YEM', ISO2: 'YE' } },
    { label: 'Jersey', value: { ISO3: 'JEY', ISO2: 'JE' } },
    { label: 'Jordanien', value: { ISO3: 'JOR', ISO2: 'JO' } },
    { label: 'Kaimaninseln', value: { ISO3: 'CYM', ISO2: 'KY' } },
    { label: 'Kambodscha', value: { ISO3: 'KHM', ISO2: 'KH' } },
    { label: 'Kamerun', value: { ISO3: 'CMR', ISO2: 'CM' } },
    { label: 'Kanada', value: { ISO3: 'CAN', ISO2: 'CA' } },
    { label: 'Kap Verde', value: { ISO3: 'CPV', ISO2: 'CV' } },
    { label: 'Kasachstan', value: { ISO3: 'KAZ', ISO2: 'KZ' } },
    { label: 'Katar', value: { ISO3: 'QAT', ISO2: 'QA' } },
    { label: 'Kenia', value: { ISO3: 'KEN', ISO2: 'KE' } },
    { label: 'Kirgisistan', value: { ISO3: 'KGZ', ISO2: 'KG' } },
    { label: 'Kiribati', value: { ISO3: 'KIR', ISO2: 'KI' } },
    { label: 'Kolumbien', value: { ISO3: 'COL', ISO2: 'CO' } },
    { label: 'Komoren', value: { ISO3: 'COM', ISO2: 'KM' } },
    { label: 'Kongo', value: { ISO3: 'COG', ISO2: 'CG' } },
    { label: 'Kroatien', value: { ISO3: 'HRV', ISO2: 'HR' } },
    { label: 'Kuba', value: { ISO3: 'CUB', ISO2: 'CU' } },
    { label: 'Kuwait', value: { ISO3: 'KWT', ISO2: 'KW' } },
    {
      label: 'Laos, Demokratische Volksrepublik',
      value: { ISO3: 'LAO', ISO2: 'LA' },
    },
    { label: 'Lesotho', value: { ISO3: 'LSO', ISO2: 'LS' } },
    { label: 'Lettland', value: { ISO3: 'LVA', ISO2: 'LV' } },
    { label: 'Libanon', value: { ISO3: 'LBN', ISO2: 'LB' } },
    { label: 'Liberia', value: { ISO3: 'LBR', ISO2: 'LR' } },
    { label: 'Libyen', value: { ISO3: 'LBY', ISO2: 'LY' } },
    { label: 'Liechtenstein', value: { ISO3: 'LIE', ISO2: 'LI' } },
    { label: 'Litauen', value: { ISO3: 'LTU', ISO2: 'LT' } },
    { label: 'Luxemburg', value: { ISO3: 'LUX', ISO2: 'LU' } },
    { label: 'Macao', value: { ISO3: 'MAC', ISO2: 'MO' } },
    { label: 'Madagaskar', value: { ISO3: 'MDG', ISO2: 'MG' } },
    { label: 'Malawi', value: { ISO3: 'MWI', ISO2: 'MW' } },
    { label: 'Malaysia', value: { ISO3: 'MYS', ISO2: 'MY' } },
    { label: 'Malediven', value: { ISO3: 'MDV', ISO2: 'MV' } },
    { label: 'Mali', value: { ISO3: 'MLI', ISO2: 'ML' } },
    { label: 'Malta', value: { ISO3: 'MLT', ISO2: 'MT' } },
    { label: 'Marokko', value: { ISO3: 'MAR', ISO2: 'MA' } },
    { label: 'Marshallinseln', value: { ISO3: 'MHL', ISO2: 'MH' } },
    { label: 'Martinique', value: { ISO3: 'MTQ', ISO2: 'MQ' } },
    { label: 'Mauretanien', value: { ISO3: 'MRT', ISO2: 'MR' } },
    { label: 'Mauritius', value: { ISO3: 'MUS', ISO2: 'MU' } },
    { label: 'Mayotte', value: { ISO3: 'MYT', ISO2: 'YT' } },
    { label: 'Mexiko', value: { ISO3: 'MEX', ISO2: 'MX' } },
    { label: 'Mikronesien', value: { ISO3: 'FSM', ISO2: 'FM' } },
    { label: 'Moldau, Republik', value: { ISO3: 'MDA', ISO2: 'MD' } },
    { label: 'Monaco', value: { ISO3: 'MCO', ISO2: 'MC' } },
    { label: 'Mongolei', value: { ISO3: 'MNG', ISO2: 'MN' } },
    { label: 'Montenegro', value: { ISO3: 'MNE', ISO2: 'ME' } },
    { label: 'Montserrat', value: { ISO3: 'MSR', ISO2: 'MS' } },
    { label: 'Mosambik', value: { ISO3: 'MOZ', ISO2: 'MZ' } },
    { label: 'Myanmar', value: { ISO3: 'MMR', ISO2: 'MM' } },
    { label: 'Namibia', value: { ISO3: 'NAM', ISO2: 'NA' } },
    { label: 'Nauru', value: { ISO3: 'NRU', ISO2: 'NR' } },
    { label: 'Nepal', value: { ISO3: 'NPL', ISO2: 'NP' } },
    { label: 'Neukaledonien', value: { ISO3: 'NCL', ISO2: 'NC' } },
    { label: 'Neuseeland', value: { ISO3: 'NZL', ISO2: 'NZ' } },
    { label: 'Nicaragua', value: { ISO3: 'NIC', ISO2: 'NI' } },
    { label: 'Niederlande', value: { ISO3: 'NLD', ISO2: 'NL' } },
    { label: 'Niger', value: { ISO3: 'NER', ISO2: 'NE' } },
    { label: 'Nigeria', value: { ISO3: 'NGA', ISO2: 'NG' } },
    { label: 'Niue', value: { ISO3: 'NIU', ISO2: 'NU' } },
    { label: 'Nördliche Marianen', value: { ISO3: 'MNP', ISO2: 'MP' } },
    { label: 'Nordmazedonien', value: { ISO3: 'MKD', ISO2: 'MK' } },
    { label: 'Norfolk-Insel', value: { ISO3: 'NFK', ISO2: 'NF' } },
    { label: 'Norwegen', value: { ISO3: 'NOR', ISO2: 'NO' } },
    { label: 'Oman', value: { ISO3: 'OMN', ISO2: 'OM' } },
    { label: 'Österreich', value: { ISO3: 'AUT', ISO2: 'AT' } },
    { label: 'Pakistan', value: { ISO3: 'PAK', ISO2: 'PK' } },
    { label: 'Palau', value: { ISO3: 'PLW', ISO2: 'PW' } },
    {
      label: 'Palästinensisches Gebiet, besetztes',
      value: { ISO3: 'PSE', ISO2: 'PS' },
    },
    { label: 'Panama', value: { ISO3: 'PAN', ISO2: 'PA' } },
    { label: 'Papua-Neuguinea', value: { ISO3: 'PNG', ISO2: 'PG' } },
    { label: 'Paraguay', value: { ISO3: 'PRY', ISO2: 'PY' } },
    { label: 'Peru', value: { ISO3: 'PER', ISO2: 'PE' } },
    { label: 'Philippinen', value: { ISO3: 'PHL', ISO2: 'PH' } },
    { label: 'Pitcairn', value: { ISO3: 'PCN', ISO2: 'PN' } },
    { label: 'Polen', value: { ISO3: 'POL', ISO2: 'PL' } },
    { label: 'Portugal', value: { ISO3: 'PRT', ISO2: 'PT' } },
    { label: 'Puerto Rico', value: { ISO3: 'PRI', ISO2: 'PR' } },
    { label: 'Réunion', value: { ISO3: 'REU', ISO2: 'RE' } },
    { label: 'Ruanda', value: { ISO3: 'RWA', ISO2: 'RW' } },
    { label: 'Rumänien', value: { ISO3: 'ROU', ISO2: 'RO' } },
    { label: 'Russische Föderation', value: { ISO3: 'RUS', ISO2: 'RU' } },
    { label: 'Saint Kitts und Nevis', value: { ISO3: 'KNA', ISO2: 'KN' } },
    { label: 'Saint-Barthélemy', value: { ISO3: 'BLM', ISO2: 'BL' } },
    { label: 'Saint-Martin', value: { ISO3: 'MAF', ISO2: 'MF' } },
    { label: 'Saint-Pierre und Miquelon', value: { ISO3: 'SPM', ISO2: 'PM' } },
    { label: 'Salomonen', value: { ISO3: 'SLB', ISO2: 'SB' } },
    { label: 'Sambia', value: { ISO3: 'ZMB', ISO2: 'ZM' } },
    { label: 'Samoa', value: { ISO3: 'WSM', ISO2: 'WS' } },
    { label: 'San Marino', value: { ISO3: 'SMR', ISO2: 'SM' } },
    { label: 'São Tomé und Príncipe', value: { ISO3: 'STP', ISO2: 'ST' } },
    { label: 'Saudi-Arabien', value: { ISO3: 'SAU', ISO2: 'SA' } },
    { label: 'Schweden', value: { ISO3: 'SWE', ISO2: 'SE' } },
    { label: 'Schweiz', value: { ISO3: 'CHE', ISO2: 'CH' } },
    { label: 'Senegal', value: { ISO3: 'SEN', ISO2: 'SN' } },
    { label: 'Serbien', value: { ISO3: 'SRB', ISO2: 'RS' } },
    { label: 'Seychellen', value: { ISO3: 'SYC', ISO2: 'SC' } },
    { label: 'Sierra Leone', value: { ISO3: 'SLE', ISO2: 'SL' } },
    { label: 'Simbabwe', value: { ISO3: 'ZWE', ISO2: 'ZW' } },
    { label: 'Singapur', value: { ISO3: 'SGP', ISO2: 'SG' } },
    { label: 'Sint Maarten', value: { ISO3: 'SXM', ISO2: 'SX' } },
    { label: 'Slowakei', value: { ISO3: 'SVK', ISO2: 'SK' } },
    { label: 'Slowenien', value: { ISO3: 'SVN', ISO2: 'SI' } },
    { label: 'Somalia', value: { ISO3: 'SOM', ISO2: 'SO' } },
    { label: 'Sri Lanka', value: { ISO3: 'LKA', ISO2: 'LK' } },
    { label: 'St. Lucia', value: { ISO3: 'LCA', ISO2: 'LC' } },
    {
      label: 'St. Vincent und die Grenadinen',
      value: { ISO3: 'VCT', ISO2: 'VC' },
    },
    { label: 'Südafrika', value: { ISO3: 'ZAF', ISO2: 'ZA' } },
    { label: 'Sudan', value: { ISO3: 'SDN', ISO2: 'SD' } },
    {
      label: 'Südgeorgien und die Südlichen Sandwichinseln',
      value: { ISO3: 'SGS', ISO2: 'GS' },
    },
    { label: 'Südsudan', value: { ISO3: 'SSD', ISO2: 'SS' } },
    { label: 'Suriname', value: { ISO3: 'SUR', ISO2: 'SR' } },
    { label: 'Syrien, Arabische Republik', value: { ISO3: 'SYR', ISO2: 'SY' } },
    { label: 'Tadschikistan', value: { ISO3: 'TJK', ISO2: 'TJ' } },
    { label: 'Taiwan', value: { ISO3: 'TWN', ISO2: 'TW' } },
    {
      label: 'Tansania, Vereinigte Republik',
      value: { ISO3: 'TZA', ISO2: 'TZ' },
    },
    { label: 'Thailand', value: { ISO3: 'THA', ISO2: 'TH' } },
    { label: 'Timor-Leste', value: { ISO3: 'TLS', ISO2: 'TL' } },
    { label: 'Togo', value: { ISO3: 'TGO', ISO2: 'TG' } },
    { label: 'Tokelau', value: { ISO3: 'TKL', ISO2: 'TK' } },
    { label: 'Tonga', value: { ISO3: 'TON', ISO2: 'TO' } },
    { label: 'Trinidad und Tobago', value: { ISO3: 'TTO', ISO2: 'TT' } },
    { label: 'Tschad', value: { ISO3: 'TCD', ISO2: 'TD' } },
    { label: 'Tschechische Republik', value: { ISO3: 'CZE', ISO2: 'CZ' } },
    { label: 'Tunesien', value: { ISO3: 'TUN', ISO2: 'TN' } },
    { label: 'Turkmenistan', value: { ISO3: 'TKM', ISO2: 'TM' } },
    { label: 'Turks- und Caicosinseln', value: { ISO3: 'TCA', ISO2: 'TC' } },
    { label: 'Tuvalu', value: { ISO3: 'TUV', ISO2: 'TV' } },
    { label: 'Uganda', value: { ISO3: 'UGA', ISO2: 'UG' } },
    { label: 'Ukraine', value: { ISO3: 'UKR', ISO2: 'UA' } },
    { label: 'Ungarn', value: { ISO3: 'HUN', ISO2: 'HU' } },
    { label: 'Uruguay', value: { ISO3: 'URY', ISO2: 'UY' } },
    { label: 'Usbekistan', value: { ISO3: 'UZB', ISO2: 'UZ' } },
    { label: 'Vanuatu', value: { ISO3: 'VUT', ISO2: 'VU' } },
    { label: 'Vatikanstadt', value: { ISO3: 'VAT', ISO2: 'VA' } },
    {
      label: 'Venezuela, Bolivarische Republik',
      value: { ISO3: 'VEN', ISO2: 'VE' },
    },
    {
      label: 'Vereinigte Arabische Emirate',
      value: { ISO3: 'ARE', ISO2: 'AE' },
    },
    { label: 'Vereinigtes Königreich', value: { ISO3: 'GBR', ISO2: 'GB' } },
    {
      label: 'Vereinigte Staaten von Amerika',
      value: { ISO3: 'USA', ISO2: 'US' },
    },
    { label: 'Vietnam', value: { ISO3: 'VNM', ISO2: 'VN' } },
    { label: 'Wallis und Futuna', value: { ISO3: 'WLF', ISO2: 'WF' } },
    { label: 'Weihnachtsinsel', value: { ISO3: 'CXR', ISO2: 'CX' } },
    { label: 'Westsahara', value: { ISO3: 'ESH', ISO2: 'EH' } },
    {
      label: 'Zentralafrikanische Republik',
      value: { ISO3: 'CAF', ISO2: 'CF' },
    },
    { label: 'Zypern', value: { ISO3: 'CYP', ISO2: 'CY' } },
  ],
  it_IT: [
    { label: 'Afghanistan', value: { ISO3: 'AFG', ISO2: 'AF' } },
    { label: 'Albania', value: { ISO3: 'ALB', ISO2: 'AL' } },
    { label: 'Algeria', value: { ISO3: 'DZA', ISO2: 'DZ' } },
    { label: 'Andorra', value: { ISO3: 'AND', ISO2: 'AD' } },
    { label: 'Angola', value: { ISO3: 'AGO', ISO2: 'AO' } },
    { label: 'Anguilla', value: { ISO3: 'AIA', ISO2: 'AI' } },
    { label: 'Antartide', value: { ISO3: 'ATA', ISO2: 'AQ' } },
    { label: 'Antigua e Barbuda', value: { ISO3: 'ATG', ISO2: 'AG' } },
    { label: 'Antille Olandesi', value: { ISO3: 'ANT', ISO2: 'AN' } },
    { label: 'Arabia Saudita', value: { ISO3: 'SAU', ISO2: 'SA' } },
    { label: 'Argentina', value: { ISO3: 'ARG', ISO2: 'AR' } },
    { label: 'Armenia', value: { ISO3: 'ARM', ISO2: 'AM' } },
    { label: 'Aruba', value: { ISO3: 'ABW', ISO2: 'AW' } },
    { label: 'Australia', value: { ISO3: 'AUS', ISO2: 'AU' } },
    { label: 'Austria', value: { ISO3: 'AUT', ISO2: 'AT' } },
    { label: 'Azerbaigian', value: { ISO3: 'AZE', ISO2: 'AZ' } },
    { label: 'Bahamas', value: { ISO3: 'BHS', ISO2: 'BS' } },
    { label: 'Bahrein', value: { ISO3: 'BHR', ISO2: 'BH' } },
    { label: 'Bangladesh', value: { ISO3: 'BGD', ISO2: 'BD' } },
    { label: 'Barbados', value: { ISO3: 'BRB', ISO2: 'BB' } },
    { label: 'Bielorussia', value: { ISO3: 'BLR', ISO2: 'BY' } },
    { label: 'Belgio', value: { ISO3: 'BEL', ISO2: 'BE' } },
    { label: 'Belize', value: { ISO3: 'BLZ', ISO2: 'BZ' } },
    { label: 'Benin', value: { ISO3: 'BEN', ISO2: 'BJ' } },
    { label: 'Bermuda', value: { ISO3: 'BMU', ISO2: 'BM' } },
    { label: 'Bhutan', value: { ISO3: 'BTN', ISO2: 'BT' } },
    { label: 'Bolivia', value: { ISO3: 'BOL', ISO2: 'BO' } },
    { label: 'Bosnia-Erzegovina', value: { ISO3: 'BIH', ISO2: 'BA' } },
    { label: 'Botswana', value: { ISO3: 'BWA', ISO2: 'BW' } },
    { label: 'Brasile', value: { ISO3: 'BRA', ISO2: 'BR' } },
    { label: 'Brunei', value: { ISO3: 'BRN', ISO2: 'BN' } },
    { label: 'Bulgaria', value: { ISO3: 'BGR', ISO2: 'BG' } },
    { label: 'Burkina Faso', value: { ISO3: 'BFA', ISO2: 'BF' } },
    { label: 'Burundi', value: { ISO3: 'BDI', ISO2: 'BI' } },
    { label: 'Cambogia', value: { ISO3: 'KHM', ISO2: 'KH' } },
    { label: 'Camerun', value: { ISO3: 'CMR', ISO2: 'CM' } },
    { label: 'Canada', value: { ISO3: 'CAN', ISO2: 'CA' } },
    { label: 'Capo Verde', value: { ISO3: 'CPV', ISO2: 'CV' } },
    { label: 'Ciad', value: { ISO3: 'TCD', ISO2: 'TD' } },
    { label: 'Cile', value: { ISO3: 'CHL', ISO2: 'CL' } },
    { label: 'Cina', value: { ISO3: 'CHN', ISO2: 'CN' } },
    { label: 'Cipro', value: { ISO3: 'CYP', ISO2: 'CY' } },
    { label: 'Colombia', value: { ISO3: 'COL', ISO2: 'CO' } },
    { label: 'Comore', value: { ISO3: 'COM', ISO2: 'KM' } },
    { label: 'Congo', value: { ISO3: 'COG', ISO2: 'CG' } },
    { label: 'Corea del Nord', value: { ISO3: 'PRK', ISO2: 'KP' } },
    { label: 'Corea del Sud', value: { ISO3: 'KOR', ISO2: 'KR' } },
    { label: 'Costa Rica', value: { ISO3: 'CRI', ISO2: 'CR' } },
    { label: 'Croazia', value: { ISO3: 'HRV', ISO2: 'HR' } },
    { label: 'Cuba', value: { ISO3: 'CUB', ISO2: 'CU' } },
    { label: 'Danimarca', value: { ISO3: 'DNK', ISO2: 'DK' } },
    { label: 'Dominica', value: { ISO3: 'DMA', ISO2: 'DM' } },
    { label: 'Ecuador', value: { ISO3: 'ECU', ISO2: 'EC' } },
    { label: 'Egitto', value: { ISO3: 'EGY', ISO2: 'EG' } },
    { label: 'El Salvador', value: { ISO3: 'SLV', ISO2: 'SV' } },
    { label: 'Emirati Arabi Uniti', value: { ISO3: 'ARE', ISO2: 'AE' } },
    { label: 'Eritrea', value: { ISO3: 'ERI', ISO2: 'ER' } },
    { label: 'Estonia', value: { ISO3: 'EST', ISO2: 'EE' } },
    { label: 'Etiopia', value: { ISO3: 'ETH', ISO2: 'ET' } },
    { label: 'Fiji', value: { ISO3: 'FJI', ISO2: 'FJ' } },
    { label: 'Filippine', value: { ISO3: 'PHL', ISO2: 'PH' } },
    { label: 'Finlandia', value: { ISO3: 'FIN', ISO2: 'FI' } },
    { label: 'Francia', value: { ISO3: 'FRA', ISO2: 'FR' } },

    { label: 'Gabon', value: { ISO3: 'GAB', ISO2: 'GA' } },
    { label: 'Gambia', value: { ISO3: 'GMB', ISO2: 'GM' } },
    { label: 'Georgia', value: { ISO3: 'GEO', ISO2: 'GE' } },
    { label: 'Germania', value: { ISO3: 'DEU', ISO2: 'DE' } },
    { label: 'Ghana', value: { ISO3: 'GHA', ISO2: 'GH' } },
    { label: 'Giamaica', value: { ISO3: 'JAM', ISO2: 'JM' } },
    { label: 'Giappone', value: { ISO3: 'JPN', ISO2: 'JP' } },
    { label: 'Giordania', value: { ISO3: 'JOR', ISO2: 'JO' } },
    { label: 'Gibilterra', value: { ISO3: 'GIB', ISO2: 'GI' } },
    { label: 'Grecia', value: { ISO3: 'GRC', ISO2: 'GR' } },
    { label: 'Grenada', value: { ISO3: 'GRD', ISO2: 'GD' } },
    { label: 'Groenlandia', value: { ISO3: 'GRL', ISO2: 'GL' } },
    { label: 'Guadalupe', value: { ISO3: 'GLP', ISO2: 'GP' } },
    { label: 'Guam', value: { ISO3: 'GUM', ISO2: 'GU' } },
    { label: 'Guatemala', value: { ISO3: 'GTM', ISO2: 'GT' } },
    { label: 'Guernsey', value: { ISO3: 'GGY', ISO2: 'GG' } },
    { label: 'Guinea', value: { ISO3: 'GIN', ISO2: 'GN' } },
    { label: 'Guinea Equatoriale', value: { ISO3: 'GNQ', ISO2: 'GQ' } },
    { label: 'Guinea-Bissau', value: { ISO3: 'GNB', ISO2: 'GW' } },
    { label: 'Guyana', value: { ISO3: 'GUY', ISO2: 'GY' } },
    { label: 'Guyana Francese', value: { ISO3: 'GUF', ISO2: 'GF' } },
    { label: 'Haiti', value: { ISO3: 'HTI', ISO2: 'HT' } },
    { label: 'Honduras', value: { ISO3: 'HND', ISO2: 'HN' } },
    { label: 'Hong Kong', value: { ISO3: 'HKG', ISO2: 'HK' } },
    { label: 'India', value: { ISO3: 'IND', ISO2: 'IN' } },
    { label: 'Indonesia', value: { ISO3: 'IDN', ISO2: 'ID' } },
    { label: 'Iran', value: { ISO3: 'IRN', ISO2: 'IR' } },
    { label: 'Iraq', value: { ISO3: 'IRQ', ISO2: 'IQ' } },
    { label: 'Irlanda', value: { ISO3: 'IRL', ISO2: 'IE' } },
    { label: 'Islanda', value: { ISO3: 'ISL', ISO2: 'IS' } },
    { label: 'Israele', value: { ISO3: 'ISR', ISO2: 'IL' } },
    { label: 'Italia', value: { ISO3: 'ITA', ISO2: 'IT' } },
    { label: 'Kazakistan', value: { ISO3: 'KAZ', ISO2: 'KZ' } },
    { label: 'Kenya', value: { ISO3: 'KEN', ISO2: 'KE' } },
    { label: 'Kirghizistan', value: { ISO3: 'KGZ', ISO2: 'KG' } },
    { label: 'Kiribati', value: { ISO3: 'KIR', ISO2: 'KI' } },
    { label: 'Kuwait', value: { ISO3: 'KWT', ISO2: 'KW' } },
    { label: 'Laos', value: { ISO3: 'LAO', ISO2: 'LA' } },
    { label: 'Lesotho', value: { ISO3: 'LSO', ISO2: 'LS' } },
    { label: 'Lettonia', value: { ISO3: 'LVA', ISO2: 'LV' } },
    { label: 'Libano', value: { ISO3: 'LBN', ISO2: 'LB' } },
    { label: 'Liberia', value: { ISO3: 'LBR', ISO2: 'LR' } },
    { label: 'Libia', value: { ISO3: 'LBY', ISO2: 'LY' } },
    { label: 'Liechtenstein', value: { ISO3: 'LIE', ISO2: 'LI' } },
    { label: 'Lituania', value: { ISO3: 'LTU', ISO2: 'LT' } },
    { label: 'Lussemburgo', value: { ISO3: 'LUX', ISO2: 'LU' } },
    { label: 'Macao', value: { ISO3: 'MAC', ISO2: 'MO' } },
    { label: 'Macedonia', value: { ISO3: 'MKD', ISO2: 'MK' } },
    { label: 'Madagascar', value: { ISO3: 'MDG', ISO2: 'MG' } },
    { label: 'Malawi', value: { ISO3: 'MWI', ISO2: 'MW' } },
    { label: 'Maldive', value: { ISO3: 'MDV', ISO2: 'MV' } },
    { label: 'Malesia', value: { ISO3: 'MYS', ISO2: 'MY' } },
    { label: 'Mali', value: { ISO3: 'MLI', ISO2: 'ML' } },
    { label: 'Malta', value: { ISO3: 'MLT', ISO2: 'MT' } },
    { label: 'Marocco', value: { ISO3: 'MAR', ISO2: 'MA' } },
    { label: 'Martinica', value: { ISO3: 'MTQ', ISO2: 'MQ' } },
    { label: 'Mauritania', value: { ISO3: 'MRT', ISO2: 'MR' } },
    { label: 'Mauritius', value: { ISO3: 'MUS', ISO2: 'MU' } },
    { label: 'Mayotte', value: { ISO3: 'MYT', ISO2: 'YT' } },
    { label: 'Messico', value: { ISO3: 'MEX', ISO2: 'MX' } },
    { label: 'Moldavia', value: { ISO3: 'MDA', ISO2: 'MD' } },
    { label: 'Monaco', value: { ISO3: 'MCO', ISO2: 'MC' } },
    { label: 'Mongolia', value: { ISO3: 'MNG', ISO2: 'MN' } },
    { label: 'Montenegro', value: { ISO3: 'MNE', ISO2: 'ME' } },
    { label: 'Montserrat', value: { ISO3: 'MSR', ISO2: 'MS' } },
    { label: 'Mozambico', value: { ISO3: 'MOZ', ISO2: 'MZ' } },
    { label: 'Namibia', value: { ISO3: 'NAM', ISO2: 'NA' } },
    { label: 'Nauru', value: { ISO3: 'NRU', ISO2: 'NR' } },
    { label: 'Nepal', value: { ISO3: 'NPL', ISO2: 'NP' } },
    { label: 'Nicaragua', value: { ISO3: 'NIC', ISO2: 'NI' } },
    { label: 'Niger', value: { ISO3: 'NER', ISO2: 'NE' } },
    { label: 'Nigeria', value: { ISO3: 'NGA', ISO2: 'NG' } },
    { label: 'Niue', value: { ISO3: 'NIU', ISO2: 'NU' } },
    { label: 'Norvegia', value: { ISO3: 'NOR', ISO2: 'NO' } },
    { label: 'Nuova Caledonia', value: { ISO3: 'NCL', ISO2: 'NC' } },
    { label: 'Nuova Zelanda', value: { ISO3: 'NZL', ISO2: 'NZ' } },
    { label: 'Oman', value: { ISO3: 'OMN', ISO2: 'OM' } },
    { label: 'Paesi Bassi', value: { ISO3: 'NLD', ISO2: 'NL' } },
    { label: 'Pakistan', value: { ISO3: 'PAK', ISO2: 'PK' } },
    { label: 'Palau', value: { ISO3: 'PLW', ISO2: 'PW' } },
    { label: 'Palestina', value: { ISO3: 'PSE', ISO2: 'PS' } },
    { label: 'Panama', value: { ISO3: 'PAN', ISO2: 'PA' } },
    { label: 'Papua Nuova Guinea', value: { ISO3: 'PNG', ISO2: 'PG' } },
    { label: 'Paraguay', value: { ISO3: 'PRY', ISO2: 'PY' } },
    { label: 'Peru', value: { ISO3: 'PER', ISO2: 'PE' } },
    { label: 'Polinesia Francese', value: { ISO3: 'PYF', ISO2: 'PF' } },
    { label: 'Polonia', value: { ISO3: 'POL', ISO2: 'PL' } },
    { label: 'Porto Rico', value: { ISO3: 'PRI', ISO2: 'PR' } },
    { label: 'Portogallo', value: { ISO3: 'PRT', ISO2: 'PT' } },
    { label: 'Qatar', value: { ISO3: 'QAT', ISO2: 'QA' } },
    { label: 'Repubblica Ceca', value: { ISO3: 'CZE', ISO2: 'CZ' } },
    { label: 'Repubblica Centrafricana', value: { ISO3: 'CAF', ISO2: 'CF' } },
    {
      label: 'Repubblica Democratica del Congo',
      value: { ISO3: 'COD', ISO2: 'CD' },
    },
    { label: 'Repubblica Dominicana', value: { ISO3: 'DOM', ISO2: 'DO' } },
    { label: 'Repubblica di Corea', value: { ISO3: 'KOR', ISO2: 'KR' } },
    { label: 'Repubblica di Moldova', value: { ISO3: 'MDA', ISO2: 'MD' } },
    { label: 'Repubblica del Congo', value: { ISO3: 'COG', ISO2: 'CG' } },
    {
      label: 'Repubblica del Sudan del Sud',
      value: { ISO3: 'SSD', ISO2: 'SS' },
    },
    { label: 'Repubblica del Sudafrica', value: { ISO3: 'ZAF', ISO2: 'ZA' } },
    {
      label: 'Repubblica Islamica dell`Iran',
      value: { ISO3: 'IRN', ISO2: 'IR' },
    },
    { label: 'Repubblica popolare Cinese', value: { ISO3: 'CHN', ISO2: 'CN' } },
    {
      label: 'Repubblica Unita di Tanzania',
      value: { ISO3: 'TZA', ISO2: 'TZ' },
    },
    { label: 'Reunion', value: { ISO3: 'REU', ISO2: 'RE' } },
    { label: 'Romania', value: { ISO3: 'ROU', ISO2: 'RO' } },
    { label: 'Ruanda', value: { ISO3: 'RWA', ISO2: 'RW' } },
    { label: 'Russia', value: { ISO3: 'RUS', ISO2: 'RU' } },
    { label: 'Saint Kitts e Nevis', value: { ISO3: 'KNA', ISO2: 'KN' } },
    { label: 'Saint Vincent e Grenadine', value: { ISO3: 'VCT', ISO2: 'VC' } },
    { label: 'Saint-Barthelemy', value: { ISO3: 'BLM', ISO2: 'BL' } },
    { label: 'Saint-Pierre e Miquelon', value: { ISO3: 'SPM', ISO2: 'PM' } },
    { label: 'Saint-Martin', value: { ISO3: 'MAF', ISO2: 'MF' } },
    { label: 'Samoa', value: { ISO3: 'WSM', ISO2: 'WS' } },
    { label: 'Samoa Americane', value: { ISO3: 'ASM', ISO2: 'AS' } },
    { label: 'San Marino', value: { ISO3: 'SMR', ISO2: 'SM' } },
    { label: 'Santa Lucia', value: { ISO3: 'LCA', ISO2: 'LC' } },
    { label: 'Sao Tome e Principe', value: { ISO3: 'STP', ISO2: 'ST' } },
    { label: 'Senegal', value: { ISO3: 'SEN', ISO2: 'SN' } },
    { label: 'Serbia', value: { ISO3: 'SRB', ISO2: 'RS' } },
    { label: 'Seychelles', value: { ISO3: 'SYC', ISO2: 'SC' } },
    { label: 'Sierra Leone', value: { ISO3: 'SLE', ISO2: 'SL' } },
    { label: 'Singapore', value: { ISO3: 'SGP', ISO2: 'SG' } },
    { label: 'Siria', value: { ISO3: 'SYR', ISO2: 'SY' } },
    { label: 'Slovacchia', value: { ISO3: 'SVK', ISO2: 'SK' } },
    { label: 'Slovenia', value: { ISO3: 'SVN', ISO2: 'SI' } },
    { label: 'Somalia', value: { ISO3: 'SOM', ISO2: 'SO' } },
    { label: 'Spagna', value: { ISO3: 'ESP', ISO2: 'ES' } },
    { label: 'Sri Lanka', value: { ISO3: 'LKA', ISO2: 'LK' } },
    { label: 'Stati Uniti', value: { ISO3: 'USA', ISO2: 'US' } },
    { label: 'Sudan', value: { ISO3: 'SDN', ISO2: 'SD' } },
    { label: 'Sudan del Sud', value: { ISO3: 'SSD', ISO2: 'SS' } },
    { label: 'Svezia', value: { ISO3: 'SWE', ISO2: 'SE' } },
    { label: 'Svizzera', value: { ISO3: 'CHE', ISO2: 'CH' } },
    { label: 'Swaziland', value: { ISO3: 'SWZ', ISO2: 'SZ' } },
    { label: 'Tagikistan', value: { ISO3: 'TJK', ISO2: 'TJ' } },
    { label: 'Taiwan', value: { ISO3: 'TWN', ISO2: 'TW' } },
    { label: 'Tanzania', value: { ISO3: 'TZA', ISO2: 'TZ' } },
    { label: 'Thailandia', value: { ISO3: 'THA', ISO2: 'TH' } },
    { label: 'Timor Est', value: { ISO3: 'TLS', ISO2: 'TL' } },
    { label: 'Togo', value: { ISO3: 'TGO', ISO2: 'TG' } },
    { label: 'Tonga', value: { ISO3: 'TON', ISO2: 'TO' } },
    { label: 'Trinidad e Tobago', value: { ISO3: 'TTO', ISO2: 'TT' } },
    { label: 'Tunisia', value: { ISO3: 'TUN', ISO2: 'TN' } },
    { label: 'Turchia', value: { ISO3: 'TUR', ISO2: 'TR' } },
    { label: 'Turkmenistan', value: { ISO3: 'TKM', ISO2: 'TM' } },
    { label: 'Tuvalu', value: { ISO3: 'TUV', ISO2: 'TV' } },
    { label: 'Ucraina', value: { ISO3: 'UKR', ISO2: 'UA' } },
    { label: 'Uganda', value: { ISO3: 'UGA', ISO2: 'UG' } },
    { label: 'Ungheria', value: { ISO3: 'HUN', ISO2: 'HU' } },
    { label: 'Uruguay', value: { ISO3: 'URY', ISO2: 'UY' } },
    { label: 'Uzbekistan', value: { ISO3: 'UZB', ISO2: 'UZ' } },
    { label: 'Vanuatu', value: { ISO3: 'VUT', ISO2: 'VU' } },
    { label: 'Vaticano', value: { ISO3: 'VAT', ISO2: 'VA' } },
    { label: 'Venezuela', value: { ISO3: 'VEN', ISO2: 'VE' } },
    { label: 'Vietnam', value: { ISO3: 'VNM', ISO2: 'VN' } },
    { label: 'Yemen', value: { ISO3: 'YEM', ISO2: 'YE' } },
    { label: 'Zambia', value: { ISO3: 'ZMB', ISO2: 'ZM' } },
    { label: 'Zimbabwe', value: { ISO3: 'ZWE', ISO2: 'ZW' } },
  ],
};
