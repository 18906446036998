export default () => (
  <svg
    width="32"
    height="29"
    viewBox="0 0 32 29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        className="label"
        d="M2.22217 15.4062C2.22217 8.51875 7.04439 2.71875 13.4166 1.8125C13.9333 1.8125 14.2777 2.175 14.2777 2.71875V16.3125L22.7166 25.1937C23.0611 25.5562 23.0611 26.2813 22.7166 26.4625C20.6499 28.0938 18.0666 29 15.1388 29C8.07772 29 2.22217 23.0187 2.22217 15.4062Z"
      />
      <path
        className="yin"
        d="M16.8611 13.5938V0.90625C16.8611 0.3625 17.2055 0 17.7222 0C24.4389 0 29.7778 5.61875 29.7778 12.6875C29.7778 13.2312 29.4333 13.5938 28.9166 13.5938H16.8611Z"
      />
      <path
        className="label"
        d="M30.6388 16.3125C31.1555 16.3125 31.4999 16.675 31.4999 17.2188C31.1555 20.4812 29.6055 23.2 27.5388 25.375C27.1944 25.7375 26.6777 25.7375 26.3333 25.375L17.7222 16.3125H30.6388Z"
      />
    </g>
  </svg>
);
