import { dispatch } from '@gimlite/router';
import { useMyUrl, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { createFpsService } from './createFps.machine';
import { BilanStep } from './steps/bilan/bilan.step';
import { ConfirmStep } from './steps/confirm/confirm.step';
import { InfoStep } from './steps/info/info.step';

export type CreateFPSWizardContext = any;
export const CreateFPSWizard = observer(() => {
  const { t } = useTranslation();
  const { AuthStore } = useRootStore();
  const { id } = useParams();
  const me = AuthStore.me as any;
  const { getParamsUrl } = useMyUrl();
  const result = useSelector(
    createFpsService,
    ({ context }: any) => context.result,
  );

  const fps = useSelector(createFpsService, ({ context }: any) => context.fps);

  const wizard = useSelector(
    createFpsService,
    ({ context }: any) => context.wizard,
  );

  useEffect(() => {
    if (result) {
      const searchRequest = {
        limit: 50,
        ...getParamsUrl,
        upsId: id,
      };
      dispatch('SEARCH_SESSIONS', searchRequest);
      dispatch('RESET_CURRENT_SESSION', {});
    }
  }, [result]);

  return (
    <Wizard<CreateFPSWizardContext>
      config={{
        title: `${t('createFps')} (${fps.lpn})`,
        withValidation: {
          state: result,
          success: t('CREATE_FPS_SUCCESS'),
          error: t('CREATE_FPS_ERROR'),
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (data: any) => {
          const payload = {
            sessionId: fps.sessionId,
            upsId: fps.upsId,
            type: 'INITIAL',
            userId: me._id,
            rootFineLegalId: '',
            terminalId: fps.terminalId,
            licensePlate: {
              plate: wizard.lpn,
              plateCountry: wizard?.countryISO,
            },
            vehicle: {
              brand: wizard.brand,
              model: wizard?.model,
            },
            parkId: fps.parkId,
            statementDatetime: fps.statementDatetime,
            comments: [
              {
                agent: {
                  name: me.fpsAgentName,
                  agentId: me.fpsAgentId,
                },
                creationDatetime: DateTime.now().toISO()!!,
                text: wizard?.comment,
              },
            ],
            pictures: [
              {
                mimeType: 'image/jpeg',
                description: 'entry',
                pictureDatetime: fps.entry.date,
                contentURL: fps.entry.camera,
              },
              {
                mimeType: 'image/jpeg',
                description: 'exit',
                pictureDatetime: fps.exit.date,
                contentURL: fps.exit.camera,
              },
            ],
            paymentStatus: 'PENDING',
          };

          createFpsService.send('CREATE_FPS', {
            payload,
          });
        },
        clear: () => { },
        close: () => {
          dispatch('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      {fps?.confirm && Object.values(fps?.confirm).includes(true) && (
        <Wizard.Item
          config={{ title: t('confirmation'), component: ConfirmStep }}
        />
      )}
      <Wizard.Item config={{ title: t('informations'), component: InfoStep }} />
      <Wizard.Item config={{ title: t('summary'), component: BilanStep }} />
    </Wizard>
  );
});
