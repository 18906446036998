import { gql } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'
import { Domain } from '@gimlite/domain'
import { DocumentNode, getIntrospectionQuery } from 'graphql'
import { RootStore } from '../config/index'
import { toCapitalizeCase } from './string.function'

type GraphQLRequestType = {
  operationName?: string
  params: any
  gql: DocumentNode
  render?: (res: any) => any
}

export const requestGQL = async ({
  operationName = undefined,
  params,
  gql,
  render
}: GraphQLRequestType): Promise<any> => {
  const bffClient: any = new Domain(
    `${RootStore.GlobalStore?.server?.bff}/graphql`
  )
  const token: string | null = localStorage.getItem('token')
  const authorization: string | undefined = RootStore.GlobalStore?.authorization
  const { definitions } = gql
  const [
    {
      operation,
      name: { value }
    }
  ] = definitions as any[]

  if (!operationName) operationName = getOperationName(gql)!!;

  return bffClient[operation](gql, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'x-access-token': token,
        authorization: `Bearer ${authorization}`
      }
    },
    variables: { ...params }
  })
    .then((res: any) => {
      const extractValue = operationName || value
      return render ? render(res[extractValue]) : res[extractValue]
    })
    .catch((error: unknown) => {
      // if (
      //   error.networkError &&
      //   'statusCode' in error.networkError &&
      //   (!error.networkError?.statusCode ||
      //     error.networkError?.statusCode >= 500)
      // ) {
      //   dispatch('BFF_ERROR', { error })
      // } else {
      throw error
      // }
      //*** Je désactive c'est pas fiable ***/
    })
}

export type SchemaGraphQLOfType = {
  kind: string
  name?: string
  ofType?: SchemaGraphQLOfType
}

export type SchemaGraphQLField = {
  name: string
  type?: SchemaGraphQLOfType
}

export type SchemaGraphQLArg = {
  name: string
  type?: SchemaGraphQLOfType
}

export type SchemaGraphQLType = {
  kind: string
  name: string
  fields?: SchemaGraphQLField[]
  args: SchemaGraphQLArg[]
}

export type requestGQLWithoutSchemaType = {
  operationName: string
  operationType: 'QUERY' | 'MUTATION'
  params?: Object
  render?: (res: any) => any
}

type ObjectGQL = string | ObjectGQL[]

const searchFieldsOfType = (
  typeName: string,
  types: SchemaGraphQLType[]
): SchemaGraphQLField[] | undefined => {
  const type: SchemaGraphQLType | undefined = Object.values(types).find(
    ({ kind, name }: any) => kind === 'OBJECT' && name === typeName
  )
  return type?.fields
}

const detailsOfType = (
  ofType: SchemaGraphQLOfType | undefined
): {
  name: string
  write: string | undefined
} => {
  const res = {
    name: searchTypeNameOfType(ofType),
    write: searchKindsOfType(ofType)
  }

  return res
}

const searchKindsOfType = (
  ofType: SchemaGraphQLOfType | undefined
): string | undefined => {
  if (!ofType) {
    return undefined
  } else if (ofType?.kind === 'NON_NULL') {
    return searchKindsOfType(ofType?.ofType) + '!'
  } else if (ofType?.kind === 'LIST') {
    return '[' + searchKindsOfType(ofType?.ofType) + ']'
  } else if (ofType.kind === 'SCALAR') {
    return ofType?.name
  } else {
    return ''
  }
}

const searchTypeNameOfType = (
  ofType: SchemaGraphQLOfType | undefined
): string => ofType?.name || searchTypeNameOfType(ofType?.ofType)

const buildGQL = (
  fields: SchemaGraphQLField[],
  types: SchemaGraphQLType[],
  operationName: string,
  operationType: string,
  args: SchemaGraphQLArg[]
): DocumentNode => {
  const buildSchema = (
    fieldsParams: SchemaGraphQLField[],
    typeParams: SchemaGraphQLType[]
  ): ObjectGQL[] =>
    fieldsParams.flatMap(
      ({ name: fieldName, type: fieldType }: SchemaGraphQLField): any => {
        const typeName = searchTypeNameOfType(fieldType)

        const fieldsOfTypeName = typeName
          ? searchFieldsOfType(typeName, typeParams)
          : []

        return typeName && fieldsOfTypeName
          ? [fieldName, buildSchema(fieldsOfTypeName, typeParams)]
          : [fieldName]
      }
    )

  const buildArgs = (
    argsParams: SchemaGraphQLArg[],
    mode: 'DEFINE' | 'PASS'
  ): string =>
    argsParams.length > 0
      ? `(${argsParams.reduce(
          (acc, { name, type }, index) =>
            `${acc} ${index !== 0 ? ',' : ''} ${
              mode === 'DEFINE' ? '$' + name : name
            }: ${mode === 'PASS' ? '$' + name : detailsOfType(type)?.write}`,
          ''
        )})`
      : ''

  const formatForGQL = (array: ObjectGQL[]): string =>
    `{${array.reduce(
      (acc: unknown, value: ObjectGQL) =>
        `${acc} \n ${Array.isArray(value) ? formatForGQL(value) : value}`,
      ''
    )}}`

  const setOperation = (request: string) =>
    `${operationType.toLowerCase()} ${operationName.toLowerCase()}${buildArgs(
      args,
      'DEFINE'
    )} {\n${operationName.toLowerCase()}${buildArgs(args, 'PASS')} ${request}}`

  return gql`
    ${setOperation(formatForGQL(buildSchema(fields, types)))}
  `
}

//! Les params ne sont pas encore implémenté
export async function requestGQLWithoutSchema({
  operationName,
  operationType,
  params = {},
  render = undefined
}: requestGQLWithoutSchemaType): Promise<any> {
  return requestGQL({
    operationName: '__schema',
    params,
    gql: gql`
      ${getIntrospectionQuery()}
    `,
    render: (res) => {
      const types: SchemaGraphQLType[] = res?.types

      const query: any = searchFieldsOfType(
        toCapitalizeCase(operationType),
        types
      )
      const fieldOfResolver = query?.find(
        ({ name }: SchemaGraphQLField) => name === operationName
      )

      const fieldOfResolverArgs = fieldOfResolver?.args

      const entityOfResolver = searchTypeNameOfType(
        fieldOfResolver?.type?.ofType
      )

      const fieldsOfEntityOfResolver = entityOfResolver
        ? searchFieldsOfType(entityOfResolver, types)
        : undefined
      if (!fieldsOfEntityOfResolver) throw new Error('Not Exist')

      const build = buildGQL(
        fieldsOfEntityOfResolver,
        types,
        operationName,
        operationType,
        fieldOfResolverArgs
      )

      const request: any = {
        params,
        gql: build
      }

      if (render) request.render = render
      return requestGQL(request)
    }
  })
}
