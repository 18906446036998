import React from 'react';

export const recursiveSearchReactElement = (
  children: React.ReactNode,
  element: React.ElementType,
): React.ReactNode[] => {
  const itemsFind: React.ReactNode[] = [];

  React.Children.forEach(children, (child: any) => {
    const childProps: any = child?.props;

    if (React.isValidElement(child) && child.type === element) {
      itemsFind.push(child);
    } else if (React.isValidElement(child) && childProps?.children) {
      const nestedFormItems = recursiveSearchReactElement(
        childProps?.children,
        element,
      );
      itemsFind.push(...nestedFormItems);
    }
  });

  return itemsFind;
};
