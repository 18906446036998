import {
  Form,
  FormItemType,
} from '@gimlite/watermelon/components/form/form.component';
import { Size as SizeGlobal, Way } from '@gimlite/watermelon';
import { Radio as RadioAnt } from 'antd';
import type { RadioChangeEvent as RadioChangeEventAnt } from 'antd/es/radio';
import { createContext } from 'react';
import { Write } from '../write/write.component';
import './radio.component.scss';

const RadioContext = createContext<{ size?: RadioGroupType.Config.Size }>({});

class Static {
  public static sizeMapper = Object.freeze({
    small: 'small',
    medium: 'middle',
    large: 'large',
  });
}

export declare namespace RadioGroupType {
  type Props = {
    children:
      | React.ReactElement<RadioType.Props>
      | React.ReactElement<RadioType.Props>[];
    className?: string;
    handleEvent?: {
      change?: (value: RadioGroupType.Data.Value) => void;
    };
    data?: {
      selected?: RadioGroupType.Data.Value;
      defaultSelected?: RadioGroupType.Data.Value;
    };
    config?: {
      size?: RadioGroupType.Config.Size;
      disabled?: boolean;
      way?: Extract<Way, 'vertical' | 'horizontal'>;
    };
  };

  namespace Data {
    type Value = string | null;
  }

  namespace Config {
    type Size = Extract<SizeGlobal, 'small' | 'medium' | 'large'>;
  }
}

export const RadioGroup = ({
  children,
  className = '',
  handleEvent: { change } = {},
  config: { size = 'small', way = 'horizontal' } = {},
  data: { selected, defaultSelected } = {},
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<RadioGroupType.Props>) => {
  return (
    <Form.Context.Consumer>
      {(form) => {
        return (
          <RadioContext.Provider value={{ size }}>
            <RadioAnt.Group
              className={`
                radiogroup 
                radiogroup--way--${form.way || way} 
                ${className}
              `}
              size={Static.sizeMapper[size]}
              value={selected}
              {...formItemParams}
              defaultValue={defaultSelected}
              onChange={({
                target: { value, checked },
              }: RadioChangeEventAnt) => {
                if (checked) {
                  change?.(value);
                  onChangeFormItem?.(value);
                } else {
                  console.error('change is not defined');
                }
              }}
            >
              {children}
            </RadioAnt.Group>
          </RadioContext.Provider>
        );
      }}
    </Form.Context.Consumer>
  );
};

export declare namespace RadioType {
  type Props = {
    key?: string;
    className?: string;
    data: {
      value: RadioType.Data.Value;
      label?: string;
    };
    config?: {
      disabled?: boolean;
    };
  };

  namespace Data {
    type Value = string;
  }
}

export const Radio = ({
  key,
  className = '',
  config: { disabled } = {},
  data: { value, label },
}: RadioType.Props) => {
  return (
    <RadioContext.Consumer>
      {({ size }) => {
        return (
          <div
            key={key}
            className={`
              radio
              radio--size--${size} 
              ${className}
            `}
          >
            <RadioAnt
              className={`radio__item`}
              value={value}
              disabled={disabled}
            ></RadioAnt>
            {label && (
              <Write
                data={{ item: label }}
                config={{ mode: 'value-medium' }}
                className="radio__label"
              ></Write>
            )}
          </div>
        );
      }}
    </RadioContext.Consumer>
  );
};
