export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <g id="Calque_2">
      <g>
        <path
          className="yang"
          d="M141.5,142.9c-9,0-16.2,7.4-16.2,16.5v82.3c0,9.1,7.3,16.5,16.2,16.5h113.6c9,0,16.2-7.4,16.2-16.5v-82.3
			c0-9.1-7.3-16.5-16.2-16.5H141.5z M161.8,208.8H178c11.2,0,20.3,9.2,20.3,20.6c0,2.3-1.8,4.1-4.1,4.1h-48.7
			c-2.2,0-4.1-1.9-4.1-4.1C141.5,218,150.6,208.8,161.8,208.8z M153.7,184.1c0-4.4,1.7-8.6,4.8-11.6c3-3.1,7.2-4.8,11.5-4.8
			c4.3,0,8.4,1.7,11.5,4.8c3,3.1,4.8,7.3,4.8,11.6s-1.7,8.6-4.8,11.6c-3,3.1-7.2,4.8-11.5,4.8c-4.3,0-8.4-1.7-11.5-4.8
			C155.4,192.6,153.7,188.4,153.7,184.1z M218.6,175.8H251c2.2,0,4.1,1.9,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1h-32.5
			c-2.2,0-4.1-1.9-4.1-4.1C214.5,177.7,216.3,175.8,218.6,175.8z M218.6,192.3H251c2.2,0,4.1,1.9,4.1,4.1c0,2.3-1.8,4.1-4.1,4.1
			h-32.5c-2.2,0-4.1-1.9-4.1-4.1C214.5,194.2,216.3,192.3,218.6,192.3z M218.6,208.8H251c2.2,0,4.1,1.9,4.1,4.1s-1.8,4.1-4.1,4.1
			h-32.5c-2.2,0-4.1-1.9-4.1-4.1S216.3,208.8,218.6,208.8z"
        />
      </g>
    </g>
  </svg>
);
