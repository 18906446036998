import { Menu as MenuAnt } from 'antd';
import React, { useMemo } from 'react';
import type { Size, Way } from '../../types/component.type';
import { Icon, IconType } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './menu.component.scss';

export declare namespace MenuType {
  type Props = {
    className?: string;
    handleEvent?: {
      navigation?: (value: string) => void;
    };
    data: {
      title?: string;
      items?: MenuType.Data.Item[];
      selected?: {
        value?: string | string[];
        type?: 'deep' | 'exact';
      };
    };
    config?: {
      mode?: MenuType.Config.Mode;
      size?: Extract<Size, 'small' | 'medium'>;
    };
  };

  namespace Data {
    type Item = {
      key: string;
      label: string;
      params?: Record<string, string>;
      items?: MenuType.Data.Item[];
      icon?: IconType.Config.Type;
      extra?: React.ReactNode;
    };
  }

  namespace Config {
    type Mode = Extract<Way, 'vertical' | 'horizontal'> | 'horizontal';
  }
}

export const Menu = ({
  handleEvent: { navigation } = {},
  data: { items = [], selected = {}, title = '' },
  className = '',
  config: { mode = 'vertical', size = 'medium' } = {},
}: MenuType.Props) => {
  const itemsFormatted: any = useMemo(() => {
    return [
      {
        label: title,
        type: 'group',
        children: items.map(({ items, icon, label, key, extra }) => ({
          icon: (
            <Icon
              className="menu__item__icon"
              config={{ size: 'small', type: icon }}
            ></Icon>
          ),
          label: (
            <div className="menu__item">
              <Write
                className="menu__item__label"
                data={{ item: label }}
                config={{ mode: 'link', testId: `menu-${label}` }}
              ></Write>
              {extra && <div className="menu__item__extra">{extra}</div>}
            </div>
          ),
          key,
          children:
            mode === 'horizontal' && items && items.length > 0
              ? items.map(
                  ({
                    label: subLabel,
                    icon: subIcon,
                    extra: subExtra,
                    ...rest
                  }) => ({
                    ...rest,
                    icon: subIcon ? (
                      <Icon
                        className="menu__item__icon"
                        config={{ size: 'small', type: subIcon }}
                      ></Icon>
                    ) : null,
                    label: (
                      <div className="menu__item">
                        <Write
                          className="menu__item__label"
                          data={{ item: subLabel }}
                          config={{ mode: 'link', testId: `menu-${subLabel}` }}
                        ></Write>

                        {subExtra && (
                          <div className="menu__item__extra">{subExtra}</div>
                        )}
                      </div>
                    ),
                  }),
                )
              : undefined,
        })),
      },
    ];
  }, [items]);

  const options = useMemo((): Record<string, unknown> => {
    const optionsFormatted: Record<string, unknown> = {};

    if (selected?.value) {
      optionsFormatted.selectedKeys = Array.isArray(selected.value)
        ? selected.value
        : [selected.value];
    }

    if (mode === 'horizontal') {
      optionsFormatted.defaultOpenKeys = Array.isArray(selected.value)
        ? selected.value
        : [selected.value];
    }

    return optionsFormatted;
  }, [selected, mode]);

  return (
    <MenuAnt
      onClick={({ key }) => {
        navigation?.(key);
      }}
      className={`
      ${className} 
        menu
        menu--mode--${mode === 'horizontal' ? 'recursive' : 'flat'}
        menu--select--${selected?.type || 'exact'}
        menu--size--${size}
      `}
      items={itemsFormatted}
      mode={mode === 'horizontal' ? 'inline' : mode}
      {...options}
    />
  );
};
