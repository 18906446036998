export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <g id="Calque_2">
      <path
        className="yang"
        d="M273.3,148.1c-2.2-7.8-9.3-13.6-17.7-13.6H138.8c-10.2,0-18.4,8.3-18.4,18.5V237c0,8.5,5.6,15.6,13.4,17.8
		c2.7,5.9,8.6,10,15.5,10h116.8c9.4,0,17.1-7.7,17.1-17.2v-83.9C283.1,156.8,279.1,150.8,273.3,148.1z M155.8,199.2h4.7
		c1.7,0,3.3,0.4,4.9,1.1c-0.1,0.8-0.2,1.6-0.2,2.5c0,2,0.4,4,1.2,5.8c0.8,1.8,2,3.4,3.5,4.8h-23.3c-0.6,0-1.2-0.2-1.6-0.7
		c-0.4-0.4-0.7-1-0.7-1.6h0c0-3.1,1.2-6.1,3.4-8.3C149.7,200.4,152.7,199.2,155.8,199.2z M151.1,186.9c0-2.3,0.9-4.6,2.6-6.2
		c1.6-1.6,3.9-2.6,6.2-2.6c1.7,0,3.4,0.5,4.8,1.5c1.4,1,2.6,2.3,3.2,3.9c0.7,1.6,0.8,3.4,0.5,5.1c-0.3,1.7-1.2,3.3-2.4,4.5
		c-1.2,1.2-2.8,2.1-4.5,2.4c-1.7,0.3-3.4,0.2-5-0.5c-1.6-0.7-3-1.8-3.9-3.2C151.7,190.3,151.1,188.6,151.1,186.9z M191.8,208.5
		c0.8-1.8,1.2-3.8,1.2-5.8c0-0.8-0.1-1.6-0.2-2.5c1.5-0.7,3.2-1.1,4.9-1.1h4.7c1.5,0,3,0.3,4.5,0.9c1.4,0.6,2.7,1.5,3.8,2.5
		c1.1,1.1,1.9,2.4,2.5,3.8c0.6,1.4,0.9,2.9,0.9,4.5c0,0.6-0.2,1.2-0.7,1.7c-0.4,0.4-1,0.7-1.6,0.7h-23.3h-0.1
		C189.8,212,191,210.3,191.8,208.5z M191.3,186.9c0-1.2,0.2-2.3,0.7-3.4c0.4-1.1,1.1-2,1.9-2.9c0.8-0.8,1.8-1.5,2.8-1.9
		c1.1-0.4,2.2-0.7,3.3-0.7c1.7,0,3.4,0.5,4.8,1.5c1.4,1,2.6,2.3,3.2,3.9c0.7,1.6,0.8,3.4,0.5,5.1c-0.3,1.7-1.2,3.3-2.4,4.5
		c-1.2,1.2-2.8,2.1-4.5,2.4c-1.7,0.3-3.4,0.2-5-0.5c-1.6-0.7-3-1.8-3.9-3.2C191.8,190.3,191.3,188.6,191.3,186.9z M200,231.4
		c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.4-0.4,0.7-0.6,1c-0.3,0.3-0.6,0.5-0.9,0.6c-0.4,0.1-0.7,0.2-1.1,0.2H161c-0.4,0-0.8-0.1-1.1-0.2
		c-0.4-0.1-0.7-0.4-0.9-0.6c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.7-0.2-1.1c0-3.9,1.5-7.6,4.3-10.4c2.7-2.7,6.4-4.3,10.3-4.3h12.8
		c3.9,0,7.6,1.5,10.3,4.3C198.5,223.8,200,227.6,200,231.4z M188.8,206.8c-0.8,1.9-2.1,3.6-3.9,4.7c-1.7,1.2-3.7,1.8-5.8,1.8
		c-2.8,0-5.4-1.1-7.4-3.1c-2-2-3.1-4.7-3.1-7.5c0-2.1,0.6-4.1,1.8-5.9c1.2-1.7,2.8-3.1,4.7-3.9c1.9-0.8,4-1,6.1-0.6
		c2,0.4,3.9,1.4,5.4,2.9c1.5,1.5,2.5,3.4,2.9,5.4C189.8,202.7,189.5,204.8,188.8,206.8z M228.3,197.2h33.6c2.3,0,4.2,1.9,4.2,4.2
		v0.1c0,2.3-1.9,4.2-4.2,4.2h-33.6c-2.3,0-4.2-1.9-4.2-4.2v-0.1C224.1,199,226,197.2,228.3,197.2z M224.1,184.6
		c0-2.3,1.9-4.2,4.2-4.2h33.6c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2h-33.6C226,188.8,224.1,186.9,224.1,184.6z M228.3,214.1h33.6
		c2.3,0,4.2,1.9,4.2,4.2v0.1c0,2.3-1.9,4.2-4.2,4.2h-33.6c-2.3,0-4.2-1.9-4.2-4.2v-0.1C224.1,216,226,214.1,228.3,214.1z M123,237
		v-83.9c0-8.8,7.1-15.9,15.8-15.9h116.8c6.6,0,12.2,4,14.6,9.8c-1.3-0.3-2.7-0.5-4.1-0.5H149.2c-9.4,0-17.1,7.7-17.1,17.2v83.9
		c0,1.4,0.2,2.8,0.5,4.1C127,249.2,123,243.5,123,237z"
      />
    </g>
  </svg>
);
