import { useEffect, useState } from 'react';
import { Socket, io } from 'socket.io-client';

export const useSockets = ({ config, token }) => {
  const { host } = config;
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    if (!token || socket) return;

    const client: Socket = io(host, {
      autoConnect: true,
      secure: true,
      reconnection: true,
      auth: (cb) => cb({ token }),
    });

    client.onAny((event, ...args) => {
      console.log('WEBSOCKET EVENT', event, args);
    });

    setSocket(client);
  }, [token]);

  useEffect(() => {
    if (!socket) {
      //console.log('No socket detected')
      return;
    }

    /*
    socket.io.on('open', () => console.log('Connecting......'))
    socket.on('connect', () => console.log('connected'))
    socket.io.on('reconnect', (attempt: any) =>
      console.log('reconnecting...', attempt)
    )*/

    return () => {
      socket.offAny();
      socket.disconnect();
    };
  }, [socket]);
  return socket;
};
