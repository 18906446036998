import React, { createContext, useCallback } from 'react';
import { createPortal } from 'react-dom';
import './elevate.component.scss';

export const ElevateContext = createContext<{
  close?: () => void;
}>({});

export declare namespace ElevateType {
  type Props = {
    handleEvent?: {
      exit?: () => void;
    };
    children: React.ReactNode;
  };
}

export const Elevate = ({
  children,
  handleEvent: { exit } = {},
}: ElevateType.Props) => {
  const close = useCallback(() => {
    exit?.();
  }, [exit]);

  return createPortal(
    <ElevateContext.Provider value={{ close }}>
      <div className="elevate">
        <div className="elevate__contain">{children}</div>
      </div>
    </ElevateContext.Provider>,
    document.getElementById('elevate-container') || document.body,
  );
};
