import { assign, createMachine, interpret } from 'xstate';
import type { NavDesktopType } from '../components/nav/nav.component';

export interface NavigationContext {
  menu: NavDesktopType.Data[];
  selected: string;
}

type MenuEvent =
  | {
      type: 'REGISTER_MENU';
      menu: NavDesktopType.Data[];
    }
  | {
      type: 'SELECTED_LINK';
      selected: string;
    };

const initialContext: NavigationContext = {
  menu: [],
  selected: '',
};

const navigationMachine = createMachine({
  id: 'navigation',
  context: initialContext,
  initial: 'idle',
  schema: {
    context: {} as NavigationContext,
    events: {} as MenuEvent,
  },
  states: {
    idle: {
      on: {
        REGISTER_MENU: {
          actions: assign({
            menu: (_, { menu }) => menu,
          }),
        },
        SELECTED_LINK: {
          actions: assign({
            selected: (_, { selected }) => selected,
          }),
        },
      },
    },
  },
});

const navigationService = interpret(navigationMachine).start();

export { navigationService };
