import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import { message } from 'antd';

export const useNotifications = (machine: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  const currentNotif = useSelector(
    machine,
    ({ context }: any) => context.currentNotif,
  );

  useEffect(() => {
    if (!currentNotif) return;
    const { mode, content } = currentNotif;

    messageApi.open({
      duration: 3,
      type: mode,
      content,
      style: {
        textAlign: 'end',
        marginRight: '10px',
      },
    });
  }, [currentNotif]);

  return contextHolder;
};
