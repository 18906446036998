import Color from 'color'
import { getCSS } from '../../functions/css.function'
import { Color as ColorGlobal } from '../../types/component.type'
import './avatar.component.scss'

export declare namespace AvatarType {
  type Props = {
    className?: string
    data: AvatarType.Data
    config: {
      color: AvatarType.Config.Color
      backgroundColor: AvatarType.Config.Color
    }
  }

  type Data = {
    firstName: string
    lastName: string
  }

  namespace Config {
    type Color = Extract<
      ColorGlobal,
      'primary-over' | 'primary' | 'yin' | 'yang' | 'white' | 'black'
    >
  }
}

export const Avatar = ({
  data: { firstName, lastName },
  config: { color, backgroundColor },
  className = ''
}: AvatarType.Props) => {
  return (
    <div className={`avatar ${className}`}>
      <img
        className={`avatar__image`}
        src={`https://ui-avatars.com/api/?format=svg&font-size=0.5&bold=true&name=${
          firstName || '?'
        }+${lastName || '?'}&color=${new Color(getCSS(`--color-${color}`))
          .hex()
          .toString()
          .replace('#', '')}&background=${new Color(
          getCSS(`--color-${backgroundColor}`)
        )
          .hex()
          .toString()
          .replace('#', '')}`}
      ></img>
    </div>
  )
}
