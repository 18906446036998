import { AuthStoreMobX } from './auth.store';
import { GlobalStoreMobX } from './global.store';
import { ThemeStoreMobX } from './theme.store';

export class RootStoreMobX {
  ThemeStore: ThemeStoreMobX;
  GlobalStore: GlobalStoreMobX;
  AuthStore: AuthStoreMobX;

  constructor() {
    this.GlobalStore = new GlobalStoreMobX(this);
    this.GlobalStore.init();
    this.ThemeStore = new ThemeStoreMobX(this);
    this.ThemeStore.init();
    this.AuthStore = new AuthStoreMobX(this);
    this.AuthStore.init();
  }
}
