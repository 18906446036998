import { assign, createMachine, interpret } from 'xstate';

type NotifContext = {
  currentNotif?: {
    mode: NotifEvent['mode'];
    content: NotifEvent['content'];
  };
};

type NotifEvent = {
  type: 'NOTIF' | 'ws:notif';
  mode: 'warning' | 'error' | 'success' | 'info';
  content: string;
};

const initialContext: NotifContext = {
  currentNotif: undefined,
};

const notifMachine = createMachine<NotifContext, NotifEvent>({
  id: 'notif',
  context: initialContext,
  initial: 'idle',
  schema: {
    context: {} as NotifContext,
    events: {} as NotifEvent,
  },
  states: {
    idle: {
      on: {
        NOTIF: {
          actions: assign({
            currentNotif: (_, event) => ({
              mode: event.mode,
              content: event.content,
            }),
          }),
        },
        'ws:notif': {
          actions: [
            assign({
              currentNotif: (_, { mode, content }) => ({
                mode: mode,
                content: content,
              }),
            }),
          ],
        },
      },
    },
  },
});

const notifService: any = interpret(notifMachine).start();

export { notifService };
