import { assign, createMachine, interpret } from 'xstate';

interface WizardContext {
  wizardToShow?: string;
  wizardStore?: Record<string, unknown>;
}

interface WizardEvent {
  type: 'SHOW_WIZARD';
  id: string;
  store: Record<string, unknown>;
}

const initialContext: WizardContext = {
  wizardToShow: undefined,
  wizardStore: undefined,
};

const wizardMachine = createMachine({
  id: 'wizard',
  context: initialContext,
  initial: 'idle',
  schema: {
    context: {} as WizardContext,
    events: {} as WizardEvent,
  },
  states: {
    idle: {
      on: {
        SHOW_WIZARD: {
          actions: assign({
            wizardToShow: (_, { id }) => id,
            wizardStore: (ctx, { id, store }) => ({
              ...ctx.wizardStore,
              [id]: store,
            }),
          }),
        },
      },
    },
  },
});

const wizardService: any = interpret(wizardMachine).start();

export { wizardService };
