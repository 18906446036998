import React from 'react';
import { createRoot } from 'react-dom/client';
import { CustomRouter } from './CustomRouter';
import config from './config';
import { CreateFPSWizard } from './wizards/createFps/createFps.wizard';
import { createFpsService } from './wizards/createFps/createFps.machine';
import { Config } from '@gimlite/watermelon';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Config
      debug={false}
      info={{
        env: config.ENV,
        version: {
          front: config.VERSION_FRONT,
        },
      }}
      server={{
        bff: config.BFF_HOST,
        ws: config.BFF_WS_HOST,
      }}
      isResponsive={false}
      authorization={config.BFF_TOKEN}
      updatePassword={true}
      theme={{
        color: {
          dark: '#5dbed2',
          light: '#0a2652',
        },
        defaultTheme: 'light',
      }}
      wizards={[
        {
          id: 'createFps',
          machine: createFpsService,
          component: CreateFPSWizard,
        },
      ]}
    >
      <CustomRouter />
    </Config>
  </React.StrictMode>,
);
