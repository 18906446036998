import { Steps as StepsAnt } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../hook/useRootStore.hook';
import './steps.component.scss';

export declare namespace StepsType {
  type Props = {
    className?: string;
    data: {
      items: StepsType.Data.Item[];
    };
    config?: {
      current?: number;
      percent?: number;
    };
  };

  namespace Data {
    type Item = {
      key: string;
      title: string;
    };
  }
}

export const Steps = observer(
  ({
    className = '',
    config: { current, percent } = {},
    data: { items = [] },
  }: StepsType.Props) => {
    const { ThemeStore } = useRootStore();

    return (
      <StepsAnt
        labelPlacement="vertical"
        items={items}
        current={current}
        percent={percent}
        className={`
          steps  
          ${items.length < 2 ? 'steps--single' : ''} 
          ${className}
        `}
      />
    );
  },
);
