import { Write } from '@gimlite/watermelon';
import { Icon } from '@gimlite/watermelon/components/icon/icon.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { DateTime } from 'luxon';

export const search = (
  context,
  { list, paging: { current, count, limit } },
) => {
  const listFormatted = list.map(
    ({
      _id,
      happenedAt,
      way,
      vehicle: { lpn },
      hasSubscription,
      hasParkingRight,
      knownLpn,
      isAnonymised,
    }) => ({
      _id,
      credential: (
        <Row>
          {hasSubscription && (
            <Icon
              config={{
                type: 'faCalendarCheckSolid',
                color: 'primary',
                size: 'small',
              }}
            />
          )}

          {!hasSubscription && hasParkingRight && (
            <Icon
              config={{
                type: 'faClockSolid',
                color: 'primary',
                size: 'small',
              }}
            />
          )}
        </Row>
      ),
      happenedAt: DateTime.fromISO(happenedAt).toFormat('dd/MM/yy HH:mm:ss'),
      way,
      lpn: isAnonymised ? (
        <Write
          data={{ item: lpn.toUpperCase() || '' }}
          config={{ mode: 'cell-value', color: 'disabled' }}
        />
      ) : (
        <Write
          data={{ item: lpn.toUpperCase() || '' }}
          config={{ mode: 'cell-value', color: 'text' }}
        />
      ),
    }),
  );

  return {
    list: listFormatted,
    paging: { current, count, limit },
  };
};

export const read = (
  context,
  {
    _id,
    happenedAt,
    way,
    vehicle: { lpn, type },
    resources: { list },
    camera: { code, ups, position },
    createdAt,
  },
) => {
  return {
    _id,
    lpn: lpn.toUpperCase() || '',
    happenedAt: DateTime.fromISO(happenedAt).toFormat('dd/MM/yy HH:mm:ss'),
    image: {
      item: list[0]?.url,
    },
    recognized: [type, 'nonmotorVehicle'],
    createdAt: DateTime.fromISO(createdAt).toFormat('dd/MM/yy HH:mm:ss'),
    name: code,
    way,
  };
};
