import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Color from 'color';
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getCSS } from '../../functions/css.function';
import { useDevice } from '../../hook/useDevice.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { Empty } from '../empty/empty.component';
import './maps.component.scss';

class Static {
  static markersStyles = [
    {
      id: 'default',
      iconURL:
        'https://cellar-c2.services.clever-cloud.com/yoonite-static/presto_one_marker.png',
      size: {
        width: 48,
        height: 48,
      },
      labelOrigin: {
        x: 24,
        y: -10,
      },
      label: {
        family: 'roboto',
        color: '#000000',
        size: '13px',
        weight: 'normal',
      },
    },
  ];
}

export declare namespace MapsType {
  type Props = {
    className?: string;
    data: MapsType.Data;
    config?: {
      selectionScaleFactor?: number;
      clickable?: boolean;
      yMax?: string;
    };
    handleEvent?: {
      click?: MapsType.HandleEvent.Click['function'];
      select?: (markerId: string | number) => void;
      unselect?: () => void;
    };
  };

  type Data = {
    markers?: MapsType.Static.Marker[];
    selected?: (string | number)[];
  };

  type Viewport = {};

  namespace HandleEvent {
    type Click = {
      value1: google.maps.MapMouseEvent;
      return: void;
      function: (
        e: MapsType.HandleEvent.Click['value1'],
      ) => MapsType.HandleEvent.Click['return'];
    };
  }

  namespace Static {
    type MarkerStyleIds = 'default' | 'error'; //! Essayer de créer un type dynamique avec le static (complexe)

    type Location = {
      lat: number;
      lng: number;
    };

    type Marker = {
      id: string | number;
      location: MapsType.Static.Location;
      label: string;
      markerStyleId?: MarkerStyleIds;
    };
  }
}

export const Maps = observer(
  ({
    config: {
      selectionScaleFactor = 1.5,
      clickable = true,
      yMax = '100%',
    } = {},
    data: { markers = [], selected = [] },
    handleEvent: { click, select, unselect } = {},
    className = '',
  }: MapsType.Props) => {
    const { GlobalStore } = useRootStore();
    const [selectedMarkers, setSelectedMarkers] = useState<(string | number)[]>(
      selected || [],
    );
    const { isLoaded: scriptLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: GlobalStore.maps || '',
    });
    const [tilesLoaded, setTilesLoaded] = useState<boolean>(false);
    const { isMobile } = useDevice();
    const mapRef = useRef(null);

    useEffect(() => {
      setSelectedMarkers(selected);
    }, [selected]);

    const onLoad = useCallback(
      (map: any) => {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((marker) => {
          bounds.extend(marker.location);
        });
        map.fitBounds(bounds, 0);
        mapRef.current = map;
      },
      [markers, selectedMarkers],
    );

    return scriptLoaded ? (
      <div style={{ width: '100%', height: yMax }}>
        <GoogleMap
          mapContainerClassName={`
            maps
          ${className}
          `}
          options={{
            disableDefaultUI: true,
            scrollwheel: isMobile ? false : true,
            styles: [
              {
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-background')).hex(),
                  },
                ],
              },
              {
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                elementType: 'labels.text.stroke',
                stylers: [
                  {
                    color: new Color(getCSS('--color-background')).hex(),
                  },
                ],
              },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                featureType: 'administrative.locality',
                elementType: 'geometry.stroke',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-intermediate')).hex(),
                  },
                ],
              },
              {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                featureType: 'poi.attraction',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.business',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.government',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.medical',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-intermediate')).hex(),
                  },
                ],
              },
              {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                featureType: 'poi.place_of_worship',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.school',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi.sports_complex',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-yin')).hex(),
                  },
                ],
              },
              {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [
                  {
                    color: new Color(getCSS('--color-background')).hex(),
                  },
                  {
                    visibility: 'on',
                  },
                ],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-yin')).hex(),
                  },
                ],
              },
              {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-label')).hex(),
                  },
                ],
              },
              {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-yin')).hex(),
                  },
                ],
              },
              {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-intermediate')).hex(),
                  },
                ],
              },
              {
                featureType: 'water',
                stylers: [
                  {
                    color: new Color(getCSS('--color-primary')).hex(),
                  },
                ],
              },
              {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                  {
                    color: new Color(getCSS('--color-primary')).hex(),
                  },
                ],
              },
              {
                featureType: 'water',
                elementType: 'geometry.fill',
                stylers: [
                  {
                    color: new Color(getCSS('--color-primary')).hex(),
                  },
                ],
              },
              {
                featureType: 'water',
                elementType: 'labels.text',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
            gestureHandling: 'greedy',
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            zoomControl: false,
            mapTypeControl: false,
            controlSize: 40,
            maxZoom: 18,
          }}
          onClick={(e: MapsType.HandleEvent.Click['value1']) => {
            setSelectedMarkers?.([]);
            click?.(e);
            unselect?.();
          }}
          onLoad={onLoad}
          onTilesLoaded={() => {
            setTilesLoaded(true);
          }}
        >
          {scriptLoaded &&
            tilesLoaded &&
            markers &&
            markers.map((marker) => {
              const markerStyle =
                Static.markersStyles.find(
                  (style) => style.id === marker.markerStyleId,
                ) || Static.markersStyles[0];
              const selected = selectedMarkers.includes(marker.id);

              return (
                <Marker
                  key={marker.id}
                  position={marker.location}
                  icon={
                    {
                      url: markerStyle?.iconURL,
                      scaledSize: selected
                        ? {
                            width:
                              markerStyle?.size?.width!! * selectionScaleFactor,
                            height:
                              markerStyle?.size?.height!! *
                              selectionScaleFactor,
                          }
                        : markerStyle?.size,
                      labelOrigin: selected
                        ? {
                            x:
                              markerStyle?.labelOrigin?.x!! *
                              selectionScaleFactor,
                            y: markerStyle?.labelOrigin?.y!!,
                          }
                        : markerStyle?.labelOrigin,
                    } as google.maps.Icon
                  }
                  label={{
                    text: marker.label,
                    className: 'marker-label',
                    color: markerStyle?.label?.color,
                    fontFamily: markerStyle?.label?.family,
                    fontSize: markerStyle?.label?.size,
                  }}
                  clickable={clickable}
                  onClick={() => {
                    if (clickable) {
                      setSelectedMarkers?.([marker.id]);
                      select?.(marker.id);
                    }
                  }}
                  options={{
                    optimized: true,
                  }}
                />
              );
            })}
        </GoogleMap>
      </div>
    ) : (
      <Empty config={{ mode: { name: 'disabled' } }} />
    );
  },
);
