import { gql } from '@apollo/client';

export const updateSessionGql = gql`
  mutation updateSession(
    $sessionId: String!
    $input: UpdateControlSessionsDto!
  ) {
    updateSession(sessionId: $sessionId, input: $input) {
      _id
    }
  }
`;
