import { Duration } from 'luxon';

export const formattedDuration = (duration) => {
  return duration < 1000 * 60 * 60 * 24
    ? Duration.fromMillis(duration).toFormat('hh:mm', ['hours', 'minutes'])
    : Duration.fromMillis(duration).toFormat("d'[d]' hh:mm", [
        'days',
        'hours',
        'minutes',
      ]);
};

export const formattedDurationMinutes = (duration) => {
  return duration < 60 * 24
    ? Duration.fromObject({ minutes: duration }).toFormat('hh:mm', ['hours', 'minutes'])
    : Duration.fromObject({ minutes: duration }).toFormat("d'[d]' hh:mm", [
        'days',
        'hours',
        'minutes',
      ]);
};
