import { EventObject, assign, createMachine, interpret } from 'xstate'

export type State2FAServiceContextType = {
  tfaActivated: boolean | null
  email: string | null
  error: string | null
}

const State2FAContext: State2FAServiceContextType = {
  tfaActivated: null,
  email: null,
  error: null
}

const machine = createMachine({
  id: 'state2FA',
  initial: 'off',
  context: State2FAContext,
  states: {
    off: {
      on: {
        WAKEUP: {
          target: 'idle',
          actions: assign({
            tfaActivated: (
              _,
              { tfaActivated }: EventObject & { tfaActivated: boolean }
            ) => tfaActivated,
            email: (_, { email }: EventObject & { email: string }) => email
          })
        }
      } as any
    },
    idle: {
      on: {
        KILL: {
          target: 'off'
        }
      }
    },
    failure: {
      on: {
        RETRY: {
          actions: assign({ error: null }),
          target: 'idle'
        }
      }
    }
  }
})

export const State2FAService = interpret(machine).start()
