import { IANAZone } from 'luxon';
import type { FormItemType } from '../components/form/form.component';

const createMessage = (message: string) => {
  return new Error(message);
};

export const buildRule = (
  validator: (input: string) => boolean,
  message: string,
) => {
  return (
    _: FormItemType.Config.Validator['value1'],
    value: string,
  ): Promise<Error | string | void> => {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve();
      }

      if (validator(value)) {
        resolve();
      } else {
        reject(createMessage(message));
      }
    });
  };
};

const buildRegexRule = (regex: RegExp, message: string) => {
  return buildRule((string) => regex.test(string), message);
};

export const isValidIANA = buildRule((string) => {
  return IANAZone.isValidZone(string);
}, 'watermelon-VALIDATOR_IANA');

export const isValidCode = buildRegexRule(
  /^\d{1,4}$/,
  'watermelon-VALIDATOR_OPERATOR_CODE_1',
);

export const isValidOperatorCode = buildRegexRule(
  /^\d{1,3}$/,
  'watermelon-VALIDATOR_OPERATOR_CODE_2',
);

export const isValidPhoneNumber = buildRegexRule(
  /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?((\d{1,4})[-.\s]?){0,3}?(\d{1,9})$/,
  'watermelon-VALIDATOR_PHONE_NUMBER',
);

export const isValidEmail = buildRegexRule(
  /([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/,
  'watermelon-VALIDATOR_EMAIL',
);

export const minLengthPasswordRule = buildRegexRule(
  /^(?=.{8,})/,
  'watermelon-VALIDATOR_PASSWORD_LENGTH',
);

export const uppercaseLetterRule = buildRegexRule(
  /^(?=.*[A-Z])/,
  'watermelon-VALIDATOR_PASSWORD_UPPERCASE',
);

export const lowercaseLetterRule = buildRegexRule(
  /^(?=.*[a-z])/,
  'watermelon-VALIDATOR_PASSWORD_LOWERCASE',
);

export const digitRule = buildRegexRule(
  /^(?=.*\d)/,
  'watermelon-VALIDATOR_PASSWORD_NUMBER',
);

export const specialCharRule = buildRegexRule(
  /^(?=.*[@$%*?&!.])[A-Za-z\d@$%*?&]/,
  'watermelon-VALIDATOR_PASSWORD_SPECIAL_CHARACTER',
);

export const isValidLocale = buildRegexRule(
  /^[a-z]{2}_[A-Z]{2}$/,
  'watermelon-VALIDATOR_LANG',
);

export const number = buildRegexRule(/^\d+$/, 'watermelon-VALIDATOR_NUMBER');

export const foreignPlates = buildRegexRule(
  /^[A-Z1-9]{1,15}$/,
  'watermelon-VALIDATOR_PLATE',
);

export const isValidPlateBasic = buildRegexRule(
  /^[A-Z0-9ÖÜÄËÏ]{1,12}$/,
  'watermelon-VALIDATOR_PLATE',
);

export const isChecked = (
  _: FormItemType.Config.Validator['value1'],
  value: boolean,
): Promise<Error | string | void> =>
  new Promise((resolve, reject) => {
    if (value === true) resolve();
    else reject(new Error(''));
  });

export const isValidPlateFNSM = (
  _: FormItemType.Config.Validator['value1'],
  value: string,
): Promise<Error | string | void> => {
  const regexFNSM = {
    FNI: /[1-9]\d{0,3}\s[A-Z]{1,3}\s(\d\d|2A|2B|971|972|973|974|975|976)/,
    SIV: /[A-Z]{2}-[0-9]{3}-[A-Z]{2}/,
    Cyclomotor: /[A-Z]{1,2}\s[0-9]{2,3}\s[A-Z]{1}/,
    Army: /[26789][0-9]{7}/,
    DiplomaticClassic1: /(([ESU]\s)?[0-9]{1,4}\s[K]\s[0-9]{1,4}(\s[XZ])?)/,
    DiplomaticClassic2:
      /(([ESU]\s)?[0-9]{1,4}\s[C][mM]?[D]\s[0-9]{1,4}(\s[XZ])?)/,
    DiplomaticConsular:
      /(([ESU]\s)?[0-9]{1,4}\s[C]\s[0-9]{1,4}(\s[X|Z])?(\s[0-8][0-9]|9[0-5])?)/,
    DomainPublicService: /((\d{2,3})|2A|2B)[DRNE]{0,1}[1-9][0-9]{3}[A-Z]/,
    GarageTemporary: /W{1,2}-[0-9]{3}-[A-Z]{2}/,
    Garage:
      /[1-9]\d{0,3}(W|WW[A-Z]{0,1})(\d\d|2A|2B|MC|971|972|973|974|975|976)/,
    Transit:
      /([1-9]\d{0,2}(T[A-Z]{2})(\d\d|2A|2B|971|972|973|974|975|976))|(([1-9]\d{4})(TTQ|TTW))/,
  };

  const regexFNSMEntries = Object.values(regexFNSM);

  return new Promise((resolve, reject) => {
    const testFNSM = regexFNSMEntries.some((currentRegex) =>
      currentRegex.test(value),
    );
    if (!testFNSM) reject(createMessage('watermelon-VALIDATOR_PLATE'));
    resolve();
  });
};
