export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <g id="Calque_2">
      <g>
        <g>
          <path
            className="yang"
            d="M201.3,158.4c-0.6-0.6-1.5-1-2.3-1c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.5-1,2.3v38.1l-7.6-7.5c-0.6-0.6-1.5-1-2.3-1
				c-0.9,0-1.7,0.3-2.3,1c-0.6,0.6-1,1.5-1,2.3c0,0.9,0.3,1.7,1,2.3l13.2,13.1l0,0c0.3,0.3,0.7,0.5,1.1,0.7c0.4,0.2,0.8,0.3,1.3,0.3
				c0.4,0,0.9-0.1,1.3-0.3c0.4-0.2,0.8-0.4,1.1-0.7l13.2-13.1c0.6-0.6,1-1.5,1-2.3c0-0.9-0.3-1.7-1-2.3c-0.6-0.6-1.5-1-2.3-1
				c-0.9,0-1.7,0.3-2.3,1l-7.6,7.5v-38.1C202.3,159.8,201.9,159,201.3,158.4z"
          />
        </g>
        <g>
          <path
            className="yang"
            d="M199,112.8c2.3,0,4.5-0.7,6.4-1.9c1.9-1.3,3.4-3.1,4.2-5.2c0.9-2.1,1.1-4.4,0.7-6.7c-0.4-2.2-1.5-4.3-3.2-5.9
				c-1.6-1.6-3.7-2.7-5.9-3.2c-2.2-0.4-4.6-0.2-6.7,0.7c-2.1,0.9-3.9,2.3-5.2,4.2c-1.3,1.9-1.9,4.1-1.9,6.4c0,3.1,1.2,6,3.4,8.1
				C193,111.5,195.9,112.8,199,112.8z"
          />
          <path
            className="yang"
            d="M181.5,135.8h35c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9c0-2.1-0.4-4.2-1.2-6.1c-0.8-1.9-2-3.7-3.5-5.2
				c-1.5-1.5-3.3-2.7-5.2-3.5c-2-0.8-4-1.2-6.2-1.2h-8.2c-2.1,0-4.2,0.4-6.2,1.2c-2,0.8-3.7,2-5.2,3.5c-1.5,1.5-2.7,3.3-3.5,5.2
				c-0.8,1.9-1.2,4-1.2,6.1c0,0.7,0.3,1.4,0.8,1.9C180.1,135.5,180.8,135.8,181.5,135.8z"
          />
          <path
            className="yang"
            d="M155.9,129.7c2.3,0,4.5-0.7,6.4-1.9c1.9-1.3,3.4-3.1,4.2-5.2c0.9-2.1,1.1-4.4,0.7-6.7c-0.4-2.2-1.5-4.3-3.2-5.9
				c-1.6-1.6-3.7-2.7-5.9-3.2c-2.2-0.4-4.6-0.2-6.7,0.7c-2.1,0.9-3.9,2.3-5.2,4.2c-1.3,1.9-1.9,4.1-1.9,6.4c0,3.1,1.2,6,3.4,8.1
				C149.9,128.5,152.8,129.7,155.9,129.7z"
          />
          <path
            className="yang"
            d="M175.3,152c0.5-0.5,0.8-1.2,0.8-1.9c0-2.1-0.4-4.2-1.2-6.1c-0.8-1.9-2-3.7-3.5-5.2c-1.5-1.5-3.3-2.7-5.2-3.5
				c-2-0.8-4-1.2-6.2-1.2h-8.2c-2.1,0-4.2,0.4-6.2,1.2c-2,0.8-3.7,2-5.2,3.5c-1.5,1.5-2.7,3.3-3.5,5.2c-0.8,1.9-1.2,4-1.2,6.1
				c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8h35C174.1,152.8,174.8,152.5,175.3,152z"
          />
          <path
            className="yang"
            d="M244.1,131.6c2.3,0,4.5-0.7,6.4-1.9c1.9-1.3,3.4-3.1,4.3-5.2c0.9-2.1,1.1-4.4,0.7-6.7c-0.4-2.2-1.5-4.3-3.2-5.9
				c-1.6-1.6-3.7-2.7-5.9-3.2c-2.2-0.4-4.6-0.2-6.7,0.7c-2.1,0.9-3.9,2.3-5.2,4.2c-1.3,1.9-1.9,4.1-1.9,6.4c0,3.1,1.2,6,3.4,8.1
				C238.2,130.3,241.1,131.6,244.1,131.6z"
          />
          <path
            className="yang"
            d="M259.6,140.6c-1.5-1.5-3.3-2.7-5.2-3.5c-1.9-0.8-4-1.2-6.2-1.2H240c-2.1,0-4.2,0.4-6.2,1.2s-3.7,2-5.2,3.5
				c-1.5,1.5-2.7,3.3-3.5,5.2c-0.8,1.9-1.2,4-1.2,6.1c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8h35c0.7,0,1.4-0.3,1.9-0.8
				c0.5-0.5,0.8-1.2,0.8-1.9c0-2.1-0.4-4.2-1.2-6.1C262.3,143.8,261.1,142.1,259.6,140.6z"
          />
          <path
            className="yang"
            d="M277.1,107.7c1.1,1.1,2.3,1.9,3.7,2.5c1.4,0.6,2.9,0.9,4.4,0.9c2.3,0,4.5-0.7,6.4-1.9c1.9-1.3,3.4-3.1,4.3-5.2
				c0.9-2.1,1.1-4.4,0.7-6.7c-0.4-2.2-1.5-4.3-3.2-5.9c-1.6-1.6-3.7-2.7-5.9-3.2c-2.2-0.4-4.6-0.2-6.7,0.7c-2.1,0.9-3.9,2.3-5.2,4.2
				c-1.3,1.9-1.9,4.1-1.9,6.4c0,1.5,0.3,3,0.9,4.4C275.2,105.3,276,106.6,277.1,107.7z"
          />
          <path
            className="yang"
            d="M304.2,125.2c-0.8-1.9-2-3.7-3.5-5.2c-1.5-1.5-3.3-2.7-5.2-3.5s-4-1.2-6.2-1.2h-8.2c-2.1,0-4.2,0.4-6.2,1.2
				c-2,0.8-3.7,2-5.2,3.5c-1.5,1.5-2.7,3.3-3.5,5.2c-0.8,1.9-1.2,4-1.2,6.1c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8h35
				c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9C305.5,129.3,305,127.2,304.2,125.2z"
          />
          <path
            className="yang"
            d="M104.6,107.7c1.1,1.1,2.3,1.9,3.7,2.5c1.4,0.6,2.9,0.9,4.4,0.9c2.3,0,4.5-0.7,6.4-1.9c1.9-1.3,3.4-3.1,4.2-5.2
				c0.9-2.1,1.1-4.4,0.7-6.7c-0.4-2.2-1.5-4.3-3.2-5.9c-1.6-1.6-3.7-2.7-5.9-3.2c-2.2-0.4-4.6-0.2-6.7,0.7c-2.1,0.9-3.9,2.3-5.2,4.2
				c-1.3,1.9-1.9,4.1-1.9,6.4c0,1.5,0.3,3,0.9,4.4C102.7,105.3,103.5,106.6,104.6,107.7z"
          />
          <path
            className="yang"
            d="M128.2,120c-1.5-1.5-3.3-2.7-5.2-3.5c-2-0.8-4-1.2-6.2-1.2h-8.2c-2.1,0-4.2,0.4-6.2,1.2c-2,0.8-3.7,2-5.2,3.5
				c-1.5,1.5-2.7,3.3-3.5,5.2c-0.8,1.9-1.2,4-1.2,6.1c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8h35c0.7,0,1.4-0.3,1.9-0.8
				c0.5-0.5,0.8-1.2,0.8-1.9c0-2.1-0.4-4.2-1.2-6.1C130.9,123.3,129.7,121.5,128.2,120z"
          />
        </g>
        <g>
          <path
            className="yang"
            d="M247,228.9c-1.9-4.1-6.1-6.9-10.8-6.9h-82.2c-6.6,0-12,5.4-12,12.1v59c0,4.7,2.7,8.8,6.6,10.8c1.5,5.5,6.5,9.5,12.4,9.5
				h82.2c7.2,0,13-5.8,13-13v-59C256.1,235.5,252.3,230.5,247,228.9z M209.6,245.9h23.6c1.6,0,3,1.3,3,3c0,1.6-1.3,3-3,3h-23.6
				c-1.6,0-3-1.3-3-3C206.6,247.2,208,245.9,209.6,245.9z M206.6,260.7c0-1.6,1.3-3,3-3h23.6c1.6,0,3,1.3,3,3v0.1c0,1.6-1.3,3-3,3
				h-23.6C208,263.7,206.6,262.4,206.6,260.7L206.6,260.7z M206.6,272.6c0-1.6,1.3-3,3-3h23.6c1.6,0,3,1.3,3,3v0.1c0,1.6-1.3,3-3,3
				h-23.6C208,275.6,206.6,274.3,206.6,272.6L206.6,272.6z M199.5,267.4c0,0.4-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5h-16.4h-0.1
				c1-0.9,1.9-2.1,2.5-3.3c0.6-1.3,0.9-2.7,0.9-4.1c0-0.6-0.1-1.2-0.1-1.7c1.1-0.5,2.2-0.7,3.4-0.7h3.3c2.2,0,4.3,0.9,5.8,2.4
				C198.6,263.1,199.5,265.2,199.5,267.4z M189.7,281.8c0,0.5-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.4,0.6h-25.4
				c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.3-0.2-0.5-0.2-0.8c0-2.7,1.1-5.4,3-7.3
				c1.9-1.9,4.5-3,7.2-3h9c2.7,0,5.3,1.1,7.2,3C188.6,276.4,189.7,279.1,189.7,281.8z M185.3,246.1c1.2-1.2,2.7-1.8,4.3-1.8
				c1.2,0,2.4,0.4,3.4,1c1,0.7,1.8,1.6,2.3,2.8c0.5,1.1,0.6,2.4,0.4,3.6c-0.2,1.2-0.8,2.3-1.7,3.2c-0.9,0.9-2,1.5-3.1,1.7
				c-1.2,0.2-2.4,0.1-3.5-0.4c-1.1-0.5-2.1-1.3-2.8-2.3c-0.7-1-1-2.2-1-3.4C183.5,248.8,184.2,247.3,185.3,246.1z M167.5,261.6
				c0-1.5,0.4-2.9,1.2-4.1c0.8-1.2,2-2.2,3.3-2.7c1.3-0.6,2.8-0.7,4.3-0.4c1.4,0.3,2.7,1,3.8,2c1,1,1.7,2.4,2,3.8
				c0.3,1.4,0.1,2.9-0.4,4.3c-0.6,1.4-1.5,2.5-2.7,3.3c-1.2,0.8-2.6,1.3-4.1,1.3c-2,0-3.8-0.8-5.2-2.2
				C168.3,265.5,167.6,263.6,167.5,261.6L167.5,261.6z M157.1,246.1c1.2-1.2,2.7-1.8,4.3-1.8c1.2,0,2.4,0.4,3.4,1
				c1,0.7,1.8,1.6,2.3,2.8c0.5,1.1,0.6,2.4,0.3,3.6c-0.2,1.2-0.8,2.3-1.7,3.2c-0.9,0.9-2,1.5-3.1,1.7c-1.2,0.2-2.4,0.1-3.5-0.4
				c-1.1-0.5-2.1-1.3-2.8-2.3c-0.7-1-1-2.2-1-3.4C155.3,248.8,155.9,247.3,157.1,246.1z M152.8,261.5c1.5-1.5,3.6-2.4,5.8-2.4h3.3
				c1.2,0,2.3,0.3,3.4,0.7c-0.1,0.6-0.1,1.1-0.1,1.7c0,1.4,0.3,2.8,0.9,4.1c0.6,1.3,1.4,2.4,2.5,3.3H152c-0.4,0-0.8-0.2-1.1-0.5
				c-0.3-0.3-0.5-0.7-0.5-1.2C150.4,265.2,151.2,263.1,152.8,261.5z M254.3,300.4c0,6.2-5,11.2-11.1,11.2h-82.2
				c-4.6,0-8.5-2.8-10.2-6.7c1,0.3,2.1,0.4,3.1,0.4h82.2c6.6,0,12-5.4,12-12.1v-59c0-1-0.1-2-0.4-2.9c3.8,1.8,6.5,5.6,6.5,10.2
				V300.4z"
          />
        </g>
      </g>
    </g>
  </svg>
);
