import React, { useMemo } from 'react';
import { recursiveSearchReactElement } from '../../functions/element.function';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Placement1Axis } from '../../types/component.type';
import { Button } from '../button/button.component';
import type { FormType } from '../form/form.component';
import { Form } from '../form/form.component';
import { Icon } from '../icon/icon.component';
import { Popover } from '../popover/popover.component';
import { Space } from '../space/space.component';
import './filter.component.scss';

export declare namespace FilterExpandedType {
  type Props = {
    className?: string;
    handleEvent?: {
      instance?: FormType.HandleEvent.Instance['function'];
      submit?: (value: FormType.Data.Value) => void;
      change?: (value: FormType.Data.Value) => void;
    };
    children?: React.ReactNode;
    config?: {
      free?: boolean; //! Faudras le supprimer
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
    };
    data?: {
      value?: FormType.Data.Value;
    };
  };
}

export const FilterExpanded = ({
  handleEvent: { instance, submit, change } = {},
  children,
  config: { free = false, vertical = 'start' } = {},
  className = '',
  data: { value } = {},
}: FilterExpandedType.Props) => {
  const [fields] = useMemo(
    () => recursiveSearchReactElement(children, FilterExpanded.Fields),
    [children, FilterExpanded.Fields],
  );

  const formItems = useMemo(
    () => recursiveSearchReactElement(fields, Form.Item),
    [fields, Form.Item],
  );

  const [actions] = useMemo(
    () => recursiveSearchReactElement(children, FilterExpanded.Actions),
    [children, FilterExpanded.Actions],
  );

  const buttons = useMemo(
    () => recursiveSearchReactElement(actions, Button),
    [actions, Button],
  );

  return (
    <Form
      handleEvent={{
        instance: (form) => instance?.(form),
        submit: (data) => submit?.(data),
        change: (data) => change?.(data),
      }}
      className={`filter-ex`}
      data={{ value: value }}
    >
      <div
        className={`
          filter-ex__contain
          filter-ex__contain--vertical--${vertical} 
          ${free ? 'filter-ex__contain--free' : ''} 
          ${className}
        `}
      >
        <div className="filter-ex__contain__fields">
          {formItems.map((item: React.ReactNode, index: number) => {
            return (
              <div
                key={`filter-ex__contain__fields__item-${index}`}
                className="filter-ex__contain__fields__item"
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className="filter-ex__contain__actions">
          {buttons.map((item: React.ReactNode, index: number) => {
            return (
              <div
                key={`filter-ex__contain__actions__item-${index}`}
                className="filter-ex__contain__actions__item"
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </Form>
  );
};

export declare namespace FilterExpandedFieldsType {
  type Props = {
    children?: React.ReactNode | React.ReactNode[];
  };
}

FilterExpanded.Fields = ({ children }: FilterExpandedFieldsType.Props) => {
  return <>{children}</>;
};

export declare namespace FilterExpandedActionsType {
  type Props = {
    children?: React.ReactNode | React.ReactNode[];
  };
}

FilterExpanded.Actions = ({ children }: FilterExpandedActionsType.Props) => {
  return <>{children}</>;
};

export declare namespace FilterReducedType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    handleEvent?: {
      submit?: (value: FormType.Data.Value) => void;
      change?: (value: FormType.Data.Value) => void;
      clear?: () => void;
    };
    config?: {
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
    };
    data?: {
      value?: FormType.Data.Value;
    };
  };
}

export const FilterReduced = ({
  className = '',
  handleEvent: { submit, change, clear } = {},
  data: { value } = {},
  children,
}: FilterReducedType.Props) => {
  const { t } = useTranslation();
  const formItems = recursiveSearchReactElement(children, Form.Item);

  return (
    <Popover
      data={
        <Popover.Context.Consumer>
          {({ close }) => {
            return (
              <Form
                className="filter-re-form"
                handleEvent={{
                  submit: (data: FormType.Data.Value) => {
                    submit?.(data);
                    close?.();
                  },
                  change: (data: FormType.Data.Value) => change?.(data),
                }}
                data={{ value: value }}
                config={{
                  element: {
                    way: 'horizontal',
                    width: 'full',
                  },
                }}
              >
                <div className="filter-re-form__contain">
                  {formItems.map((element, index) => (
                    <div
                      key={`filter-re-form__contain__item-${index}`}
                      className="filter-re-form__contain__item"
                    >
                      {element}
                    </div>
                  ))}
                </div>
                <div className="filter-re-form__action">
                  <Button
                    config={{
                      text: t('watermelon-submit'),
                      size: 'small',
                      type: { value: 'submit' },
                    }}
                  ></Button>
                  <Space config={{ way: 'horizontal' }}></Space>
                  <Button
                    handleEvent={{
                      click: () => {
                        clear?.();
                        close?.();
                      },
                    }}
                    config={{
                      text: t('watermelon-clear'),
                      size: 'small',
                      type: { value: 'button' },
                    }}
                  ></Button>
                </div>
              </Form>
            );
          }}
        </Popover.Context.Consumer>
      }
    >
      <div className={`filter-re ${className}`}>
        <div className="filter-re__count">
          <span className="filter-re__count__item">
            {value && typeof value === 'object'
              ? Object.values(value)
                  .filter(Boolean)
                  .filter((value) =>
                    typeof value === 'string'
                      ? value?.toLowerCase() !== 'all'
                      : true,
                  ).length
              : 0}
          </span>
        </div>
        <Icon
          className="filter-re__icon"
          config={{ type: 'faFilterSolid', color: 'label', size: 'medium' }}
        ></Icon>
      </div>
    </Popover>
  );
};
