import MobileDetect from 'mobile-detect';
import { useWindowSize } from 'usehooks-ts';
import { getCSS } from '../functions/css.function';

const stringToNumber = (str: string) => parseInt(str.replace(/[a-zA-Z]/g, ''));

const devices = {
  mobile: stringToNumber(getCSS('--device-mobile')),
  desktop: stringToNumber(getCSS('--device-desktop')),
};

const breakpoints = {
  navigation: stringToNumber(getCSS('--breakpoint-navigation')),
};

export function useDevice() {
  const { width, height } = useWindowSize();
  const detect = new MobileDetect(window.navigator.userAgent);

  return {
    isMobile:
      // !!detect.mobile() || !!detect.tablet() || width <= devices.desktop,
      width <= devices.desktop,

    isDesktop:
      // !!!detect.mobile() && !!!detect.tablet() && width > devices.desktop,
      width > devices.desktop,

    maxResolution: width < devices.desktop || height < 700,

    breakpoint: {
      navigation: width < breakpoints.navigation ? 'mobile' : 'desktop',
    },
  };
}
