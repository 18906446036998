import { gql } from '@apollo/client';

export const session = gql`
  query session($id: String!) {
    session(id: $id) {
      _id
      startDate
      endDate
      controlState
      isExposed
      lpn
      upsId
      entryEvent {
        resources {
          list {
            url
          }
        }
      }
      exitEvent {
        resources {
          list {
            url
          }
        }
      }
      covers {
        coverType
        startDate
        endDate
      }
      channels {
        _id
        channel
      }
      uncoveredDuration
      rightsDuration
      previousClaimsForUser
      terminalState {
        terminalId
        name
        startDate
        endDate
      }
      ups {
        _id
        config {
          fps {
            maximumAmount
          }
          p1000 {
            pointOfSaleId
            legacyId
          }
        }
        address {
          streetNumber
          streetNumberBis
          streetType
          streetName
          specialPlace
          postalCode
          addressLocality
          addressSubRegion
        }
        payload {
          tariffId
          duree_fps
          pointOfSaleId
          legacyId
        }
      }
      controlCtx {
        ctxRelevantCovers {
          tariffType
          startDate
          endDate
          usedMeansOfPayment
          upsId
          legacyId
          status
          claimReason
          cancellationReason
        }
      }
    }
  }
`;
