import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  ups: {},
};

const appMachine = createMachine({
  id: 'app',
  context: initialContext,
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    idle: {
      on: {
        SET_UPS_CONFIG: {
          actions: assign({
            ups: (_, { payload }) => payload,
          }),
        },
      },
    },
  },
});

export const appService = interpret(appMachine).start();
