import React from 'react';
import type { Color, Layout, Size, Way } from '../../types/component.type';
import { Write } from '../write/write.component';
import './description.component.scss';

export declare namespace DescriptionType {
  type Props = {
    className?: string;
    data: DescriptionType.Data[];
    config?: {
      layout?: Extract<Layout, 'row' | 'column'>;
      way?: Extract<Way, 'vertical' | 'horizontal'>;
      size?: Extract<Size, 'small' | 'medium' | 'large'>;
      mode?: 'line' | 'text';
    };
  };

  type Data = {
    key: string;
    label: string;
    value: string | number | React.ReactNode;
    color?: Extract<Color, 'success' | 'text' | 'warn' | 'error' | 'info'>;
  };
}

export const Description = ({
  data,
  config: {
    layout = 'row',
    way = 'horizontal',
    size = 'small',
    mode = 'text',
  } = {},
  className = '',
}: DescriptionType.Props) => {
  return mode === 'text' ? (
    <div
      className={`
        descriptiontext
        descriptiontext--layout--${layout} 
        ${className}`}
    >
      {data.map(({ key, label, value, color }, index) => {
        return value ? (
          <div
            key={`des-text-key-${key}-${index}`}
            className={`
            descriptiontext__item
            descriptiontext__item--size--${size} 
            descriptiontext__item--way--${way}`}
            data-testid={`description-${key}`}
          >
            <Write
              className="descriptiontext__item__label"
              data={{ item: `${label} :` }}
              config={{ mode: 'label', wrap: false }}
            ></Write>
            {['string', 'boolean', 'number'].includes(typeof value) ? (
              <Write
                className="descriptiontext__item__value"
                data={{ item: `${value}` }}
                config={{ mode: 'value-medium', color, wrap: false }}
              ></Write>
            ) : (
              value
            )}
          </div>
        ) : (
          <div key={`des-text-key-${key}`}></div>
        );
      })}
    </div>
  ) : (
    <div className={`descriptionline ${className}`}>
      {data.map(({ key, label, value, color }, index) => {
        return (
          <div
            key={`des-inline-key-${key}-${index}`}
            className="descriptionline__item"
            data-testid={`description-${key}`}
          >
            <Write
              data={{ item: label }}
              config={{ mode: 'label-value', wrap: false }}
            ></Write>
            {['string', 'boolean', 'number'].includes(typeof value) ? (
              <Write
                data={{ item: `${value}` }}
                config={{ mode: 'label', color, wrap: false }}
              ></Write>
            ) : (
              value
            )}
          </div>
        );
      })}
    </div>
  );
};
