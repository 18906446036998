import { gql } from '@apollo/client';

export default gql`
  mutation resetPassword(
    $username: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    resetPassword(
      username: $username
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      auth {
        token
        status
      }
      user {
        state
      }
    }
  }
`;
