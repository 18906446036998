import MainUse from './main';
import { eventsService } from './pages/events/events.machine';
import { Events } from './pages/events/view/events.page';
import { HomePage } from './pages/home';
import { homeService } from './pages/home/home.machine';
import { loginService } from './pages/login/login.machine';
import { LoginPage } from './pages/login/view/login.page';
import { vaoService } from './pages/vao/vao.machine';
import { Vao } from './pages/vao/view/vao.page';
import { Occupancy } from './pages/occupancy/view/occupancy.page';
import { occupancyService } from './pages/occupancy/occupancy.machine';

export const guestPathToPage = [
  {
    path: '/',
    Component: LoginPage,
    machine: loginService,
    children: [
      {
        path: '/login',
        Component: LoginPage,
      },
      {
        path: '*',
        Component: LoginPage,
      },
    ],
  },
];

export const userPathToPage = [
  {
    path: '/',
    Component: MainUse,
    children: [
      {
        path: '/',
        Component: HomePage,
        machine: homeService,
      },
      {
        path: '/:id',
        Component: Events,
        machine: eventsService,
      },
      {
        path: '/:id/events',
        Component: Events,
        machine: eventsService,
      },
      {
        path: '/:id/vao',
        Component: Vao,
        machine: vaoService,
      },
      {
        path: '/:id/occupancy',
        Component: Occupancy,
        machine: occupancyService,
      },
    ],
  },
];
