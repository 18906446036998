import { useSelector } from '@xstate/react';
import { useCallback, useState } from 'react';
import { Wizard } from '../../components/wizard/wizard.component';
import { requestGQL } from '../../functions/request.function';
import { activate2FAGql } from '../../gql/activate2FA.gql';
import { useRootStore } from '../../hook/useRootStore.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { wizardService } from '../../machines/wizard.machine';

import {
  State2FAService,
  State2FAServiceContextType,
} from './state2FA.machine';
import { ConfirmStep } from './steps/confirm/confirm.step';
import { SynchronizeStep } from './steps/synchronize/synchronize.step';
import { deactivate2FAGql } from '../../gql/deactivate2FA.gql';

export type State2FAContext = {
  codeFromEmail: string | null;
  codeFromAuth: string | null;
  uri: string | null;
  label: string | null;
  secret: string | null;
  tfaLabel: string | null;
  withEmailCode: boolean;
};

export const state2FAWizard = () => {
  const { t, lang } = useTranslation();

  const { AuthStore, GlobalStore } = useRootStore();

  const [result, setResult] = useState<boolean | undefined>(undefined);
  const { tfaActivated } = useSelector(
    State2FAService,
    ({ context }: { context: State2FAServiceContextType }) => context,
  );

  const deactivate2FA = useCallback(
    async ({ codeFromAuth }: { codeFromAuth: string }) => {
      try {
        const { success } = await requestGQL({
          gql: deactivate2FAGql,
          params: { input: { tfaToken: codeFromAuth } },
        });

        if (success) {
          AuthStore.initMe();
          setResult(() => true);
        } else {
          setResult(() => false);
        }
      } catch {
        setResult(() => false);
      }
    },
    [],
  );

  const activate2FA = useCallback(
    async ({ otpToken, tfaToken }: { otpToken: string; tfaToken: string }) => {
      try {
        const { success } = await requestGQL({
          gql: activate2FAGql,
          params: { input: { tfaToken, otpToken } },
        });

        if (success) {
          AuthStore.initMe();
          setResult(() => true);
        } else {
          setResult(() => false);
        }
      } catch {
        setResult(() => false);
      }
    },
    [],
  );

  return typeof tfaActivated === 'boolean' ? (
    <Wizard<State2FAContext>
      config={{
        withValidation: {
          error: tfaActivated
            ? t('watermelon-2FAAuthenticationCouldNotBeDeactivated')
            : t('watermelon-2FAAuthenticationCouldNotBeActivated'),
          state: result,
          success: tfaActivated
            ? t('watermelon-2FAAuthenticationHasBeenDisabled')
            : t('watermelon-2FAAuthenticationHasBeenActivated'),
        },
        title: tfaActivated
          ? t(`watermelon-desactivate2FAWithGoogleAuthentificator`)
          : t(`watermelon-activate2FAWithGoogleAuthentificator`),
      }}
      data={{
        defaultContext: {
          codeFromAuth: null,
          codeFromEmail: null,
          secret: null,
          label: null,
          uri: null,
          tfaLabel: GlobalStore.tfa?.label || null,
          withEmailCode: !tfaActivated,
        },
      }}
      handleEvent={{
        done: async ({ codeFromAuth, codeFromEmail }) => {
          if (tfaActivated) {
            if (codeFromAuth) {
              await deactivate2FA({ codeFromAuth });
            }
          } else {
            if (codeFromAuth && codeFromEmail) {
              await activate2FA({
                otpToken: codeFromEmail,
                tfaToken: codeFromAuth,
              });
            }
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          wizardService.send('SHOW_WIZARD', { id: '' });
        },
      }}
    >
      {!tfaActivated && (
        <Wizard.Item
          config={{
            title: t('watermelon-synchronize'),
            component: SynchronizeStep,
          }}
        />
      )}
      <Wizard.Item
        config={{ title: t('watermelon-confirm'), component: ConfirmStep }}
      />
    </Wizard>
  ) : (
    <></>
  );
};

// import { dispatch } from '@gimlite/router';
// import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
// import { useSelector } from '@xstate/react';
// import { createControlUsersService } from './createControlUser.machine';
// import { CredentialsStep } from './steps/credentials.step';
// import { DetailsStep } from './steps/details.step';

// export const timeoutOnSearch = 200;
// export type CreateControlUserWizardContext = any;
// export const CreateControlUserWizard = () => {
//   const result = useSelector(
//     createControlUsersService,
//     ({ context }: any) => context.result,
//   );

//   const error = useSelector(
//     createControlUsersService,
//     ({ context }: any) => context.error,
//   );

//   return (
//     <Wizard<CreateControlUserWizardContext>
//       config={{
//         title: '',
//         withValidation: {
//           state: result,
//           success: 'Ça marche nickel !',
//           error: error || 'Ça marche pas !',
//         },
//       }}
//       data={{
//         defaultContext: undefined,
//       }}
//       handleEvent={{
//         done: async (value: any) => {
//           createControlUsersService.send('CREATE_CONTROL_USER', {
//             payload: value,
//           });
//         },
//         clear: () => {
//           console.log('CLEAR');
//         },
//         close: () => {
//           dispatch('SHOW_WIZARD', { id: '' });
//         },
//       }}
//     >
//       <Wizard.Item
//         config={{ title: 'Credentials', component: CredentialsStep }}
//       />
//       <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
//     </Wizard>
//   );
// };
