export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <g id="Calque_2">
      <g>
        <path
          className="yang"
          d="M198.6,197.3h-9.6v-9c0-1.1-1-2-2.1-2c-1.2,0-2.1,0.9-2.1,2v9H175c-1.2,0-2.1,0.9-2.1,2c0,1.1,1,2,2.1,2h9.6v9
			c0,1.1,1,2,2.1,2c1.2,0,2.1-0.9,2.1-2v-9h9.6c1.2,0,2.1-0.9,2.1-2C200.7,198.2,199.8,197.3,198.6,197.3z"
        />
        <g>
          <path
            className="yang"
            d="M239.7,199.3c3.1,0,6.1-1.3,8.3-3.5c2.2-2.3,3.4-5.3,3.4-8.5c0-3.2-1.2-6.3-3.4-8.5c-2.2-2.3-5.2-3.5-8.3-3.5
				c-3.1,0-6.1,1.3-8.3,3.5c-2.2,2.3-3.4,5.3-3.4,8.5c0,3.2,1.2,6.3,3.4,8.5C233.7,198,236.6,199.3,239.7,199.3z"
          />
          <path
            className="yang"
            d="M243.9,203.9h-8.3c-9,0-16.3,7.5-16.3,16.8c0,1.5,1.2,2.8,2.7,2.8h35.5c1.5,0,2.7-1.3,2.7-2.8
				C260.2,211.4,252.9,203.9,243.9,203.9z"
          />
          <path
            className="yang"
            d="M294.1,176h-8.3c-9,0-16.3,7.5-16.3,16.8c0,1.5,1.2,2.8,2.7,2.8h35.5c1.5,0,2.7-1.3,2.7-2.8
				C310.3,183.5,303,176,294.1,176z"
          />
          <path
            className="yang"
            d="M289.9,171.5c3.1,0,6.1-1.3,8.3-3.5c2.2-2.3,3.4-5.3,3.4-8.5c0-3.2-1.2-6.3-3.4-8.5c-2.2-2.3-5.2-3.5-8.3-3.5
				c-3.1,0-6.1,1.3-8.3,3.5c-2.2,2.3-3.4,5.3-3.4,8.5c0,3.2,1.2,6.3,3.4,8.5C283.8,170.2,286.8,171.5,289.9,171.5z"
          />
          <path
            className="yang"
            d="M294.1,231.7h-8.3c-9,0-16.3,7.5-16.3,16.8c0,1.5,1.2,2.8,2.7,2.8h35.5c1.5,0,2.7-1.3,2.7-2.8
				C310.3,239.2,303,231.7,294.1,231.7z"
          />
          <path
            className="yang"
            d="M298.1,223.7c2.2-2.3,3.4-5.3,3.4-8.5c0-3.2-1.2-6.3-3.4-8.5c-2.2-2.3-5.2-3.5-8.3-3.5c-3.1,0-6.1,1.3-8.3,3.5
				c-2.2,2.3-3.4,5.3-3.4,8.5c0,3.2,1.2,6.3,3.4,8.5c2.2,2.3,5.2,3.5,8.3,3.5C293,227.2,296,225.9,298.1,223.7z"
          />
          <path
            className="yang"
            d="M340.1,199.3c3.1,0,6.1-1.3,8.3-3.5c2.2-2.3,3.4-5.3,3.4-8.5c0-3.2-1.2-6.3-3.4-8.5c-2.2-2.3-5.2-3.5-8.3-3.5
				c-3.1,0-6.1,1.3-8.3,3.5c-2.2,2.3-3.4,5.3-3.4,8.5c0,3.2,1.2,6.3,3.4,8.5C334,198,337,199.3,340.1,199.3z"
          />
          <path
            className="yang"
            d="M344.2,203.9h-8.3c-9,0-16.3,7.5-16.3,16.8c0,1.5,1.2,2.8,2.7,2.8h35.5c1.5,0,2.7-1.3,2.7-2.8
				C360.5,211.4,353.2,203.9,344.2,203.9z"
          />
        </g>
        <path
          className="yang"
          d="M143.9,160.7c-1.9-4-6-6.8-10.8-6.8H51.5c-6.6,0-12,5.4-12,12v58.6c0,4.7,2.7,8.8,6.6,10.7c1.5,5.4,6.5,9.4,12.4,9.4h81.7
			c7.1,0,12.9-5.8,12.9-13v-58.6C153.1,167.3,149.2,162.4,143.9,160.7z M106.8,177.6h23.5c1.6,0,2.9,1.3,2.9,3c0,1.6-1.3,3-2.9,3
			h-23.5c-1.6,0-2.9-1.3-2.9-3C103.9,178.9,105.2,177.6,106.8,177.6z M103.9,192.3c0-1.6,1.3-3,2.9-3h23.5c1.6,0,2.9,1.3,2.9,3v0.1
			c0,1.6-1.3,3-2.9,3h-23.5C105.2,195.4,103.9,194,103.9,192.3L103.9,192.3z M103.9,204.2c0-1.6,1.3-3,2.9-3h23.5
			c1.6,0,2.9,1.3,2.9,3v0.1c0,1.6-1.3,3-2.9,3h-23.5C105.2,207.2,103.9,205.9,103.9,204.2L103.9,204.2z M96.8,199
			c0,0.4-0.2,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.5H78.9h-0.1c1-0.9,1.9-2.1,2.4-3.3c0.6-1.3,0.9-2.7,0.9-4c0-0.6-0.1-1.1-0.1-1.7
			c1.1-0.5,2.2-0.7,3.4-0.7h3.3c2.2,0,4.2,0.9,5.8,2.4C95.9,194.7,96.8,196.8,96.8,199z M87,213.3c0,0.5-0.2,1.1-0.6,1.4
			c-0.4,0.4-0.9,0.6-1.4,0.6H59.7c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.2-0.2-0.3-0.4-0.4-0.7
			c-0.1-0.2-0.2-0.5-0.2-0.8c0-2.7,1.1-5.3,3-7.2c1.9-1.9,4.5-3,7.2-3h9c2.7,0,5.3,1.1,7.2,3C85.9,208,87,210.6,87,213.3z
			 M82.7,177.9c1.1-1.2,2.7-1.8,4.3-1.8c1.2,0,2.4,0.4,3.4,1c1,0.7,1.8,1.6,2.2,2.8c0.5,1.1,0.6,2.4,0.3,3.6
			c-0.2,1.2-0.8,2.3-1.7,3.1c-0.9,0.9-1.9,1.4-3.1,1.7c-1.2,0.2-2.4,0.1-3.5-0.4c-1.1-0.5-2.1-1.3-2.7-2.3c-0.7-1-1-2.2-1-3.4
			C80.9,180.6,81.5,179,82.7,177.9z M65,193.2c0-1.5,0.4-2.9,1.2-4.1c0.8-1.2,1.9-2.2,3.3-2.7c1.3-0.6,2.8-0.7,4.2-0.4
			c1.4,0.3,2.7,1,3.7,2c1,1,1.7,2.3,2,3.8c0.3,1.4,0.1,2.9-0.4,4.3c-0.6,1.3-1.5,2.5-2.7,3.3c-1.2,0.8-2.6,1.2-4.1,1.2
			c-1.9,0-3.8-0.8-5.2-2.2C65.8,197.1,65,195.2,65,193.2L65,193.2z M54.6,177.9c1.1-1.2,2.7-1.8,4.3-1.8c1.2,0,2.4,0.4,3.4,1
			c1,0.7,1.8,1.6,2.2,2.8c0.5,1.1,0.6,2.4,0.3,3.6c-0.2,1.2-0.8,2.3-1.7,3.1c-0.9,0.9-1.9,1.4-3.1,1.7c-1.2,0.2-2.4,0.1-3.5-0.4
			c-1.1-0.5-2.1-1.3-2.7-2.3c-0.7-1-1-2.2-1-3.4C52.8,180.6,53.5,179,54.6,177.9z M50.3,193.2c1.5-1.5,3.6-2.4,5.7-2.4h3.3
			c1.2,0,2.3,0.2,3.4,0.7c-0.1,0.6-0.1,1.1-0.1,1.7c0,1.4,0.3,2.8,0.9,4c0.6,1.3,1.4,2.4,2.4,3.3H49.6c-0.4,0-0.8-0.2-1.1-0.5
			c-0.3-0.3-0.5-0.7-0.5-1.1C47.9,196.8,48.8,194.7,50.3,193.2z M151.2,231.8c0,6.1-4.9,11.1-11,11.1H58.5c-4.5,0-8.4-2.8-10.1-6.7
			c1,0.3,2,0.4,3.1,0.4h81.7c6.6,0,12-5.4,12-12v-58.6c0-1-0.1-2-0.4-2.9c3.8,1.8,6.4,5.6,6.4,10.1V231.8z"
        />
      </g>
    </g>
  </svg>
);
