export default () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 51.7 27.4">
    <g>
      <polygon
        className="yin"
        points="28.8,5.8 33.8,5.8 36.7,17.8 39.6,5.8 44.7,5.8 44.7,21.6 41.5,21.6 41.5,8.4 41.4,8.4 38.4,21.6 
			35,21.6 32,8.4 32,8.4
       32,21.6 28.8,21.6 		"
      />
      <polygon
        className="yin"
        points="13.8,5.8 25.8,5.8 25.8,8.6 17.2,8.6 17.2,11.9 25.1,11.9 25.1,14.7 17.2,14.7 17.2,18.7 26.1,18.7 
			26.1,21.6 13.8,21.6 		"
      />
      <rect x="7" y="5.8" className="yin" width="3.5" height="15.9" />
    </g>
  </svg>
)
