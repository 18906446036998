import dayjs from 'dayjs'
import { DateTime } from 'luxon'

type DateModeType = 'LABEL' | 'DATE'

export const now = (mode: DateModeType): any => {
  if (mode === 'DATE') return dayjs()
  if (mode === 'LABEL') return 'now'
}

export const endOfDay = (mode: DateModeType): any => {
  if (mode === 'DATE') return dayjs().hour(23).minute(59).second(59)
  if (mode === 'LABEL') return 'endOfDay'
}

export const yesterday = (mode: DateModeType): any => {
  if (mode === 'DATE') {
    return dayjs().add(-1, 'd').hour(0).minute(0).second(0)
  }
  if (mode === 'LABEL') return 'yesterday'
}

export const lastWeek = (mode: DateModeType): any => {
  if (mode === 'DATE') return dayjs().add(-7, 'd').hour(0).minute(0).second(0)
  if (mode === 'LABEL') return 'lastWeek'
}

export const lastMonth = (mode: DateModeType): any => {
  if (mode === 'DATE')
    return dayjs().add(-1, 'month').hour(0).minute(0).second(0)
  if (mode === 'LABEL') return 'lastMonth'
}
