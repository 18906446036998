import { Empty as EmptyAnt } from 'antd';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Icon } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './empty.component.scss';

export declare namespace EmptyType {
  type Props = {
    className?: string;
    config?: {
      mode?:
        | { name: 'noData' }
        | { name: 'commingSoon' }
        | { name: 'maintenance' }
        | { name: 'disabled' }
        | { name: 'select'; text: string };
    };
  };
}

export const Empty = ({
  className = '',
  config: { mode } = {},
}: EmptyType.Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`
      empty
      ${className}
    `}
    >
      {mode?.name === 'noData' ? (
        <EmptyAnt
          imageStyle={{
            height: 'auto',
          }}
          image={
            <Icon
              config={{
                type: 'faDatabaseSolid',
                color: 'disabled',
              }}
            />
          }
          description={
            <Write
              data={{ item: t('watermelon-no-data') }}
              config={{
                mode: 'value-small',
                color: 'disabled',
              }}
            />
          }
        />
      ) : mode?.name === 'commingSoon' ? (
        <div className="empty__commingSoon">
          <Write
            data={{ item: 'Comming soon' }}
            config={{
              mode: 'value-small',
              color: 'disabled',
            }}
          />
        </div>
      ) : mode?.name === 'maintenance' ? (
        <div className="empty__maintenance">
          <Write
            data={{ item: 'Maintenance' }}
            config={{
              mode: 'value-small',
              color: 'disabled',
            }}
          />
        </div>
      ) : mode?.name === 'disabled' ? (
        <div className="empty__notAuthorized">
          <Write
            data={{ item: 'Disabled' }}
            config={{
              mode: 'value-small',
              color: 'disabled',
            }}
          />
        </div>
      ) : mode?.name === 'select' ? (
        <div className="empty__select">
          <Write
            data={{ item: mode.text }}
            config={{ color: 'primary', mode: 'value-large' }}
          ></Write>
        </div>
      ) : null}
    </div>
  );
};
