import { AutoComplete } from '@gimlite/watermelon/components/autocomplete/autocomplete.component';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Image } from '@gimlite/watermelon/components/image/image.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { TextArea } from '@gimlite/watermelon/components/textarea/textarea.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { cars as carsData } from '@gimlite/watermelon/data/cars.data';
import { countries as countriesData } from '@gimlite/watermelon/data/countries.data';
import {
  foreignPlates,
  isValidPlateFNSM,
} from '@gimlite/watermelon/functions/validator.function';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createFpsService } from '../../createFps.machine';
import { CreateFPSWizardContext } from '../../createFps.wizard';
import './info.step.scss';

export const InfoStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateFPSWizardContext>) => {
  const [currentBrand, setCurrentBrand] = useState(null);
  const [lastBrand, setLastBrand] = useState(null);
  const { t, lang } = useTranslation();

  const fps = useSelector(createFpsService, ({ context }: any) => context.fps);

  const formattedDate = useCallback(
    (date: string) =>
      t(`DATETIME_HUMAN`)
        .replace('[DATE]', DateTime.fromISO(date).toFormat(`DDDD`))
        .replace('[TIME]', DateTime.fromISO(date).toFormat(`TT`)),
    [lang],
  );

  const currentModel = useMemo(
    () =>
      currentBrand && carsData?.[currentBrand]
        ? carsData[currentBrand].map((value) => ({ value, label: value }))
        : [],
    [currentBrand],
  );

  const countriesFormatted = useMemo(() => {
    if (lang)
      return countriesData?.[lang]
        ? countriesData[lang].map(({ label, value }) => ({
            label,
            value: value.ISO2,
          }))
        : [];
    return [];
  }, [lang]);

  const lpnHelp = useCallback(
    (text: string) => {
      const translatedText = t(text);

      return (
        <li
          dangerouslySetInnerHTML={{
            __html: translatedText,
          }}
        ></li>
      );
    },
    [lang],
  );

  useEffect(() => {
    if (context?.country && lang) {
      submit({
        countryISO:
          countriesData[lang!].find(({ label }) => label === context.country)
            ?.value?.ISO2 || null,
      });
    }
  }, [context?.country, lang]);

  return (
    <Form
      data={{ value: context }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          if (lastBrand === null || lastBrand !== value.brand)
            setLastBrand(() => value.brand);

          if (lastBrand !== value.brand) {
            setLastBrand(() => value.brand);
            value.model = '';
          }

          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          zones: [
            ['cameraEnter', 'cameraExit'],
            ['form', 'form'],
          ],
          rows: ['minmax(300px, 1fr)', 'min-content'],
          columns: ['1fr', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'cameraEnter' }}>
          <Widget
            config={{
              full: true,
              title: formattedDate(fps?.entry?.date),
              shadow: false,
            }}
          >
            <div className="infoCameraContain">
              <Image
                data={{
                  src: fps?.entry?.camera,
                }}
                config={{
                  size: 'full',
                  preview: true,
                }}
              />
              <Badge
                className="infoCameraBadge"
                config={{
                  size: 'xlarge',
                  mode: 'degraded',
                  radius: false,
                  text: t(`entry`),
                  icon: 'faArrowRightToBracketSolid',
                  color: 'success',
                }}
              ></Badge>
            </div>
          </Widget>
        </Zone.Area>

        <Zone.Area config={{ area: 'cameraExit' }}>
          <Widget
            config={{
              full: true,
              title: formattedDate(fps?.exit?.date),
              shadow: false,
            }}
          >
            <div className="infoCameraContain">
              <Image
                data={{
                  src: fps?.exit?.camera,
                }}
                config={{
                  size: 'full',
                  preview: true,
                }}
              />
              <Badge
                className="infoCameraBadge"
                config={{
                  size: 'xlarge',
                  mode: 'degraded',
                  radius: false,
                  text: t(`exit`),
                  icon: 'faArrowRightFromBracketSolid',
                  color: 'warn',
                }}
              ></Badge>
            </div>
          </Widget>
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <Widget config={{ full: true, shadow: false }}>
            <Col>
              <Row config={{ wrap: false }}>
                <Form.Item
                  className="infoFormItem"
                  config={{
                    way: 'vertical',
                    name: 'country',
                    label: t('country'),
                    rules: [{ required: true }],
                  }}
                >
                  <AutoComplete
                    data={{
                      items: countriesFormatted || [],
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="infoFormItem"
                  config={{
                    way: 'vertical',
                    name: 'lpn',
                    label: `${t('immatriculation')}`,
                    tooltip: (
                      <div className="lpnInfo">
                        <span className="lpnInfoTitle">
                          {t('registrationInputFormats')}
                        </span>
                        <ul className="lpnInfoList">
                          {lpnHelp('INPUT_REGISTRATION_FRENCH_SVI')}
                          {lpnHelp('INPUT_REGISTRATION_FRENCH_FNI')}
                          {lpnHelp('INPUT_REGISTRATION_FOREIGN_FNI')}
                        </ul>
                      </div>
                    ),
                    rules: [
                      { required: true },
                      {
                        validator:
                          context?.countryISO === 'FR'
                            ? isValidPlateFNSM
                            : foreignPlates,
                      },
                    ],
                  }}
                >
                  <Input
                    config={{
                      placeholder:
                        !context?.countryISO || context?.countryISO === 'FR'
                          ? 'AA-000-BB'
                          : 'GE12345',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="infoFormItem"
                  config={{
                    way: 'vertical',
                    name: 'brand',
                    label: t('brand'),
                    rules: [{ required: true }],
                  }}
                >
                  <AutoComplete
                    data={{
                      items: Object.keys(carsData).map((value) => ({
                        label: value,
                        value,
                      })),
                    }}
                    handleEvent={{
                      input: (value: any) => setCurrentBrand(() => value),
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="infoFormItem"
                  data={{ defaultValue: '' }}
                  config={{
                    way: 'vertical',
                    name: 'model',
                    label: t('model'),
                    rules: [],
                  }}
                >
                  <AutoComplete
                    data={{
                      items: currentModel,
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                    }}
                  />
                </Form.Item>

                <Form.Item
                  className="infoFormItem"
                  data={{ defaultValue: '' }}
                  config={{
                    way: 'vertical',
                    name: 'comment',
                    label: `${t('comment')} (${t('optional').toLowerCase()})`,
                    rules: [],
                  }}
                >
                  <TextArea config={{ width: 'medium' }} />
                </Form.Item>
              </Row>
            </Col>
          </Widget>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
