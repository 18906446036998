import React from 'react';
import { Placement1Axis } from '../../types/component.type';
import { Badge, BadgeType } from '../badge/badge.component';
import { Write } from '../write/write.component';
import './blurb.component.scss';

class Stacic {
  public static interpretAlign = Object.freeze({
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  });
}

export declare namespace BlurbType {
  type Props = {
    className?: string;
    data: {
      title: string;
      description?: string;
      subcontain?: React.ReactNode;
      badge?: BlurbType.Data.Badge[];
    };
    config?: {
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
    };
    handleEvent?: {
      onClick?: () => void;
    };
  };

  namespace Data {
    type Badge = {
      color: BadgeType.Config.Color;
      text: BadgeType.Config.Text;
    };
  }
}

export const Blurb = ({
  data: { title, description, subcontain, badge },
  config: { vertical = 'start' } = {},
  className = '',
  handleEvent: { onClick } = {},
}: BlurbType.Props) => {
  return (
    <div
      className={`
        ${className ?? ''}
        blurb
    `}
      style={{ alignItems: Stacic.interpretAlign[vertical] }}
      onClick={onClick}
    >
      <div className="blurb__title">
        <Write
          data={{ item: title }}
          className="blurb__title__item"
          config={{
            lineHeight: true,
            mode: 'namise',
          }}
        ></Write>
        <div className="blurb__title__badges">
          {badge &&
            badge?.length > 0 &&
            badge.map(({ color, text }, index) => (
              <div
                key={`blurb__title__badges__item-${index}`}
                className="blurb__title__badges__item"
              >
                <Badge config={{ color, text, size: 'xsmall' }} />
              </div>
            ))}
        </div>
      </div>
      {description && (
        <Write
          data={{ item: description }}
          className="blurb__description"
          config={{ lineHeight: true, mode: 'namise-description' }}
        ></Write>
      )}
      {subcontain && <div className="blurb__subcontain">{subcontain}</div>}
    </div>
  );
};
