import { dispatch } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { useEffect, useMemo, useState } from 'react';
import { Elevate } from './components/elevate/elevate.component';
import { useRootStore } from './hook/useRootStore.hook';
import { wizardService } from './machines/wizard.machine';
import { GlobalStoreContextWizardType } from './store/global.store';

export const WizardManager = () => {
  const { GlobalStore } = useRootStore();
  const allWizards = GlobalStore?.wizards ?? [];
  const [currentWizard, setWizard] = useState<GlobalStoreContextWizardType>();

  const wizardToShow: string = useSelector(
    wizardService,
    ({ context }: any) => context.wizardToShow,
  );
  const wizardStore: string = useSelector(
    wizardService,
    ({ context }: any) => context.wizardStore,
  );

  useEffect(() => {
    if (!wizardToShow) {
      setWizard(undefined);
      return;
    }

    const WizardToUse = allWizards?.find(({ id }) => id === wizardToShow);
    if (!WizardToUse) return;

    // If same machine, don't do anything
    if (currentWizard?.machine?.id === WizardToUse.machine?.id) return;

    // Kill previous machine
    if (currentWizard?.machine) {
      console.log('KILL WIZARD', currentWizard.machine.id);
      currentWizard.machine.send('KILL', {});
    }

    // Wakeup machine
    console.log('WAKEUP WIZARD', WizardToUse.machine.id);
    WizardToUse.machine.send('WAKEUP', wizardStore[wizardToShow] ?? {});

    setWizard(WizardToUse);

    return () => {
      WizardToUse.machine.send('KILL', {});
    };
  }, [wizardToShow]);

  const Component = useMemo(
    () => currentWizard && currentWizard.component,
    [currentWizard],
  );

  return (
    <>
      {Component && (
        <Elevate
          handleEvent={{
            exit: () => {
              dispatch('SHOW_WIZARD', { id: '' });
            },
          }}
        >
          <Component />
        </Elevate>
      )}
    </>
  );
};
