import { Checkbox } from '@gimlite/watermelon/components/checkbox/checkbox.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { isChecked } from '@gimlite/watermelon/functions/validator.function';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import React, { useMemo } from 'react';
import { createFpsService } from '../../createFps.machine';
import { CreateFPSWizardContext } from '../../createFps.wizard';
import './confirm.step.scss';

export const ConfirmStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateFPSWizardContext>) => {
  const { t, lang }: any = useTranslation();

  const fps = useSelector(createFpsService, ({ context }: any) => context.fps);

  const cases = useMemo(() => {
    if (!fps?.confirm) return [];

    const result: { title: React.ReactNode; description: React.ReactNode }[] =
      [];

    if (fps?.confirm?.right) {
      result.push({
        title: (
          <Write
            data={{ item: t('rightInThePeriod') }}
            config={{
              mode: 'title-small',
            }}
          ></Write>
        ),

        description: (
          <Write
            data={{ item: t('RIGHT_IN_PERIOD_ADVERTISSEMENT') }}
            config={{ mode: 'value-small' }}
          />
        ),
      });
    }

    if (fps?.confirm?.claim) {
      result.push({
        title: (
          <Write
            data={{ item: t('rapo') }}
            config={{
              mode: 'title-small',
            }}
          ></Write>
        ),
        description: (
          <>
            <div className="confirmForReason">
              <Write
                data={{ item: t('RAPO_ADVERTISSEMENT') }}
                config={{ mode: 'value-small' }}
              />
              {fps?.claimReasons && fps?.claimReasons?.length > 0 && (
                <div className="confirmForReasonContent">
                  <Write
                    data={{ item: 'Reasons :' }}
                    config={{ mode: 'value-small' }}
                  />
                  <ul className="confirmForReasonContentList">
                    {fps?.claimReasons.map((value: string) => (
                      <li>
                        <Write
                          data={{ item: t(`CLAIM_REASON_${value}`) }}
                          config={{ mode: 'value-small' }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </>
        ),
      });
    }

    return result;
  }, [fps?.confirm, lang]);

  return (
    <Form
      className="confirmForm"
      data={{ value: context }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Write
        data={{ item: t('aboutFineVehicle') }}
        config={{
          mode: 'title-small',
        }}
      ></Write>
      <Space config={{ count: 2 }}></Space>
      {cases.map(({ title, description }, index) => {
        return (
          <label
            key={`confirm-wizard-create-fps-choice-${index}`}
            id={`confirm${index}`}
            className={`confirmCase ${
              context?.[`confirm${index}`] ? 'confirmCaseSelected' : ''
            }`}
          >
            <Col>
              <Row>{title}</Row>
              <Space config={{ count: 2 }}></Space>
              <Row>
                <Form.Item
                  data={{ defaultValue: '' }}
                  config={{
                    way: 'horizontal',
                    name: `confirm${index}`,
                    rules: [
                      {
                        required: true,
                      },
                      {
                        validator: isChecked,
                      },
                    ],
                  }}
                >
                  <Checkbox />
                </Form.Item>
                <Space config={{ count: 2, way: 'horizontal' }}></Space>

                {description}
                <Write
                  className="confirmWrite"
                  // data={{ item: description }}
                  data={{ item: '' }} //! A CORRIGER
                ></Write>
              </Row>
            </Col>
          </label>
        );
      })}
    </Form>
  );
};
