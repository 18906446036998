import TextAreaAnt from 'antd/es/input/TextArea';
import type { Size } from '../../types/component.type';
import { Form, FormItemType } from '../form/form.component';
import './textarea.component.scss';

export declare namespace TextAreaType {
  type Props = {
    className?: string;
    handleEvent?: {
      input?: (value: string) => void;
    };
    data?: {
      value?: TextAreaType.Data.Value;
      defaultValue?: TextAreaType.Data.Value;
    };
    config?: {
      placeholder?: string;
      minLength?: number;
      maxLength?: number;
      resize?: boolean;
      width?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      height?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      disabled?: boolean;
    };
  };

  namespace Data {
    type Value = string | number;
  }
}

export const TextArea = ({
  handleEvent: { input } = {},
  config: {
    width,
    height,
    resize = false,
    placeholder = '',
    minLength = 1,
    maxLength,
    disabled = false,
  } = {},
  data: { defaultValue, value } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<TextAreaType.Props>) => {
  return (
    <Form.Context.Consumer>
      {(form) => (
        <TextAreaAnt
          style={{
            resize: resize ? 'vertical' : 'none',
          }}
          onChange={(e) => {
            onChangeFormItem?.(e.target.value);
            input?.(e.target.value);
          }}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={valueFormItem ?? value}
          disabled={disabled}
          className={`
            formItemTargetWidth--${form.width || width || 'medium'}
            formItemTargetHeight--${form.height || height || 'medium'}
            textarea 
            textarea--width--${form.width || width || 'medium'}
            textarea--height--${form.height || height || 'medium'}
            textarea--padding--${form.height || height || 'medium'}
            ${className}`}
          size="large"
          {...formItemParams}
        ></TextAreaAnt>
      )}
    </Form.Context.Consumer>
  );
};
