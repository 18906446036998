const targetStockCSSInject = document.documentElement;

export const getCSS = (variable: string) => {
  try {
    return (
      getComputedStyle(targetStockCSSInject)?.getPropertyValue(variable) ??
      'green'
    );
  } catch (error) {
    return 'green';
  }
};

export const setCSS = (variable: string, value: string) =>
  targetStockCSSInject.style.setProperty(variable, value);
