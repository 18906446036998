export function findId(obj: object): any {
  const keys = Object.keys(obj)
  const values = keys.reduce<any[]>((acc, key) => {
    const value = obj[key]
    if (key === '_id') {
      return acc.concat(value)
    } else if (typeof value === 'object' && value !== null) {
      return acc.concat(findId(value))
    }
    return acc
  }, [])

  return values.length > 0 ? values[0] : null
}
