import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { cameras as camerasGQL } from './gql/cameras.gql';
import { event as eventGQL, events as eventsGQL } from './gql/events.gql';
import { search as searchCamerasRender } from './projection/cameras.render';
import {
  read as readEventRender,
  search as searchEventsRender,
} from './projection/events.render';

const initialContext = {
  // cameras: undefined,
  // search: undefined,
  // read: undefined,
};

const machine = createMachine(
  {
    id: 'events',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
          RESET: { actions: 'reset' },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
            actions: assign({
              cameras: () => undefined,
              search: () => undefined,
              read: () => undefined,
              error: () => undefined,
            }),
          },
          SEARCH_EVENTS: {
            target: 'search',
          },
          READ_EVENT: {
            target: 'read',
          },
          SEARCH_CAMERAS: {
            target: 'cameras',
          },
          RESET: { actions: 'reset' },
        },
      },
      cameras: {
        invoke: {
          id: 'cameras',
          src: 'cameras',
          onDone: {
            target: 'idle',
            actions: assign({
              cameras: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              cameras: () => [],
              error: (_, { data }) => data,
            }),
          },
        },
      },
      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign({
              search: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
      read: {
        invoke: {
          id: 'read',
          src: 'read',
          onDone: {
            target: 'idle',
            actions: assign({
              read: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({ error: (_, { data }) => data }),
          },
        },
      },
    },
    on: {
      LOGOUT: { actions: assign(initialContext) },
    },
  },

  {
    actions: {
      reset: assign({
        read: () => undefined,
        search: () => undefined,
      }),
    },
    services: {
      search: (context, params) =>
        requestGQL({
          params,
          gql: eventsGQL,
          render: (res) => searchEventsRender(context, res),
        }),
      read: (context, params) =>
        requestGQL({
          params,
          gql: eventGQL,
          render: (res) => readEventRender(context, res),
        }),
      cameras: (context, params) =>
        requestGQL({
          params,
          gql: camerasGQL,
          render: (res) => searchCamerasRender(context, res),
        }),
    },
  },
);

export const eventsService = interpret(machine).start();
