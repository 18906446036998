import Color from 'color';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { setCSS } from '../functions/css.function';
import { RootStoreMobX } from './root.store';
import { Chart } from 'chart.js';

export type ThemeStoreContextType = {
  theme: 'dark' | 'light';
  provider: {
    defaultTheme: ThemeStoreContextType['theme'];
    color: {
      dark: string;
      light: string;
    };
  };
};

const colors = Object.freeze({
  theme: {
    light: {
      background: '#F5F5F5',
      layout: '#ffffff',
      card: '#ffffff',
      border: '#dcdcdc',
      elevate: 'rgba(10, 10, 10, 0.5)',
      intermediate: '#dddddd',
      disabled: '#a0a0a0',
      label: '#848484',
      text: '#333333',
      yin: '#ffffff',
      yang: '#333333',
      shadow: 'rgba(0, 0, 0, 0.15)',
      error: '#ff4d4f',
      warn: '#f48c06',
      info: '#009fb7',
      yell: '#cfba00',
      success: '#51cf84',
    },
    dark: {
      background: '#121212',
      layout: '#1c1c1c',
      card: '#2C2C2C',
      border: '#4d4d4d',
      intermediate: '#383838',
      elevate: 'rgba(10, 10, 10, 0.5)',
      disabled: '#989898',
      label: '#DEDEDE',
      text: '#e8e8e8',
      yin: '#333333',
      yang: '#e8e8e8',
      shadow: 'rgba(0, 0, 0, 0.2)',
      error: '#ff4d4f',
      warn: '#c46b03',
      info: '#00798f',
      yell: '#a38a00',
      success: '#3a9e5c',
    },
  },
  base: {
    white: '#ffffff',
    black: '#333333',
  },
} as const);

export class ThemeStoreMobX {
  root: RootStoreMobX;
  theme: ThemeStoreContextType['theme'] = 'light';
  colorsTheme = colors.theme[this.theme];
  colorsDynamic?: ThemeStoreContextType['provider']['color'];
  colorsBase = colors.base;
  @observable load: boolean = false;

  constructor(root: RootStoreMobX) {
    this.root = root;
    makeAutoObservable(this);
  }

  public init() {
    this.initAllColors;
  }

  @action public initAllColors({
    color,
    defaultTheme,
  }: ThemeStoreContextType['provider']) {
    runInAction(() => {
      this.theme =
        (localStorage.getItem('theme') as
          | ThemeStoreContextType['theme']
          | null) || defaultTheme;
      this.colorsDynamic = color;
      this.setTheme(this.theme);
    });
  }

  @action public setTheme(
    theme: ThemeStoreContextType['theme'],
    load?: boolean,
  ) {
    this.load = false;
    this.theme = theme;
    this.colorsTheme = colors.theme[theme];
    localStorage.setItem('theme', theme);
    this.initColorsTheme(theme);
    this.initColorsDynamic(theme);
    this.initColorsBase(theme);
    this.initColorsChart(theme);
    this.load = true;
  }

  @action private initColorsTheme(theme: ThemeStoreContextType['theme']) {
    Object.entries(this.colorsTheme).forEach(([key, value]) =>
      setCSS(`--color-${key}`, value),
    );
  }

  @action private initColorsChart(theme: ThemeStoreContextType['theme']) {
    // Chart.defaults.borderColor = this.colorsTheme.border;
  }

  @action private initColorsDynamic(theme: ThemeStoreContextType['theme']) {
    if (this.colorsDynamic) {
      const colorPrimaryOver = !new Color(this.colorsDynamic[theme]).isDark()
        ? new Color(this.colorsBase.black).alpha(1).string()
        : new Color(this.colorsBase.white).alpha(1).string();
      setCSS(`--color-primary`, this.colorsDynamic[theme]);
      setCSS(`--color-primary-over`, colorPrimaryOver);
      for (let i = 5; i <= 100; i += 5) {
        setCSS(
          `--color-primary-alpha-${i}`,
          `${new Color(this.colorsDynamic[theme]).alpha(i / 100)}`,
        );
      }

      setCSS(
        '--color-selected',
        `${new Color(this.colorsDynamic[theme]).alpha(0.2)}`,
      );

      setCSS(
        '--color-over',
        `${new Color(this.colorsDynamic[theme]).alpha(0.1)}`,
      );
    }
  }

  @action private initColorsBase(theme: ThemeStoreContextType['theme']) {
    const colorInfoOver = new Color(this.colorsTheme.info).isDark()
      ? new Color(colors.base.white).alpha(1).toString()
      : new Color(colors.base.black).alpha(1).toString();

    setCSS(`--color-white`, colors.base.white);
    setCSS(`--color-black`, colors.base.black);
    setCSS(`--color-info-over`, colorInfoOver);
  }
}
