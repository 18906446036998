import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Statistic as StatisticAnt } from 'antd';
import { getCSS } from '../../functions/css.function';
import './statistic.component.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../hook/useRootStore.hook';

export declare namespace StatisticType {
  type Props = {
    className?: string;
    data: {
      value: number;
    };
    config?: {
      precision?: number;
      way?: boolean;
      suffix?: any;
      explain?: string;
    };
  };
}

export const Statistic = observer(
  ({
    config: { precision, way, suffix, explain } = {},
    data: { value },
    className = '',
  }: StatisticType.Props) => {
    const { ThemeStore } = useRootStore();

    const color = useMemo(
      () =>
        Object.freeze({
          error: getCSS('--color-error'),
          warn: getCSS('--color-warn'),
          success: getCSS('--color-success'),
        }),
      [ThemeStore.load, ThemeStore.theme],
    );

    const defineColor = useMemo(() => {
      if (!(typeof value === 'number' && value && way && value !== 0)) {
        return undefined;
      } else if (Math.sign(value) === 1) {
        return color.success;
      } else {
        return color.error;
      }
    }, [value, color, way]);

    const definePrefix = useMemo(() => {
      if (!(typeof value === 'number' && value && way && value !== 0)) {
        return undefined;
      } else if (Math.sign(value) === 1) {
        return <ArrowUpOutlined />;
      } else {
        return <ArrowDownOutlined />;
      }
    }, [value, way]);

    return (
      <div
        className={`
      statistic 
      ${className}`}
      >
        <div className="statistic__contain">
          <StatisticAnt
            value={value}
            precision={precision}
            valueStyle={{
              color: defineColor,
            }}
            prefix={definePrefix}
            suffix={suffix}
          ></StatisticAnt>
        </div>
        <span className="statistic__explain">{explain}</span>
      </div>
    );
  },
);
