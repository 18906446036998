let timeoutVa = setTimeout(() => {}, 0);

export function useTimeout() {
  const timeoutFn = (fn: Function, time: number) => {
    clearTimeout(timeoutVa);
    timeoutVa = setTimeout(() => {
      fn();
    }, time);
  };

  return { timeout: timeoutFn };
}
