import { createContext, useContext } from 'react';
import { RootStoreMobX } from '../store/root.store';

export function useRootStore() {
  const context = useContext(RootStoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}

export const RootStoreContext = createContext<RootStoreMobX | undefined>(
  undefined,
);
