import { assign, createMachine, interpret } from 'xstate';
import type { requestGQLWithoutSchemaType } from '../functions/request.function';
import { requestGQLWithoutSchema } from '../functions/request.function';
import { Languages } from '../types/lang.type';

export type DocumentType = {
  _id: Languages;
  lang: Languages;
  translations: Record<Languages, string>;
};

export type DictionaryType = {
  documents: DocumentType[];
  key: string;
  languages: Languages[];
};

export type LangContext = {
  defaultLang: Languages;
  lang?: Languages;
  items?: Languages[];
  i18n?: DictionaryType;
  orphanWords: string[];
};

type LangEvent =
  | {
      type: 'SELECTED_LANG';
      lang: Languages;
    }
  | {
      type: 'UPDATE_LANG';
    }
  | {
      type: 'REGISTER_LANG';
      items: Languages[];
    }
  | {
      type: 'REGISTER_ORPHAN_WORD';
      word: string;
    }
  | ({
      type: 'I18N';
    } & requestGQLWithoutSchemaType);

type LangService = {
  loadFromStorage: { data: Promise<any> };
  i18n: { data: any };
};

const initialContext: LangContext = {
  defaultLang: 'en_EN',
  lang: 'en_EN',
  items: ['en_EN'],
  i18n: undefined,
  orphanWords: [],
};

const translationMachine = createMachine(
  {
    id: 'lang',
    context: initialContext,
    initial: 'idle',
    schema: {
      context: {} as LangContext,
      events: {} as LangEvent,
      // states: {} as LangState,
      services: {} as LangService,
    },
    states: {
      idle: {
        on: {
          REGISTER_LANG: {
            actions: assign({
              items: (_, { items }) => items,
            }),
          },
          I18N: {
            target: 'i18n',
          },
          UPDATE_LANG: {
            target: 'changeLang',
          },
          REGISTER_ORPHAN_WORD: {
            actions: assign({
              orphanWords: ({ orphanWords }, { word: wordSend }) =>
                !orphanWords.find((word) => word === wordSend)
                  ? [...orphanWords, wordSend]
                  : [...orphanWords],
            }),
          },
        },
      },
      i18n: {
        on: {
          I18N: {
            target: 'i18n',
          },
        },
        invoke: {
          id: 'i18n',
          src: 'i18n',
          onDone: {
            actions: [
              assign({
                i18n: (_, { data }) => data,
                items: (_, { data }) => data?.languages,
              }),
            ],
            target: 'idle',
          },
          onError: {
            target: 'idle',
          },
        },
      },
      changeLang: {
        invoke: {
          id: 'changeLang',
          src: 'changeLang',
          onDone: {
            target: 'idle',
          },
          onError: {
            target: 'idle',
          },
        },
      },
    },
    on: {
      SELECTED_LANG: {
        actions: [
          assign({
            lang: (ctx, { lang }) => lang || ctx.defaultLang,
          }),
        ],
      },
    },
  },
  {
    services: {
      i18n: (context, { operationName, operationType, params, render }: any) =>
        requestGQLWithoutSchema({
          operationName,
          operationType,
          params,
          render,
        }),
      changeLang: ({ lang }) =>
        requestGQLWithoutSchema({
          operationName: 'updatelang',
          operationType: 'MUTATION',
          params: { lang },
          render: (data) => data,
        }),
    },
  },
);

const translationService = interpret(translationMachine).start();

export { translationService };
