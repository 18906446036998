export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.5 169.23">
    <g>
      <path
        d="M41.2,19.12v26.6H65.4V66H41.2v43c0,12.5,3.3,18.6,13,18.6a34.06,34.06,0,0,0,9.9-1.1l.5,20.8c-4,1.5-11,2.7-19.3,2.7-9.7,0-17.8-3.3-22.7-8.5-5.6-5.9-8.2-15.4-8.2-29.1V65.92H0V45.62H14.4V26.52Z"
        className="black"
      />
      <path
        d="M160,145c-5.5,2.6-16.2,5.1-28.8,5.1-32.3,0-53.1-20.5-53.1-52.3,0-30.4,20.8-54.4,57.3-54.4,9.3,0,18.7,1.9,24.8,4.5l-4.7,20.5c-4.1-1.9-10.1-3.8-19.1-3.8-19,0-30.4,13.8-30.3,32,0,20.4,13.4,31.9,30.5,31.9a49.64,49.64,0,0,0,19.8-3.9Z"
        className="black"
      />
      <path
        d="M177.9,123.12c5.7,3.5,17.1,7.5,26.4,7.5,10.9,0,15.7-4.4,15.7-10.7,0-6.5-4-9.8-16.3-14.1-20.3-6.9-28.6-17.9-28.5-30,0-18.3,15.3-32.4,39.6-32.4,11.4,0,21.5,2.8,27.5,6.1l-5.2,19.1c-4.4-2.4-12.8-5.9-21.7-5.9s-13.8,4.2-13.8,10.1c0,6.1,4.7,9,17.5,13.7,18.8,6.6,27.3,16.2,27.4,31.2,0,18.8-14.6,32.4-42.2,32.4-12.6,0-24.1-3-31.7-7.2Z"
        className="black"
      />
    </g>
  </svg>
);
