import React from 'react';

export default () => (
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="touched-path"
      d="M5.21348 0.0440917C1.97753 3.21869 0 7.58377 0 12.4339C0 17.284 1.93258 21.7813 5.21348 24.9559L8.44944 21.7813C6.02247 19.4004 4.49438 16.0935 4.49438 12.3898C4.49438 8.73016 5.97753 5.55556 8.44944 3.1746L5.21348 0V0.0440917ZM30.7865 0.0440917L27.5506 3.21869C29.9775 5.59965 31.5056 8.77425 31.5056 12.4339C31.5056 16.0935 30.0225 19.4004 27.5506 21.8254L30.7865 25C34.0225 21.8254 36 17.328 36 12.478C36 7.62787 34.0674 3.26279 30.7865 0.0881834V0.0440917ZM11.6854 6.26102C10.0674 7.84832 9.03371 10.0529 9.03371 12.478C9.03371 14.903 10.0674 17.2399 11.6854 18.8272L14.7865 15.6526C13.9775 14.8589 13.5281 13.7125 13.5281 12.478C13.5281 11.2434 13.9775 10.2734 14.7865 9.43563L11.6854 6.26102ZM24.3146 6.26102L21.2135 9.43563C22.0225 10.2293 22.4719 11.2434 22.4719 12.478C22.4719 13.7125 22.0225 14.8589 21.2135 15.6526L24.3146 18.8272C25.9326 17.2399 26.9663 14.903 26.9663 12.478C26.9663 10.0529 25.9326 7.84832 24.3146 6.26102Z"
    />
  </svg>
);
