import {
  ApolloClient,
  InMemoryCache,
  MutationOptions,
  NormalizedCacheObject,
  QueryOptions,
  TypedDocumentNode,
  createHttpLink,
} from '@apollo/client/core';
import { BFF_TOKEN } from '../config';
export interface IDomain {
  query<T>(
    query: TypedDocumentNode,
    options: QueryOptions,
  ): Promise<{ [key: string]: any } | null>;
  mutation<T>(
    mutation: TypedDocumentNode,
    options: MutationOptions,
    projection: () => T,
  ): Promise<T | null>;
}

export class Domain implements IDomain {
  private client: any;

  constructor(url: string) {
    const urlLink = createHttpLink({ uri: url });

    this.client = new ApolloClient<NormalizedCacheObject>({
      link: urlLink,
      cache: new InMemoryCache({
        addTypename: false,
      }),
      headers: {
        Authorization: `Bearer ${BFF_TOKEN}`,
      },
    });
  }

  async query<T>(
    query: TypedDocumentNode,
    options: QueryOptions,
  ): Promise<{ [key: string]: any } | null> {
    const { data } = await this.client.query({ ...options, query });
    return data;
  }

  async mutation<T>(
    mutation: TypedDocumentNode,
    options: MutationOptions,
  ): Promise<T | null> {
    const { data } = await this.client.mutate({ ...options, mutation });
    return data;
  }
}
