import { useCallback, useMemo } from 'react';
import {
  RouteObject,
  RouterProvider,
  Link as abstractLink,
  useLocation as abstractUseLocation,
  useNavigate as abstractUseNavigate,
  createBrowserRouter,
  createMemoryRouter,
} from 'react-router-dom';
import { Interpreter } from 'xstate';

let currentMachine: RouterType.Machine | null = null;
let masterMachine: RouterType.Machine | null = null;
let allMachines: RouterType.Machine[] = [];
let modMachines: RouterType.Machine[] = [];

export const dispatch = (event: string, payload: Record<string, unknown>) => {
  masterMachine?.send(event, payload);
  [...allMachines, ...modMachines].forEach((machine) => {
    machine.send(event, payload);
  });
};

//! AWAIT FOR --> callback glitch
export const useNavigate = abstractUseNavigate;
export const useLocation = abstractUseLocation;
export const Link = abstractLink;

export declare namespace RouterType {
  type Props = {
    context: string;
    sitemap: RouterType.SiteMap[];
    machine: Machine;
    store: Store;
    modulesMachines: Machine[];
  };

  type Legacy = {
    store: RouterType.Store;
    machine: RouterType.Machine;
  };

  type Machine = Interpreter<any, any, any, any, any>;
  type Store = Record<string, unknown>;
  type SiteMap = Pick<RouteObject, 'path' | 'handle' | 'Component'> & {
    machine?: Machine;
    children?: RouterType.SiteMap[];
  };

  type ComponentProps = {
    machine: RouterType.Machine;
    store: RouterType.Store;
  };
}

export const Router = ({
  context = 'browser',
  sitemap,
  machine: master,
  store,
  modulesMachines,
}: RouterType.Props) => {
  const createRouter = useMemo(
    () => (context === 'browser' ? createBrowserRouter : createMemoryRouter),
    [context],
  );

  const recursiveMachines = useCallback(
    (items: RouterType.SiteMap[]): RouterType.Machine[] =>
      items.reduce(
        (
          acc: RouterType.Machine[],
          { machine, children }: RouterType.SiteMap,
        ) => {
          const alreadyExists = acc.find(
            (m: RouterType.Machine) => machine && m.id === machine.id,
          );
          if (alreadyExists) return acc;

          if (machine) acc.push(machine);
          if (children && children.length)
            acc.push(...recursiveMachines(children as RouteObject[]));
          return acc;
        },
        [],
      ),
    [sitemap],
  );

  const handleRoutes = useCallback(
    (
      items: RouterType.SiteMap[],
      parentMachine: RouterType.Machine | null = null,
    ): RouteObject[] =>
      items.map(({ Component, machine, children, ...rest }: any) => {
        const machineToUse = machine || parentMachine;

        return {
          ...rest,
          element: Component ? (
            <Component machine={machineToUse} store={store} />
          ) : null,
          children: children
            ? [...handleRoutes(children, machineToUse)]
            : undefined,
          loader: machineToUse
            ? () => {
                if (currentMachine?.id === machineToUse?.id) return false;
                if (currentMachine) currentMachine.send('KILL', {});
                machineToUse.send('WAKEUP');
                currentMachine = machineToUse;
                return true;
              }
            : undefined,
        };
      }),
    [sitemap],
  );

  masterMachine = master;
  if (Array.isArray(modulesMachines)) {
    modMachines = useMemo(() => modulesMachines, [modulesMachines]);
  }

  allMachines = useMemo(() => recursiveMachines(sitemap), [sitemap]);
  const routes = handleRoutes(sitemap);
  const router = useMemo(() => createRouter(routes), [sitemap]);
  return <RouterProvider router={router} />;
};
