import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { useCallback } from 'react';
import { Button } from '../../../../components/button/button.component';
import { Col } from '../../../../components/col/col.component';
import { Form } from '../../../../components/form/form.component';
import { Input } from '../../../../components/input/input.component';
import { Row } from '../../../../components/row/row.component';
import { Space } from '../../../../components/space/space.component';
import { Widget } from '../../../../components/widget/widget.component';
import { Write } from '../../../../components/write/write.component';
import { Zone} from '../../../../components/zone/zone.component';
import { requestGQL } from '../../../../functions/request.function';
import { sendOtpMailGql } from '../../../../gql/sendOptMail.gql';
import { notifService } from '../../../../machines/notif.machine';
import {
  State2FAService,
  State2FAServiceContextType,
} from '../../state2FA.machine';
import { State2FAContext } from '../../state2FA.wizard';
import './confirm.step.scss';

export const ConfirmStep = ({
  context: { tfaLabel, withEmailCode },
  validate,
  submit,
}: WizardItemType.Config.Component<State2FAContext>) => {
  const { t, lang } = useTranslation();
  const { email } = useSelector(
    State2FAService,
    ({ context }: { context: State2FAServiceContextType }) => context,
  );

  const sendConfirmationMail = useCallback(async () => {
    try {
      const { success } = await requestGQL({
        gql: sendOtpMailGql,
        params: {},
      });

      if (success) {
        notifService.send('NOTIF', {
          mode: 'success',
          content: t('watermelon-theCodeHasBeenSent'),
        });
      } else {
        notifService.send('NOTIF', {
          mode: 'error',
          content: t('watermelon-theCodeCouldNotBeSent'),
        });
      }
    } catch {
      notifService.send('NOTIF', {
        mode: 'error',
        content: t('watermelon-SERVER_PROBLEM'),
      });
    }
  }, [lang]);

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit({ ...value });
        },
        validate: (value: boolean) => {
          validate(value);
        },
      }}
    >
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <Wizard.CardInfo
            data={{
              title: t('watermelon-intructions'),
              info: (
                <Col>
                  <Write
                    data={{
                      item: t('watermelon-2FA_INSTRUCTION_USE') + '.',
                    }}
                    config={{ mode: 'value-medium' }}
                  />
                  <Space config={{ count: 2 }} />
                  <div className="confirmStep-banner-2fa">
                    <Write
                      data={{ item: tfaLabel || '' }}
                      config={{ mode: 'value-medium', color: 'label' }}
                    />
                    <Space config={{ count: 1 }} />
                    <span className="confirmStep-banner-2fa__code">
                      123 456
                    </span>
                  </div>
                </Col>
              ),
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <Zone
            config={{
              zones: [['informations']],
              rows: ['1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'informations' }}>
              <Widget
                config={{ title: t('watermelon-informations'), shadow: false }}
              >
                <Col>
                  {withEmailCode && (
                    <>
                      <Button
                        handleEvent={{
                          click: () => {
                            sendConfirmationMail();
                          },
                        }}
                        config={{
                          text: t('watermelon-requestAConfirmationCodeByEmail'),
                        }}
                      />
                      <Space config={{ count: 2 }} />

                      <Row>
                        {email && (
                          <Form.Item
                            config={{
                              name: 'codeFromEmail',
                              label: t(
                                'watermelon-enterEmailCodeSentTo',
                              ).replace('[email]', email),
                              rules: [{ required: true }],
                              way: 'vertical',
                            }}
                          >
                            <Input
                              config={{
                                max: 4,
                                width: 'full',
                              }}
                            />
                          </Form.Item>
                        )}
                      </Row>
                    </>
                  )}
                  <Space config={{ count: 2 }} />
                  <Form.Item
                    config={{
                      name: 'codeFromAuth',
                      label: t('watermelon-enterTheGoogleAuthentifatorCode'),
                      rules: [{ required: true }],
                      way: 'vertical',
                    }}
                  >
                    <Input
                      config={{
                        max: 6,
                        width: 'full',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
