import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { formattedDuration, formattedDurationMinutes } from '../../../common/duration';

export const search = (context, { list, paging }) => {
  const listFormatted = list.map(
    ({ _id, previousClaimsForUser, endDate, uncoveredDuration }) => {
      return {
        _id,
        previousClaimsForUser,
        endDate: DateTime.fromISO(endDate).toFormat('dd/MM/yyyy HH:mm:ss'),
        uncoveredDuration: {
          text: formattedDuration(uncoveredDuration * 60 * 1000),
          icon: 'faClockRotateLeftSolid',
          time: uncoveredDuration,
        },
      };
    },
  );

  return { list: listFormatted, paging };
};

export const read = (
  context,
  {
    _id,
    lpn,
    covers,
    endDate,
    startDate,
    uncoveredDuration,
    rightsDuration,
    terminalState,
    entryEvent,
    exitEvent,
    controlCtx,
    ups,
    ...rest
  },
) => {
  const { startDate: startDateRight, endDate: endDateRight } =
    covers.find(({ coverType }) => coverType === 'SHORT_TERM') || {};

  const coversFormatted = covers.sort(
    (a, b) => dayjs(a?.startDate) - dayjs(b?.startDate),
  );

  const cameraEntry = entryEvent?.resources?.list?.map(({ url }) => url);
  const cameraExit = exitEvent?.resources?.list?.map(({ url }) => url);

  const camera = {
    entry: cameraEntry && cameraEntry?.length > 0 ? cameraEntry[0] : undefined,
    exit: cameraExit && cameraExit?.length > 0 ? cameraExit[0] : undefined,
  };

  return {
    _id,
    sessionStartDate: startDate,
    sessionEndDate: endDate,
    forCover: {
      covers: coversFormatted.map(({ coverType, ...partialCover }) => ({
        type: coverType,
        ...partialCover,
      })),
      session: {
        startDate,
        endDate,
      },
    },
    lpn,
    forDes: {
      entry: {
        textItem: DateTime.fromISO(startDate).toFormat('dd/MM/yy HH:mm:ss'),
      },
      totalDuration: {
        textItem: formattedDuration(
          DateTime.fromISO(endDate)
            .diff(DateTime.fromISO(startDate))
            .toMillis(),
        ),
      },
      rightDuration: {
        textItem: rightsDuration ? 
            formattedDurationMinutes(rightsDuration)
            : '00:00',
      },
      defaultRight: {
        textItem: formattedDuration(uncoveredDuration * 60 * 1000),
        iconColor: uncoveredDuration,
      },
      horodator: terminalState,
      exit: {
        textItem: DateTime.fromISO(endDate).toFormat('dd/MM/yy HH:mm:ss'),
      },
    },
    camera,
    date: {
      start: startDate,
      end: endDate,
    },
    history: controlCtx?.ctxRelevantCovers || [],
    fps: {
      sessionId: _id,
      upsId: ups._id,
      terminalId: ups.config?.p1000?.pointOfSaleId,
      parkId: ups.config?.p1000?.legacyId,
      statementDatetime: endDate,
      confirm: {
        right: !!covers.find(({ coverType }) => coverType === 'SHORT_TERM'),
      },
      claimReasons: controlCtx?.claim?.map(({ claimReason }) => claimReason),
      address: ups?.address,
      maximumAmount: ups?.config?.fps?.maximumAmount,
      entry: {
        camera: camera.entry,
        date: startDate,
      },
      exit: {
        camera: camera.exit,
        date: endDate,
      },
      lpn,
    },
    ...rest,
  };
};

export const update = (context, data) => {
  return data;
};
