import React, { useMemo } from 'react';
import { getCSS } from '../../functions/css.function';
import { Placement1Axis } from '../../types/component.type';
import { Write } from '../write/write.component';
import './zone.component.scss';
class Static {
  static interpretAlign: Record<string, string> = Object.freeze({
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  });
}
export declare namespace ZoneType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      gap?: {
        y?: number;
        x?: number;
      };
      area?: string;
      zones?: string[][];
      rows?: string[];
      columns?: string[];
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
      horizontal?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
    };
  };
}

export const Zone = ({
  className = '',
  config: {
    zones = [],
    rows = [],
    columns = [],
    gap: { y: gapY = 2, x: gapX = 2 } = {},
    vertical,
    horizontal,
  } = {},
  children,
}: ZoneType.Props) => {
  return (
    <div
      className={`zone ${className}`}
      style={{
        gap: `calc(${gapY} * ${getCSS('--space')}) calc(${gapX} * ${getCSS(
          '--space',
        )})`,
        gridTemplateAreas: zones.reduce(
          (acc: string, array) => `${acc} "${array.join(' ')}"`,
          ``,
        ),
        justifyContent: vertical ? Static.interpretAlign[vertical] : undefined,
        alignItems: horizontal ? Static.interpretAlign[horizontal] : undefined,
        gridTemplateRows: rows ? rows.join(' ') : undefined,
        gridTemplateColumns: columns ? columns.join(' ') : undefined,
      }}
    >
      {children}
    </div>
  );
};

export declare namespace ZoneAreaType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      area?: string;
    };
  };
}

Zone.Area = ({
  className = '',
  config: { area } = {},
  children,
}: ZoneAreaType.Props) => {
  const error = useMemo(
    () => (
      <div className="zonearea__error">
        <Write
          data={{ item: 'Il faut un seul enfant pour utiliser ZoneArea' }}
          config={{
            mode: 'value-medium',
          }}
        ></Write>
      </div>
    ),
    [],
  );

  if (!children) return error;

  const childrenClear = useMemo(
    (): JSX.Element[] =>
      Array.isArray(children) ? children.filter(Boolean) : [children],
    [children],
  );

  if (childrenClear.length !== 1) return error;

  const childrenOnly = useMemo(
    () => React.Children.only(childrenClear[0]),
    [childrenClear],
  );

  if (!childrenOnly) return error;

  return (
    <div className={`zonearea ${className}`} style={{ gridArea: area }}>
      {childrenOnly}
    </div>
  );
};
