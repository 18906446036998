import { assign, createMachine, interpret } from 'xstate';

const initialContext = {};

const machine = createMachine(
  {
    id: 'events',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
          RESET: { actions: 'reset' },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
            actions: assign({
              error: () => undefined,
            }),
          },

          RESET: { actions: 'reset' },
        },
      },
    },
    on: {
      LOGOUT: { actions: assign(initialContext) },
    },
  },

  {
    actions: {
      reset: assign({
        read: () => undefined,
        search: () => undefined,
      }),
    },
    services: {},
  },
);

export const occupancyService = interpret(machine).start();
