import { gql } from '@apollo/client';

export const sessions = gql`
  query sessions(
    $minUncoveredDuration: Int
    $upsId: String!
    $page: Int
    $limit: Int
    $order: String
    $ignoreTerminalState: Boolean
  ) {
    sessions(
      minUncoveredDuration: $minUncoveredDuration
      upsId: $upsId
      page: $page
      limit: $limit
      order: $order
      ignoreTerminalState: $ignoreTerminalState
    ) {
      list {
        _id
        endDate
        uncoveredDuration
        previousClaimsForUser
      }
      paging {
        current
        count
      }
    }
  }
`;
