import Color from 'color';
import { observer } from 'mobx-react-lite';
import React, { useId, useMemo } from 'react';
import { getCSS } from '../../functions/css.function';
import { useRootStore } from '../../hook/useRootStore.hook';
import { Color as ColorGlobal, Size } from '../../types/component.type';
import { Icon, IconType } from '../icon/icon.component';
import './badge.component.scss';
export declare namespace BadgeType {
  type Props = {
    className?: string;
    config?: {
      size?: Extract<Size, 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'>;
      color?: BadgeType.Config.Color;
      mode?: 'fill' | 'degraded' | 'stroke' | 'empty';
      text?: BadgeType.Config.Text;
      icon?: IconType.Config.Type;
      radius?: boolean;
    };
  };

  namespace Config {
    type Color = Extract<
      ColorGlobal,
      'error' | 'warn' | 'success' | 'disabled' | 'info' | 'yell'
    >;

    type Text = string;
  }
}

export const Badge = observer(
  ({
    config: {
      size = 'medium',
      color = 'disabled',
      mode = 'degraded',
      radius = true,
      text,
      icon,
    } = {},
    className = '',
  }: BadgeType.Props) => {
    const { ThemeStore } = useRootStore();

    const badgeStyleMapper = useMemo(() => {
      return color && ThemeStore.load && getCSS(`--color-${color}`)
        ? {
            degraded: {
              backgroundColor: new Color(getCSS(`--color-${color}`))
                .lightness(80)
                .toString(),
              color: new Color(getCSS(`--color-${color}`))
                .darken(0.7)
                .toString(),
            },
            fill: {
              backgroundColor: new Color(getCSS(`--color-${color}`)).toString(),
              color: getCSS('--color-white'),
            },
            stroke: {
              border: `1px solid ${getCSS(`--color-${color}`)}`,
              color: getCSS(`--color-${color}`),
            },
            empty: {
              color: getCSS(`--color-${color}`),
            },
          }
        : undefined;
    }, [color, ThemeStore.load, ThemeStore.theme]);

    return text ? (
      <div
        style={badgeStyleMapper?.[mode]}
        className={`
          badge
          badge--size--${size} 
          ${radius ? 'badge--radius' : ''}
          ${className ?? ''}
      `}
      >
        {icon && (
          <div className="badge__icon">
            {<Icon config={{ type: icon }}></Icon>}
          </div>
        )}
        <span className="badge__text">{text}</span>
      </div>
    ) : (
      <div
        style={
          badgeStyleMapper
            ? {
                backgroundColor: badgeStyleMapper['fill'].backgroundColor,
              }
            : undefined
        }
        className={`
          witness 
          witness--size--${size} 
        ${className}
      `}
      ></div>
    );
  },
);
