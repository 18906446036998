export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
  >
    <g id="Calque_1"></g>
    <g id="Calque_2">
      <g>
        <path
          className="yang"
          d="M199,110.5c2.2,0,4.4-0.7,6.2-1.9c1.8-1.3,3.3-3,4.1-5.1c0.8-2.1,1.1-4.4,0.6-6.6c-0.4-2.2-1.5-4.3-3.1-5.9
			c-1.6-1.6-3.6-2.7-5.7-3.1c-2.2-0.4-4.4-0.2-6.5,0.7c-2,0.9-3.8,2.3-5,4.2c-1.2,1.9-1.9,4.1-1.9,6.4c0,3,1.2,5.9,3.3,8.1
			C193.2,109.3,196,110.5,199,110.5z"
        />
        <path
          className="yang"
          d="M182,133.4H216c0.7,0,1.3-0.3,1.8-0.8c0.5-0.5,0.8-1.2,0.8-1.9c0-2.1-0.4-4.2-1.2-6.1c-0.8-1.9-1.9-3.7-3.4-5.2
			c-1.4-1.5-3.2-2.7-5-3.5c-1.9-0.8-3.9-1.2-6-1.2l-8,0c-2,0-4.1,0.4-6,1.2c-1.9,0.8-3.6,2-5,3.5c-1.4,1.5-2.6,3.2-3.4,5.2
			c-0.8,1.9-1.2,4-1.2,6.1c0,0.7,0.3,1.4,0.8,1.9C180.7,133.1,181.3,133.4,182,133.4z"
        />
        <path
          className="yang"
          d="M201.3,156c-0.6-0.6-1.4-1-2.3-1s-1.7,0.3-2.3,1c-0.6,0.6-0.9,1.5-0.9,2.3v38.3l-7.4-7.6c-0.3-0.3-0.7-0.6-1.1-0.7
			c-0.4-0.2-0.8-0.3-1.2-0.3c-0.4,0-0.9,0.1-1.2,0.2c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.5,0.7-0.7,1.1c-0.2,0.4-0.2,0.8-0.2,1.3
			c0,0.4,0.1,0.9,0.3,1.3c0.2,0.4,0.4,0.8,0.7,1.1l12.9,13.2v0c0.3,0.3,0.7,0.6,1,0.7c0.4,0.2,0.8,0.3,1.2,0.3
			c0.4,0,0.8-0.1,1.2-0.3c0.4-0.2,0.7-0.4,1-0.7l12.9-13.2c0.6-0.6,0.9-1.5,0.9-2.3c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.4-1-2.3-1
			c-0.9,0-1.7,0.3-2.3,1l-7.4,7.6v-38.3C202.2,157.4,201.9,156.6,201.3,156z"
        />
        <path
          className="yang"
          d="M246.8,226.9c-1.9-4-6-6.8-10.8-6.8h-81.9c-6.6,0-12,5.4-12,12.1v58.8c0,4.7,2.7,8.8,6.6,10.8c1.5,5.4,6.5,9.4,12.4,9.4
			H243c7.1,0,12.9-5.8,12.9-13v-58.8C255.9,233.4,252,228.5,246.8,226.9z M209.5,243.8h23.5c1.6,0,2.9,1.3,2.9,3c0,1.6-1.3,3-2.9,3
			h-23.5c-1.6,0-2.9-1.3-2.9-3C206.6,245.1,207.9,243.8,209.5,243.8z M206.6,258.5c0-1.6,1.3-3,2.9-3h23.5c1.6,0,2.9,1.3,2.9,3v0.1
			c0,1.6-1.3,3-2.9,3h-23.5C207.9,261.5,206.6,260.2,206.6,258.5L206.6,258.5z M206.6,270.4c0-1.6,1.3-3,2.9-3h23.5
			c1.6,0,2.9,1.3,2.9,3v0.1c0,1.6-1.3,3-2.9,3h-23.5C207.9,273.4,206.6,272.1,206.6,270.4L206.6,270.4z M199.5,265.2
			c0,0.4-0.2,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.1,0.5h-16.3h-0.1c1-0.9,1.9-2.1,2.4-3.3c0.6-1.3,0.9-2.7,0.9-4.1
			c0-0.6-0.1-1.1-0.1-1.7c1.1-0.5,2.2-0.7,3.4-0.7h3.3c2.2,0,4.2,0.9,5.8,2.4C198.6,260.9,199.5,263,199.5,265.2z M189.7,279.6
			c0,0.5-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.4,0.6h-25.3c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.3-0.7-0.4
			c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.2-0.8c0-2.7,1.1-5.3,3-7.3c1.9-1.9,4.5-3,7.2-3h9c2.7,0,5.3,1.1,7.2,3
			C188.6,274.2,189.7,276.8,189.7,279.6z M185.4,244c1.1-1.2,2.7-1.8,4.3-1.8c1.2,0,2.4,0.4,3.4,1c1,0.7,1.8,1.6,2.3,2.8
			c0.5,1.1,0.6,2.4,0.3,3.6c-0.2,1.2-0.8,2.3-1.7,3.2s-1.9,1.4-3.1,1.7c-1.2,0.2-2.4,0.1-3.5-0.4c-1.1-0.5-2.1-1.3-2.7-2.3
			c-0.7-1-1-2.2-1-3.4C183.6,246.7,184.2,245.2,185.4,244z M167.7,259.4c0-1.5,0.4-2.9,1.2-4.1c0.8-1.2,2-2.2,3.3-2.7
			c1.3-0.6,2.8-0.7,4.2-0.4c1.4,0.3,2.7,1,3.8,2c1,1,1.7,2.3,2,3.8c0.3,1.4,0.1,2.9-0.4,4.3c-0.6,1.4-1.5,2.5-2.7,3.3
			c-1.2,0.8-2.6,1.2-4.1,1.2c-1.9,0-3.8-0.8-5.2-2.2C168.4,263.3,167.7,261.4,167.7,259.4L167.7,259.4z M157.2,244
			c1.1-1.2,2.7-1.8,4.3-1.8c1.2,0,2.4,0.4,3.4,1c1,0.7,1.8,1.6,2.3,2.8c0.5,1.1,0.6,2.4,0.3,3.6c-0.2,1.2-0.8,2.3-1.7,3.2
			c-0.9,0.9-1.9,1.4-3.1,1.7c-1.2,0.2-2.4,0.1-3.5-0.4c-1.1-0.5-2.1-1.3-2.7-2.3c-0.7-1-1-2.2-1-3.4
			C155.4,246.7,156.1,245.2,157.2,244z M152.9,259.4c1.5-1.5,3.6-2.4,5.8-2.4h3.3c1.2,0,2.3,0.2,3.4,0.7c-0.1,0.6-0.1,1.1-0.1,1.7
			c0,1.4,0.3,2.8,0.9,4.1c0.6,1.3,1.4,2.4,2.4,3.3h-16.4c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1
			C150.6,263,151.4,260.9,152.9,259.4z M254,298c0,6.2-5,11.1-11.1,11.1h-81.9c-4.5,0-8.4-2.8-10.1-6.7c1,0.3,2,0.4,3.1,0.4H236
			c6.6,0,12-5.4,12-12.1v-58.8c0-1-0.1-2-0.4-2.9c3.8,1.8,6.5,5.6,6.5,10.1V298z"
        />
      </g>
    </g>
  </svg>
);
