import { dispatch } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import {
  LangContext,
  translationService,
} from '../../machines/translation.machine';
import { Button } from '../button/button.component';
import './debug.component.scss';

export declare namespace DebugType {
  type Props = {
    config: {
      active: boolean;
    };
  };
}

export const Debug = ({ config: { active } }: DebugType.Props) => {
  const orphanWords = useSelector(
    translationService,
    ({ context }: { context: LangContext }) => context.orphanWords,
  );

  const items = useSelector(
    translationService,
    ({ context }: { context: LangContext }) => context.items,
  );

  const lang = useSelector(
    translationService,
    ({ context }: { context: LangContext }) => context.lang,
  );

  const eventLang = () => {
    const delayStep = 200;
    if (items) {
      const saveCurrentLang = lang;

      for (let i = 0; i < items.length; i++) {
        setTimeout(() => {
          dispatch('REGISTER_LANG', { lang: items[i] });
        }, i * delayStep);
      }

      setTimeout(() => {
        dispatch('REGISTER_LANG', { lang: saveCurrentLang });
      }, (items.length + 1) * delayStep);

      setTimeout(() => {
        console.log({ orphanWords });
      }, (items.length + 2) * delayStep);
    }
  };

  if (!active) {
    return <></>;
  } else {
    return (
      <div className={`debug`}>
        <div className="debug__contain">
          <div className="debug__contain__item">
            <Button
              handleEvent={{ click: eventLang }}
              config={{ text: 'List des traductions manquantes' }}
            />
          </div>
        </div>
      </div>
    );
  }
};
