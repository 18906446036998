import { Checkbox as CheckboxAnt } from 'antd';
import type { CheckboxChangeEvent as CheckboxChangeEventAnt } from 'antd/es/checkbox';
import { Size } from '../../types/component.type';
import { FormItemType } from '../form/form.component';
import './checkbox.component.scss';

class Static {
  static toBoolean = (value: string | boolean): boolean => {
    return typeof value === 'boolean'
      ? value
      : typeof value === 'string'
      ? value === 'true'
      : !!value;
  };
}

export declare namespace CheckboxType {
  type Props = {
    className?: string;
    handleEvent?: {
      check?: (value: CheckboxType.Data.Value) => void;
    };
    data?: {
      value?: CheckboxType.Data.Value;
      defaultValue?: CheckboxType.Data.Value;
    };
    config?: {
      size?: Extract<Size, 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'>;
      disabled?: boolean;
    };
  };

  namespace Data {
    type Value = boolean;
  }
}

export const Checkbox = ({
  handleEvent: { check } = {},
  className = '',
  config: { size = 'medium', disabled = false } = {},
  data: { value = false, defaultValue = false } = {},
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<CheckboxType.Props>) => {
  return (
    <div
      className={`
      formItemTargetWidth--${size}
      checkbox
      checkbox--size--${size}
      ${className}
    `}
    >
      <CheckboxAnt
        disabled={disabled}
        defaultChecked={defaultValue}
        checked={Static.toBoolean(valueFormItem || value)}
        onChange={({ target: { checked } }: CheckboxChangeEventAnt) => {
          check?.(checked);
          onChangeFormItem?.(checked);
        }}
        {...formItemParams}
      />
    </div>
  );
};
