import {
  Color as ColorGlobal,
  Form,
  FormItemType,
  Size,
  Size as SizeGlobal,
  Write,
} from '@gimlite/watermelon';
import { Switch as SwitchAnt, SwitchProps as SwitchPropsAnt } from 'antd/lib';
import { useState } from 'react';
import './switch.component.scss';

class Static {
  static sizeMapper: Record<SwitchType.Config.Size, SwitchPropsAnt['size']> =
    Object.freeze({
      small: 'small',
      large: 'default',
    });
}

export declare namespace SwitchType {
  type Props = {
    className?: string;
    handleEvent?: { change?: (value: boolean) => void };
    data?: {
      defaultValue?: SwitchType.Data.DefaultValue;
    };
    config?: {
      text?: SwitchType.Config.Text;
      size?: SwitchType.Config.Size;
      color?: SwitchType.Config.Color;
      width?: Extract<Size, 'initial' | 'full'>;
    };
  };

  namespace Data {
    type DefaultValue = boolean;
  }

  namespace Config {
    type Text = {
      active: string;
      inactive: string;
    };
    type Size = Extract<SizeGlobal, 'small' | 'large'>;

    type Color = {
      active: Extract<ColorGlobal, 'success' | 'warn'>;
      inactive: Extract<ColorGlobal, 'warn' | 'error'>;
    };
  }
}

export const Switch = ({
  config: { color, text, size = 'large', width = 'initial' } = {},
  data: { defaultValue } = {},
  handleEvent: { change } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<SwitchType.Props>) => {
  const [trackActive, setTrackActive] = useState<boolean>(
    valueFormItem || defaultValue,
  );

  const colorActive = color?.active || 'success';
  const colorInactive = color?.inactive || 'error';

  return (
    <Form.Context.Consumer>
      {(form) => (
        <div
          className={`
            formItemTargetWidth--${width || form.width || 'medium'}
            switch
            switch--color--active--${colorActive} 
            switch--width--${width || form.width || 'initial'}
            switch--color--inactive--${colorInactive} 
            ${className}
          `}
        >
          {text && (
            <Write
              className="switch__text"
              config={{
                mode: size === 'small' ? 'key-small-regular' : 'input',
                color:
                  valueFormItem || trackActive ? colorActive : colorInactive,
              }}
              data={{
                item:
                  valueFormItem || trackActive ? text.active : text.inactive,
              }}
            />
          )}
          <SwitchAnt
            {...formItemParams}
            value={valueFormItem || trackActive}
            size={Static.sizeMapper[size]}
            onChange={(value) => {
              setTrackActive(value);
              change?.(value);
              onChangeFormItem?.(value);
            }}
            defaultChecked={trackActive}
          ></SwitchAnt>
        </div>
      )}
    </Form.Context.Consumer>
  );
};
