import { AutoComplete as AutoCompleteAnt } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { removeAccent } from '../../functions/string.function';
import type { Size } from '../../types/component.type';
import { Form, FormItemType } from '../form/form.component';
import { Icon } from '../icon/icon.component';
import './autocomplete.component.scss';

export declare namespace AutoCompleteType {
  type Props = {
    className?: string;
    handleEvent?: {
      input?: (value: AutoCompleteType.Data.Item['value']) => void;
      select?: (value: AutoCompleteType.Data.Item | null) => void;
      search?: (value: AutoCompleteType.Data.Item | null) => void;
      clear?: () => void;
    };
    data: {
      items: AutoCompleteType.Data.Item[];
      value?: AutoCompleteType.Data.Item | null;
    };
    config?: {
      width?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      minWidth?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      height?: Extract<
        Size,
        'initial' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'full'
      >;
      placeholder?: string;
      disabled?: boolean;
      border?: boolean;
      clear?: boolean;
      search?: AutoCompleteType.Config.Search;
      design?: 'autocomplete' | 'search';
    };
  };

  namespace Data {
    type Item = {
      label: string;
      value: string;
    };
  }

  namespace Config {
    type Search = {
      caseSensitive?: boolean;
      accentSensitive?: boolean;
      pattern?: 'start' | 'everywhere';
    };
  }
}

export const AutoComplete = ({
  data: { value: valueInline, items = [] },
  handleEvent: { input, select, clear, search } = {},
  config: {
    width,
    minWidth,
    height,
    placeholder = '',
    clear: clearAllow = true,
    disabled = false,
    border = true,
    search: searchConfig = {},
    design = 'autocomplete',
  } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<AutoCompleteType.Props>) => {
  const value = valueInline ?? valueFormItem;
  const [currentValue, setCurrentValue] = useState<string>('');

  const formattedData = useCallback(
    (str: string) => {
      let valueFormatted = str;

      if (!searchConfig?.caseSensitive)
        valueFormatted = `${valueFormatted}`.toLowerCase();

      if (!searchConfig?.accentSensitive)
        valueFormatted = removeAccent(valueFormatted);

      return valueFormatted;
    },
    [searchConfig, items],
  );

  const formattedValue = useCallback(
    (item: AutoCompleteType.Data.Item | string | null): string | undefined => {
      if (!item) return undefined;
      if (typeof item === 'string') return item;
      return item.label;
    },
    [],
  );

  const currentOptions = useMemo(() => {
    return items
      .reduce(
        (
          acc: AutoCompleteType.Data.Item[],
          { label, value }: AutoCompleteType.Data.Item,
        ) => {
          const optionFormatted = formattedData(label);

          const patternConfig = searchConfig?.pattern || 'everywhere';

          if (design === 'autocomplete') {
            switch (patternConfig) {
              case 'start':
                return optionFormatted.startsWith(currentValue)
                  ? [...acc, { label, value }]
                  : acc;
              case 'everywhere':
                return optionFormatted.search(currentValue) !== -1
                  ? [...acc, { label, value }]
                  : acc;
            }
          } else {
            return [...acc, { label, value }];
          }
        },
        [],
      )
      .map(({ label }) => ({
        label,
        value: label,
      }));
  }, [currentValue, items, searchConfig]);

  const autocomplete = (
    <AutoCompleteAnt
      className="autocomplete-node"
      options={currentOptions || []}
      placeholder={placeholder}
      variant={border ? 'outlined' : 'borderless'}
      disabled={disabled}
      allowClear={clearAllow}
      value={formattedValue(value)}
      onChange={(value: string) => {
        input?.(value);
        onChangeFormItem?.(value);
      }}
      onClear={() => {
        clear?.();
      }}
      onSearch={(value) => {
        setCurrentValue(() => formattedData(value));

        const tryFindItem =
          items.find((element) => element.value === value) || null;
        search?.(tryFindItem);
      }}
      onSelect={(currentLabel) => {
        select?.(items.find(({ label }) => label === currentLabel) || null);
      }}
      {...formItemParams}
    ></AutoCompleteAnt>
  );

  if (design === 'autocomplete') {
    return (
      <Form.Context.Consumer>
        {(form) => (
          <div
            className={`
              autocomplete
              formItemTargetWidth--${form.width || width || 'medium'}
              autocomplete 
              autocomplete--width--${form.width || width || 'medium'}
              ${minWidth ? `autocomplete--minWidth--${minWidth}` : ''}
              autocomplete--height--${form.height || height || 'medium'}
              ${className}`}
          >
            <AutoCompleteAnt
              options={currentOptions || []}
              placeholder={placeholder}
              bordered={border}
              disabled={disabled}
              allowClear={clearAllow}
              value={formattedValue(value)}
              onChange={(value: string) => {
                input?.(value);
                onChangeFormItem?.(value);
              }}
              onClear={() => {
                clear?.();
              }}
              onSearch={(value) => {
                setCurrentValue(() => formattedData(value));

                const tryFindItem =
                  items.find((element) => element.value === value) || null;
                search?.(tryFindItem);
              }}
              onSelect={(currentLabel) => {
                select?.(
                  items.find(({ label }) => label === currentLabel) || null,
                );
              }}
              {...formItemParams}
            ></AutoCompleteAnt>
          </div>
        )}
      </Form.Context.Consumer>
    );
  } else {
    return (
      <div
        className={`
          search
          formItemTargetWidth--${width}
          search--width--${width}
          search--height--${height}
          ${className}
        `}
      >
        <Icon
          config={{
            type: 'faMagnifyingGlassSolid',
            size: 'small',
            color: 'disabled',
          }}
          className="search__icon"
        />
        <div className="search__input">{autocomplete}</div>
      </div>
    );
  }
};
