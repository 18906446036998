import React from 'react';
import type { Placement1Axis, Size } from '../../types/component.type';
import './col.component.scss';

class Static {
  static interpretAlign: Record<string, string> = Object.freeze({
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  });
}
export declare namespace ColType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      flex?: number;
      vertical?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
      horizontal?: Extract<Placement1Axis, 'start' | 'center' | 'end'>;
      scrollY?: boolean;
      height?: Extract<Size, 'initial' | 'full'>;
      width?: Extract<Size, 'initial' | 'full'>;
    };
  };
}

export const Col = ({
  children,
  config: {
    flex = 1,
    vertical = 'start',
    horizontal = 'start',
    scrollY = false,
    height = 'initial',
    width = 'initial',
  } = {},
  className = '',
}: ColType.Props) => {
  return (
    <div
      style={{
        flex: flex,
        justifyContent: Static.interpretAlign[vertical],
        alignItems: Static.interpretAlign[horizontal],
      }}
      className={`
        col 
        ${scrollY ? 'col--scrollY' : ''} 
        col--height--${height} 
        col--width--${width} 
        ${className}`}
    >
      {children}
    </div>
  );
};
