import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import { assign, createMachine, interpret } from 'xstate';
import { createFpsGql } from './gql/createFps.gql';

const initialContext = {
  fps: undefined,
  wizard: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'createFps',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
            actions: assign({
              fps: (_, { fps }) => fps,
              result: () => undefined,
            }),
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          UPDATE_FPS: {
            actions: assign({
              wizard: (_, { payload }) => payload,
            }),
          },
          CREATE_FPS: {
            target: 'createFps',
          },
        },
      },
      createFps: {
        invoke: {
          id: 'createFps',
          src: 'createFps',
          onDone: {
            target: 'idle',
            actions: assign({
              result: true,
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              result: false,
            }),
          },
        },
      },

      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      createFps: async (context, { payload }) =>
        requestGQL({
          params: {
            input: { ...payload },
          },
          gql: createFpsGql,
          render: (res) => res,
        }),
    },
  },
);

export const createFpsService = interpret(machine).start();
