export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 368 327.02">
    <g>
      <path
        className="color-preserve"
        d="M368,163.51h0a31,31,0,0,1-31,31H230l-46-93,49.65-86A31,31,0,0,1,276,4.16h0a31,31,0,0,1,11.35,42.35l-49.65,86H337A31,31,0,0,1,368,163.51Z"
        style={{
          fill: '#1a73e8',
        }}
      />
      <path
        className="color-preserve"
        d="M276,322.86h0a31,31,0,0,1-42.35-11.34l-49.65-86-49.65,86A31,31,0,0,1,92,322.86h0a31,31,0,0,1-11.35-42.35l49.65-86,53.7-2,53.7,2,49.65,86A31,31,0,0,1,276,322.86Z"
        style={{
          fill: '#ea4335',
        }}
      />
      <path
        className="color-preserve"
        d="M184,101.5l-14,38-39.7-7-49.65-86A31,31,0,0,1,92,4.16h0A31,31,0,0,1,134.35,15.5Z"
        style={{
          fill: '#fbbc04',
        }}
      />
      <path
        className="color-preserve"
        d="M176,132.51l-36,62H31a31,31,0,0,1-31-31H0a31,31,0,0,1,31-31Z"
        style={{
          fill: '#34a853',
        }}
      />
      <polygon
        className="color-preserve"
        points="237.7 194.51 130.3 194.51 184 101.5 237.7 194.51"
        style={{
          fill: '#185db7',
        }}
      />
    </g>
  </svg>
);
