import { useTranslation } from '../../hook/useTranslation.hook';
import { Icon } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './link.component.scss';

class Static {
  public static registryLink = {
    GOOGLE_AUTHENTIFICATOR: {
      link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=fr&gl=US',
      name: 'watermelon-googleAuthentificator-title',
      store: 'watermelon-googleAuthentificator-description',
      icon: 'partnerGoogleAuthentificator',
    },
  } as const;
}

export declare namespace LinkType {
  type Props = {
    className?: string;
    config: {
      type: keyof typeof Static.registryLink;
    };
  };
}

export const Link = ({ config: { type }, className = '' }: LinkType.Props) => {
  const { t } = useTranslation();
  return (
    <a
      href={Static.registryLink[type].link}
      target="__blank"
      className={`
        link
        ${className}
      `}
    >
      <Icon
        config={{ type: Static.registryLink[type].icon, size: 'large' }}
      ></Icon>

      <div className="link__text">
        <Write
          data={{ item: t(Static.registryLink[type].name) }}
          config={{ mode: 'namise' }}
        ></Write>
        <Write
          data={{ item: t(Static.registryLink[type].store) }}
          config={{ mode: 'namise-description' }}
        ></Write>
      </div>
    </a>
  );
};
