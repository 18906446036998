export default () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-20 50 270 120"
    style={{}}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0{fill:#003A5D;}
        .st1{fill:#00B2E3;}`}
    </style>
    <g>
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M12.5,144.5h-11v30.3h1.8v-11.7h9.2c7.6,0,10.4-3.8,10.4-9.3C23,148.3,20.1,144.5,12.5,144.5z M12.5,161.4H3.3v-15.3h9.2c6.4,0,8.6,3.1,8.6,7.6C21.2,158.4,19,161.4,12.5,161.4z"
            />
            <path
              className="st0"
              d="M27.4,152.3v22.4h1.7v-21.2c1.2-0.4,2.5-0.9,4.9-0.9c1.1,0,2.6,0.1,3.6,0.5l0.5-1.5c-1-0.3-2.8-0.6-4.2-0.6C31.1,151,28.3,151.9,27.4,152.3z"
            />
            <path
              className="st0"
              d="M50.5,150.9c-5.6,0-11,3.1-11,12c0,9,5.5,12.3,12.1,12.3c3.1,0,6.1-0.7,8.3-1.8l-0.5-1.5c-1.7,1-4.8,1.7-7.7,1.7c-5.6,0-10.5-2.7-10.5-9.7h19.4V162C60.7,154.3,56.3,150.9,50.5,150.9z M41.2,162.3c0-7.3,4.7-9.7,9.3-9.7c4.7,0,8.4,2.5,8.4,9.7H41.2z"
            />
            <path
              className="st0"
              d="M66.9,157.2c0-3.2,3.1-4.6,6.7-4.6c2.4,0,5,0.6,7,1.9l0.5-1.5c-2.1-1.2-4.9-2-7.6-2c-4.3,0-8.4,1.8-8.4,6.2c0,8.6,15,4.2,15,11.3c0,3.3-3.4,4.7-7.4,4.7c-2.6,0-5.4-0.5-7.7-1.5l-0.5,1.5c2.3,1,5.3,1.7,8.1,1.7c4.8,0,9.2-1.8,9.2-6.4C81.9,159.8,66.9,164.3,66.9,157.2z"
            />
            <path
              className="st0"
              d="M96.4,173.4c-2.8,0-4.8-1.5-4.8-5.7V153h9.3v-1.6h-9.3v-6.2h-1.3l-0.4,6.2l-5.4,0.3v1.3H90v14.7c0,5.4,3,7.3,6.5,7.3c2,0,3.5-0.6,4.9-1.5l-0.5-1.5C99.8,172.7,98.3,173.4,96.4,173.4z"
            />
            <path
              className="st0"
              d="M115.1,150.9c-5.6,0-12.1,3.2-12.1,12.1c0,8.9,6.5,12.1,12.1,12.1c5.6,0,12.1-3.2,12.1-12.1C127.1,154.2,120.6,150.9,115.1,150.9z M115.1,173.6c-4.7,0-10.3-2.6-10.3-10.5s5.6-10.5,10.3-10.5c4.7,0,10.3,2.7,10.3,10.5C125.4,171,119.8,173.6,115.1,173.6z"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M144.8,158.1c-4.5-1.4-8.4-2.7-8.4-6.6c0-3.8,4.1-5.2,7.9-5.2c3,0,6.2,0.8,8.2,2.1l0.7,0.5l1-3l-0.4-0.3c-2.4-1.4-6-2.3-9.5-2.3c-5.3,0-10.9,2.2-10.9,8.2c0,6.1,5.6,7.8,10.5,9.4c4.3,1.4,8.1,2.5,8.1,6.1c0,4.2-4.6,5.7-9,5.7c-3.2,0-6.4-0.8-8.6-2.2l-0.7-0.5l-0.9,3l0.4,0.3c2.4,1.5,6.1,2.4,9.8,2.4c5.8,0,12.1-2.3,12.1-8.6C155,161.4,149.6,159.7,144.8,158.1z"
            />
            <path
              className="st1"
              d="M176.6,171.8c-1.5,0.8-3.5,1.2-5.4,1.2c-5,0-10.4-2.7-10.4-10.2c0-7.1,5-9.6,9.6-9.6c2.7,0,4.8,0.9,5.8,1.5l0.7,0.4l0.9-2.7l-0.5-0.3c-1.4-0.8-3.8-1.8-6.9-1.8c-5.8,0-12.6,3.3-12.6,12.5c0,7.8,5.4,13,13.4,13c3.1,0,5.2-0.8,6.5-1.4l0.5-0.2l-0.9-2.7L176.6,171.8z"
            />
            <path
              className="st1"
              d="M189.2,150.3c-2.4,0-5,0.4-8,1.3l-0.5,0.2l0.4,3l0.7-0.3c1.1-0.4,3.9-1.2,7.4-1.2c4.8,0,6.7,1.6,6.7,5.7v1.7h-6.4c-2.6,0-10.6,0-10.6,7.6s8,7.6,10.6,7.6c3.8,0,6.9-0.7,8.9-1.3l0.5-0.1v-15.5C198.9,153.2,195.6,150.3,189.2,150.3z M196,163.4v8.8c-1.8,0.5-4.1,0.7-6.4,0.7c-5.4,0-7.6-1.4-7.6-4.8c0-3.4,2.2-4.8,7.6-4.8H196z"
            />
            <path
              className="st1"
              d="M213.7,150.3c-5.5,0-8.8,1.5-9.9,2.1l-0.3,0.2v22.8h3v-20.8c1.8-1,4.1-1.4,7.5-1.4c3.9,0,8.1,0.6,8.1,7.5v14.7h3v-14.7C224.8,151.4,218.5,150.3,213.7,150.3z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          className="st1"
          d="M135.5,120.7h-16.6v-8.4h16.6c1.8,0,3.2-1.5,3.2-3.2V97.4h8.4V109C147.1,115.5,141.9,120.7,135.5,120.7z"
        />
        <path
          className="st1"
          d="M87.7,76.1h-8.4V64.4c0-6.4,5.2-11.6,11.6-11.6h16.6v8.4H90.9c-1.8,0-3.2,1.5-3.2,3.2V76.1z"
        />
        <path
          className="st0"
          d="M135.5,90.9c6.4,0,11.6-5.2,11.6-11.6V64.4c0-6.4-5.2-11.6-11.6-11.6h-16.6v8.4h16.6c1.8,0,3.2,1.5,3.2,3.2v14.9c0,1.8-1.5,3.2-3.2,3.2H90.9c-6.4,0-11.6,5.2-11.6,11.6l0,14.9c0,6.4,5.2,11.6,11.6,11.6h16.6V94.2c0-1.8,1.5-3.2,3.2-3.2H135.5z M99.1,94.2v18.1h-8.2c-1.8,0-3.2-1.5-3.2-3.2v-3.2h0V94.2c0-1.8,1.5-3.2,3.2-3.2h8.7C99.3,92,99.1,93,99.1,94.2z"
        />
      </g>
    </g>
  </svg>
);
