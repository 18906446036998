export default () => (
  <svg viewBox="0 0 645.5 158">
    <g>
      <g clip-path="url(#a)">
        <path
          d="M335.92,111c0,4.78-1.46,6.27-5.88,6.27h-23.8V38.89H330c4.42,0,5.88,1.46,5.88,6.22Zm9.92-85.58-63.67-.3a7,7,0,0,0-6.92,7v91.71a6.94,6.94,0,0,0,6.92,6.93l63.67.05c13.63,0,21.07-6.78,21.07-21.38V46.77c0-14.67-7.44-21.38-21.07-21.38"
          className="white"
        />
      </g>
      <g clip-path="url(#b)">
        <path
          d="M37.89,38.43c-4.45,0-6,1.5-6,6.29V55c0,4.81,1.51,6.31,6,6.31H68.65c13.71,0,21.15,6.79,21.15,21.48v26.46c0,14.71-7.44,21.48-21.15,21.48L8.8,130.63a6.92,6.92,0,0,1,0-13.83l45,.35c4.45,0,5.94-1.5,5.94-6.29V96.94c0-4.76-1.49-6.25-5.94-6.25H23C9.26,90.69,1.84,83.9,1.84,69.21V46.39C1.84,31.66,9.26,24.87,23,24.87H79.77a6.78,6.78,0,1,1,0,13.56Z"
          className="white"
        />
      </g>
      <g clip-path="url(#c)">
        <path
          d="M471.38,38.89a6.78,6.78,0,1,1,0-13.56h76.9a6.78,6.78,0,0,1,0,13.56H525.34v84.93a6.9,6.9,0,0,1-6.9,6.92H501.33a6.93,6.93,0,0,1-6.92-6.92V38.89Z"
          className="white"
        />
      </g>
      <g clip-path="url(#d)">
        <path
          d="M228.35,130.69a6.94,6.94,0,0,1-6.95-6.93V32a6.94,6.94,0,0,1,6.95-6.94h17a7,7,0,0,1,7,6.94v91.77a7,7,0,0,1-7,6.93Z"
          className="white"
        />
      </g>
      <g clip-path="url(#e)">
        <path
          d="M406.68,87.27l9.21-20.67a3.1,3.1,0,0,1,2.85-1.88,3,3,0,0,1,2.8,1.82l9.36,20.73ZM479,120.85,437.29,29.17a7,7,0,0,0-6.22-3.84h-6.65a7,7,0,0,0-6.29,4l-41.61,91.53a7.11,7.11,0,0,0-.73,3.11,6.93,6.93,0,0,0,13.33,2.7l11.33-25.93h36.49l10.22,25.76a7,7,0,0,0,6.43,4.2h19.09a7,7,0,0,0,7-6.93,6.44,6.44,0,0,0-.65-2.91"
          className="white"
        />
      </g>
      <g clip-path="url(#f)">
        <path
          d="M570.69,87.27,579.9,66.6a3.11,3.11,0,0,1,2.87-1.88,3.16,3.16,0,0,1,2.84,1.82l9.28,20.73ZM643,120.85,601.3,29.17a7,7,0,0,0-6.24-3.84h-6.65a6.89,6.89,0,0,0-6.21,4l-41.69,91.53a7.1,7.1,0,0,0-.71,3.11,6.95,6.95,0,0,0,13.35,2.7l11.31-25.93H601l10.28,25.76a6.85,6.85,0,0,0,6.33,4.2h19.19a6.91,6.91,0,0,0,6.91-6.93,6.43,6.43,0,0,0-.64-2.91"
          className="white"
        />
      </g>
      <g clip-path="url(#g)">
        <path
          d="M142.86,32a6.94,6.94,0,0,0-7-6.94h-17A6.94,6.94,0,0,0,111.93,32v91.77a6.94,6.94,0,0,0,6.94,6.93h17a7,7,0,0,0,7-6.93l0-30.56a3.19,3.19,0,0,1,5.73-2l27.06,37.41a4.88,4.88,0,0,0,4,2h23.43a4.92,4.92,0,0,0,4.93-4.87,4.87,4.87,0,0,0-1-3L171.07,73a5,5,0,0,1,.4-6.26L201,33.14A4.82,4.82,0,0,0,202.21,30a4.93,4.93,0,0,0-4.93-4.94h-9.45a4.79,4.79,0,0,0-3.66,1.65L148.44,67.41a3.23,3.23,0,0,1-2.38,1.07,3.19,3.19,0,0,1-3.18-3.2Z"
          className="white"
        />
      </g>
    </g>
  </svg>
);
