import { Col } from '@gimlite/watermelon/components/col/col.component';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { useSelector } from '@xstate/react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { createFpsService } from '../../createFps.machine';
import './bilan.step.scss';
import { CreateFPSWizardContext } from '../../createFps.wizard';

export const BilanStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateFPSWizardContext>) => {
  const { t, lang }: any = useTranslation();

  const fps = useSelector(createFpsService, ({ context }: any) => context.fps);
  const formattedDate = useCallback(
    (date: string) =>
      t(`DATETIME_HUMAN`)
        .replace('[DATE]', DateTime.fromISO(date).toFormat(`DDDD`))
        .replace('[TIME]', DateTime.fromISO(date).toFormat(`TT`)),
    [lang],
  );

  const formattedAddress = useCallback((address: any) => {
    if (!address) return '';
    const addressClear = Object.fromEntries(
      Object.entries(address).map(([key, value]) => [
        key,
        `${new String(value).trim()}`,
      ]),
    );
    const {
      streetNumber,
      streetType,
      streetName,
      postalCode,
      addressLocality,
    } = addressClear;
    return `${streetNumber} ${streetType} ${streetName}, ${postalCode} ${addressLocality}`;
  }, []);

  const notDefined = useMemo(
    () => (
      <Write
        data={{ item: t('notDefined') }}
        config={{
          mode: 'value-small',
          color: 'disabled',
        }}
      />
    ),
    [lang],
  );

  useEffect(() => {
    createFpsService.send('UPDATE_FPS', { payload: context });
  }, []);

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: Object) => {
          submit(value);
        },
        validate: (value: boolean) => {
          validate(true);
        },
      }}
    >
      <Zone
        config={{
          zones: [['car', 'fps']],
          rows: ['1fr'],
          columns: ['1fr', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'car' }}>
          <Widget
            config={{
              title: t('vehicleInformation'),
              icon: 'faCarSolid',
              shadow: false,
            }}
          >
            <Col>
              <Space />
              <Description
                data={[
                  {
                    key: 'immatriculation',
                    label: t('immatriculation'),
                    value: context?.lpn || notDefined,
                  },
                  {
                    key: 'brand',
                    label: t('brand'),
                    value: context?.brand || notDefined,
                  },
                  {
                    key: 'country',
                    label: t('country'),
                    value: context?.country,
                  },
                  {
                    key: 'model',
                    label: t('model'),
                    value: context?.model || notDefined,
                  },
                ]}
                config={{ layout: 'column' }}
              />
            </Col>
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'fps' }}>
          <Widget
            config={{
              icon: 'faFileSignatureSolid',
              title: t('fpsInformation'),
              shadow: false,
            }}
          >
            <Col>
              <Space />
              <Description
                data={[
                  {
                    key: 'dateTimeOfDispute',
                    label: t('dateTimeOfDispute'),
                    value: formattedDate(fps?.exit?.date),
                  },
                  {
                    key: 'place',
                    label: t('place'),
                    value: formattedAddress(fps?.address),
                  },
                  {
                    key: 'country',
                    label: t('country'),
                    value: 'France', // Donnée non disponible
                  },
                  // {
                  //   key: 'endValidity',
                  //   label: 'Fin de validité du FPS',
                  //   value: '26.11.2021 11h39',
                  // },
                  {
                    key: 'comment',
                    label: t('comment'),
                    value: <pre>{context?.comment}</pre>,
                  },
                ]}
                config={{ layout: 'column' }}
              />
            </Col>
          </Widget>
        </Zone.Area>
      </Zone>
    </Form>
  );
};
