import { Socket } from 'socket.io-client'
import { findId } from '../../functions/entities.function'
import { Identifiable } from '../../types/entities.type'

export type CacheEntry<T> = {
  value: T | T[]
  ids: string[]
  timestamp: number
  ttl: number
}

export type InnerCache<T> = {
  [key: string]: CacheEntry<T>
}

export class LRUCache<T extends Identifiable | Identifiable[]> {
  private cache: InnerCache<T> = {}
  private capacity: number
  private name: string
  private socket: Socket
  private invalidationSubscriptions: string[] = []

  constructor(
    capacity: number,
    invalidationSocket: Socket,
    lsKey: string = 'lruCache'
  ) {
    this.capacity = capacity
    this.name = lsKey
    this.socket = invalidationSocket
    this.load()
  }

  private load() {
    const cachedData = localStorage.getItem(this.name)
    if (cachedData) {
      this.cache = JSON.parse(cachedData) as InnerCache<T>
    }
  }

  private isCacheFull() {
    return Object.keys(this.cache).length >= this.capacity
  }

  private removeLeastRecentlyUsedItem() {
    const leastRecentlyUsedKey = Object.keys(this.cache).reduce((minKey, key) =>
      this.cache[minKey].timestamp < this.cache[key].timestamp ? minKey : key
    )
    delete this.cache[leastRecentlyUsedKey]
  }

  private cleanExpiredItems() {
    const now = Date.now()
    Object.keys(this.cache)
      .filter((key) => now >= this.cache[key].timestamp + this.cache[key].ttl)
      .forEach((key) => delete this.cache[key])
    this.persist()
  }

  public get(key: string): T | T[] | null {
    if (!(key in this.cache)) return null

    const value = this.cache[key].value
    this.cache[key].timestamp = Date.now()
    return value
  }

  public put(key: string, value: T | T[], ttl: number) {
    if (this.isCacheFull()) {
      this.removeLeastRecentlyUsedItem()
    }
    const ids = Array.isArray(value) ? value.map((v) => v._id) : [value._id]
    this.cache[key] = { value, ids, timestamp: Date.now(), ttl }
    this.cleanExpiredItems()
  }

  public hasKey(key: string) {
    this.cleanExpiredItems()
    return key in this.cache
  }

  public invalidate(id: string) {
    const evictionList = Object.keys(this.cache ?? {})
      .filter((key) => this.cache[key].ids.includes(id))

    evictionList.forEach((key) => delete this.cache[key])

    if (evictionList.length > 0) {
      this.persist()
    }
  }

  public cacheInvalidationCallback(event: any) {
    const id = findId(event)
    if (id) {
      this.invalidate(id)
    }
  }

  /*public registerInvalidationSubscription(ws: string) {
    if (this.invalidationSubscriptions.includes(ws)) return

    this.socket?.on(ws, this.cacheInvalidationCallback.bind(this))
    this.invalidationSubscriptions.push(ws)
  }*/

  private persist() {
    localStorage.setItem(this.name, JSON.stringify(this.cache))
  }

  public rm(key: string) {
    delete this.cache[key]
    this.persist()
  }

  public dispose() {
    this.cleanExpiredItems()
    this.persist()
    this.invalidationSubscriptions.forEach((sub) =>
      this.socket?.off(sub, this.cacheInvalidationCallback))
  }
}

/*
export class LFUCache<T extends Identifiable | Identifiable[]> {
  private db: IDBDatabase | null = null
  private cacheStore: IDBObjectStore | null = null
  private capacity: number
  private name: string
  private socket: Socket
  private invalidationSubscriptions: string[] = []

  private hitCount = 0
  private requestCount = 0

  constructor(
    capacity: number,
    invalidationSocket: Socket,
    dbName: string = 'lfuCache',
    storeName: string = 'cacheStore'
  ) {
    this.capacity = capacity
    this.name = dbName
    this.socket = invalidationSocket

    const request = indexedDB.open(this.name, 1)
    request.onupgradeneeded = () => {
      const db = request.result
      db.createObjectStore(storeName, { keyPath: 'key' })
    }
    request.onsuccess = () => {
      this.db = request.result
      this.cacheStore = this.db
        .transaction(storeName, 'readwrite')
        .objectStore(storeName)
      this.cleanExpiredItems()
    }
  }

  private async getAllKeys() {
    return new Promise<string[]>((resolve) => {
      const request = this.cacheStore?.getAllKeys()
      if (request) request.onsuccess = () => resolve(request.result as string[])
    })
  }

  private async cleanExpiredItems() {
    const now = Date.now()
    const keys = await this.getAllKeys()
    keys
      .map((key) => ({
        key,
        cacheEntry: this.cacheStore?.get(key) as unknown as CacheEntry<T>
      }))
      .filter(({ cacheEntry }) => now >= cacheEntry.timestamp + cacheEntry.ttl)
      .forEach(({ key }) => this.cacheStore?.delete(key))
  }

  private isCacheFull() {
    return new Promise<boolean>((resolve) => {
      const request = this.cacheStore?.count()
      if (request)
        request.onsuccess = () => resolve(request.result >= this.capacity)
    })
  }

  private async removeLeastFrequentlyUsedItem() {
    const keys = await this.getAllKeys()
    const leastFrequentlyUsedKey = keys.reduce((minKey, key) =>
      (this.cacheStore?.get(minKey) as unknown as CacheEntry<T>).count <
      (this.cacheStore?.get(key) as unknown as CacheEntry<T>).count
        ? minKey
        : key
    )
    this.cacheStore?.delete(leastFrequentlyUsedKey)
  }

  public async get(key: string): Promise<T | T[] | null> {
    this.requestCount++
    const cacheEntry = this.cacheStore?.get(key) as CacheEntry<T> | undefined
    if (!cacheEntry) return null

    const value = cacheEntry.value
    cacheEntry.timestamp = Date.now()
    cacheEntry.count++
    this.hitCount++
    this.cacheStore?.put(cacheEntry)
    return value
  }

  public async put(key: string, value: T | T[], ttl: number) {
    this.requestCount++
    if (await this.isCacheFull()) {
      await this.removeLeastFrequentlyUsedItem()
    }
    const ids = Array.isArray(value) ? value.map((v) => v._id) : [value._id]
    const cacheEntry: CacheEntry<T> = {
      value,
      ids,
      timestamp: Date.now(),
      ttl,
      count: 1
    }
    this.cacheStore?.put({ ...cacheEntry, key })
    this.cleanExpiredItems()
  }

  public async hasKey(key: string): Promise<boolean> {
    await this.cleanExpiredItems()
    return this.cacheStore?.get(key) !== undefined
  }

  public registerInvalidationSubscription(ws: string) {
    if (this.invalidationSubscriptions.includes(ws)) return

    this.invalidationSubscriptions.push(ws)
    this.socket.on(ws, (event) => {
      const id = findId(event)
      if (id) {
        this.cleanExpiredItems().then(() => {
          const transaction = this.db?.transaction(this.name, 'readwrite')
          const objectStore = transaction?.objectStore(this.name)
          const request = objectStore?.openCursor()
          if (request)
            request.onsuccess = () => {
              const cursor = request.result
              if (cursor) {
                if (cursor.value.ids.includes(id)) {
                  cursor.delete()
                }
                cursor.continue()
              }
            }
        })
      }
    })
  }

  public dispose() {
    this.cleanExpiredItems()
    this.invalidationSubscriptions.forEach((sub) => this.socket?.off(sub))
  }

  public async getMedianCacheHitRatio(): Promise<number> {
    const cacheEntries = await new Promise<CacheEntry<T>[]>((resolve) => {
      const request = this.cacheStore?.getAll()
      if (request)
        request.onsuccess = () => resolve(request.result as CacheEntry<T>[])
    })

    const hitRatios = cacheEntries.map(
      (entry) => entry.count / this.requestCount
    )
    hitRatios.sort((a, b) => a - b)
    const middleIndex = Math.floor(hitRatios.length / 2)
    return hitRatios.length % 2 === 0
      ? (hitRatios[middleIndex - 1] + hitRatios[middleIndex]) / 2
      : hitRatios[middleIndex]
  }
}
*/