import { Select as SelectAnt } from 'antd';
import React from 'react';
import type { Color, Size } from '../../types/component.type';
import { Form, FormItemType } from '../form/form.component';
import './select.component.scss';

export declare namespace SelectType {
  type Props = {
    className?: string;
    handleEvent?: {
      select?: SelectType.HandleEvent.Select['function'];
    };
    data: {
      items: SelectType.Data.Item[];
      value?: SelectType.Data.Value;
      defaultValue?: SelectType.Data.Value;
    };
    config?: {
      color?: string;
      mode?: 'multiple' | 'single';
      clear?: boolean;
      border?: boolean;
      width?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      minWidth?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      height?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      disabled?: boolean;
      backgroundColor?: Extract<Color, 'yin' | 'yang' | 'white' | 'black'>;
    };
  };

  namespace Data {
    type Item = {
      label: string | React.ReactNode;
      value: string | number | React.ReactNode;
      disabled?: boolean;
    };

    type Value = string | string[];
  }

  namespace HandleEvent {
    type Select = {
      value1: string | string[];
      return: void;
      function: (
        value: SelectType.HandleEvent.Select['value1'],
      ) => SelectType.HandleEvent.Select['return'];
    };
  }
}

export const Select = ({
  handleEvent: { select } = {},
  data: { defaultValue, value: valueInline, items = [] },
  config: {
    color,
    mode = 'single',
    width,
    minWidth,
    height,
    disabled = false,
    clear = false,
    border = true,
    backgroundColor,
  } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<SelectType.Props>) => {
  return (
    <Form.Context.Consumer>
      {(form) => (
        <SelectAnt
          onChange={(data: string | string[]) => {
            select?.(data);
            onChangeFormItem?.(data);
          }}
          className={`
          formItemTargetWidth--${form.width || width || 'medium'}
          select 
          select--width--${form.width || width || 'medium'}
          ${minWidth ? `select--minWidth--${minWidth}` : ''}
          select--height--${form.height || height || 'medium'}
          select--backgroundColor--${backgroundColor}
          ${className}
    `}
          style={{
            color: color,
          }}
          mode={mode === 'single' ? undefined : mode}
          options={items}
          allowClear={clear}
          variant={border ? 'outlined' : 'borderless'}
          value={valueInline ?? valueFormItem}
          defaultValue={defaultValue}
          disabled={disabled}
          maxTagCount={'responsive'}
          {...formItemParams}
        ></SelectAnt>
      )}
    </Form.Context.Consumer>
  );
};
