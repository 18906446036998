import { assign, createMachine, interpret } from 'xstate';
import { appService } from '../../app.machine';
import loginGql from './gql/login.gql';
import { requestGQL } from '@gimlite/watermelon/functions/request.function';
import resetPasswordGql from './gql/resetPassword.gql';

const initialContext = {
  user: {},
  contract: false,
  shouldResetPassword: undefined,
  token: undefined,
  status: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    id: 'login',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: { target: 'idle' },
        },
      },
      idle: {
        on: {
          SHOW_ERROR: {
            actions: assign({
              error: (_, { error }) => error,
            }),
          },
          RESET_ERROR: {
            actions: assign({
              error: () => undefined,
            }),
          },
          UPDATE_USER: { actions: 'updateUser' },
          LOGIN: { target: 'login' },
          RESET_PASSWORD: { target: 'resetPassword' },
          KILL: { target: 'off' },
          RESET: { actions: 'resetAll' },
        },
      },
      login: {
        invoke: {
          src: 'login',
          onDone: {
            actions: ['successAuth'],
            target: 'idle',
          },
          onError: {
            actions: ['errorDeclare'],
            target: 'idle',
          },
        },
      },
      resetPassword: {
        invoke: {
          src: 'resetPassword',
          onDone: {
            actions: ['successAuth', 'successResetPassword', 'resetUser'],
            target: 'idle',
          },
          onError: {
            actions: ['errorDeclare'],
            target: 'idle',
          },
        },
      },
    },
  },
  {
    actions: {
      updateUser: assign({
        error: () => null,
        user: ({ user }, { type, ...rest }) => ({ ...user, ...rest }),
      }),
      successAuth: assign({
        shouldResetPassword: (_, { data }) =>
          data?.auth?.status === 'PASSWORD_CHANGE_REQUESTED',
        token: (_, { data }) => data?.auth?.token,
      }),
      successResetPassword: assign({
        contract: () => true,
      }),
      resetUser: assign({ user: {} }),
      errorDeclare: assign({
        error: () => 'incorrectCredential',
      }),
      resetAll: assign({
        token: () => undefined,
        contract: () => false,
        shouldResetPassword: () => undefined,
        user: () => {},
        error: () => undefined,
      }),
    },
    services: {
      login: async ({ user: { username, password } }) =>
        requestGQL({
          params: { username, password },
          gql: loginGql,
        }),
      resetPassword: async ({ user: { username, newPassword, password } }) =>
        requestGQL({
          params: { username, newPassword, currentPassword: password },
          gql: resetPasswordGql,
        }),
    },
  },
);

export const loginService = interpret(machine).start();
