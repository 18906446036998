import { gql } from '@apollo/client';

export const cameras = gql`
  query cameras($upsId: String!) {
    cameras(upsId: $upsId) {
      _id
      code
      ups
      position
    }
  }
`;
