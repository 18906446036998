import React, { useCallback } from 'react';
import { useTranslation } from '../../hook/useTranslation.hook';
import { notifService } from '../../machines/notif.machine';
import type {
  Color as ColorGlobal,
  Placement2Axis,
} from '../../types/component.type';
import './write.component.scss';

export declare namespace WriteType {
  type Props = {
    className?: string;
    handleEvent?: {
      click?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
      over?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    };
    data: {
      item: string;
    };
    config?: {
      wrap?: boolean;
      mode?:
        | 'title-big'
        | 'title-xlarge'
        | 'title-large'
        | 'title-medium'
        | 'title-small'
        | 'title-small-description'
        | 'cell-title'
        | 'cell-value'
        | 'namise'
        | 'namise-description'
        | 'label'
        | 'label-value'
        | 'value-small'
        | 'value-medium'
        | 'value-large'
        | 'input'
        | 'key-small-bold'
        | 'key-small-regular'
        | 'key-small-light'
        | 'link';
      color?: WriteType.Config.Color;
      align?: Extract<Placement2Axis, 'left' | 'center' | 'right'>;
      lineHeight?: boolean;
      copyPaste?: boolean;
      testId?: string;
    };
  };

  namespace Config {
    type Color = Extract<
      ColorGlobal,
      | 'success'
      | 'disabled'
      | 'warn'
      | 'error'
      | 'primary'
      | 'white'
      | 'black'
      | 'yin'
      | 'yang'
      | 'primary-over'
      | 'text'
      | 'label'
      | 'info'
    >;
  }
}

export const Write = ({
  className = '',
  handleEvent: { click, over } = {},
  data: { item },
  config: {
    mode = 'input',
    color,
    wrap = true,
    align = 'left',
    lineHeight = true,
    copyPaste = false,
    testId,
  } = {},
}: WriteType.Props) => {
  const { t, lang } = useTranslation();

  const handleCopyPaste = useCallback(
    (item: string) => {
      navigator.clipboard.writeText(item).then(() => {
        notifService.send('NOTIF', {
          mode: 'success',
          content: t('watermelon-copyToClipboard'),
        });
      });
    },
    [lang],
  );

  return (
    <div
      className={`write 
        write--mode--${mode} 
        write--color--${color} 
        ${copyPaste ? 'write--copyPaste' : ''}
        ${lineHeight ? 'write--lineHeight--max' : 'write--lineHeight--min'}
        ${className}`}
      style={{
        textAlign: align,
        whiteSpace: !wrap ? 'nowrap' : undefined,
        cursor: click || over || copyPaste ? 'pointer' : undefined,
      }}
      onClick={(e) => {
        if (click) click?.(e);
        if (copyPaste) handleCopyPaste(item);
      }}
      onMouseOver={(e) => over?.(e)}
      data-testid={testId}
    >
      {copyPaste && (
        <div className="write__copyPaste">
          <span translate="no">{t('watermelon-copyTheContent')}</span>
        </div>
      )}
      {['title-big'].includes(mode) && <h1 className="write__item">{item}</h1>}
      {['title-xlarge', 'title-large'].includes(mode) && (
        <h2 translate="no" className="write__item">
          {item}
        </h2>
      )}
      {['title-medium'].includes(mode) && (
        <h3 translate="no" className="write__item">
          {item}
        </h3>
      )}
      {['title-small'].includes(mode) && (
        <h4 translate="no" className="write__item">
          {item}
        </h4>
      )}
      {[
        'title-small-description',
        'namise',
        'namise-description',
        'label',
        'label-value',
        'value-small',
        'value-medium',
        'value-large',
        'cell-title',
        'cell-value',
        'key-small-bold',
        'key-small-regular',
        'key-small-light',
        'link',
      ].includes(mode) && (
        <span translate="no" className="write__item">
          {item}
        </span>
      )}
      {['input'].includes(mode) && (
        <p translate="no" className="write__item">
          {item}
        </p>
      )}
    </div>
  );
};
