import { gql } from '@apollo/client';

export const events = gql`
  query events(
    $limit: Int
    $page: Int
    $order: String
    $startDate: String
    $endDate: String
    $way: String
    $upsId: String!
    $lpnLike: String
    $cameraId: String
    $isVehicle: Boolean
    $knownLpn: Boolean
  ) {
    events(
      limit: $limit
      page: $page
      order: $order
      startDate: $startDate
      endDate: $endDate
      upsId: $upsId
      way: $way
      lpnLike: $lpnLike
      cameraId: $cameraId
      isVehicle: $isVehicle
      knownLpn: $knownLpn
    ) {
      list {
        _id
        way
        confidence
        happenedAt
        hasSubscription
        hasParkingRight
        knownLpn
        isAnonymised
        vehicle {
          lpn
        }
        resources {
          list {
            url
          }
          paging {
            current
            count
            limit
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;

export const event = gql`
  query event($cameraEventId: String!) {
    event(cameraEventId: $cameraEventId) {
      _id
      way
      confidence
      happenedAt
      vehicle {
        lpn
        type
      }
      camera {
        code
        ups
        position
      }
      resources {
        list {
          url
        }
        paging {
          current
          count
          limit
        }
      }
      createdAt
    }
  }
`;
