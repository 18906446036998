import { Input as InputAnt, InputNumber as InputNumberAnt } from 'antd';
import React from 'react';
import type { Color, Size } from '../../types/component.type';
import { Button } from '../button/button.component';
import { Form, FormItemType } from '../form/form.component';
import './input.component.scss';

export declare namespace InputType {
  type Props = {
    className?: string;
    data?: {
      value?: InputType.Data.Value;
      defaultValue?: InputType.Data.Value;
    };
    config?: {
      width?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      minWidth?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      height?: Extract<
        Size,
        | 'initial'
        | 'xsmall'
        | 'small'
        | 'xmedium'
        | 'medium'
        | 'large'
        | 'xlarge'
        | 'full'
      >;
      placeholder?: string;
      type?:
        | { name: 'password' }
        | { name: 'text' }
        | { name: 'email' }
        | { name: 'number'; min?: number; max?: number };
      border?: boolean;
      min?: number;
      max?: number;
      addons?: {
        before?: React.ReactNode;
        after?: React.ReactNode;
      };
      disabled?: boolean;
      backgroundColor?: Extract<Color, 'yin' | 'yang' | 'white' | 'black'>;
    };
    handleEvent?: {
      input?: (value: InputType.Data.Value) => void;
      clickBefore?: (value: boolean) => void;
      clickAfter?: (value: boolean) => void;
    };
  };

  namespace Data {
    type Value = string | number | null;
  }
}

export const Input = ({
  data: { defaultValue, value } = {},
  handleEvent: { input, clickBefore, clickAfter } = {},
  config: {
    width,
    minWidth,
    height,
    placeholder = '',
    type,

    addons: { before = undefined, after = undefined } = {},
    disabled = false,
    backgroundColor,
    border = true,
    min,
    max,
  } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<InputType.Props>) => {
  const typeFormatted = type || { name: 'text' };

  const options = {
    ...formItemParams,
    className: `input__item`,
    placeholder,
    disabled,
    // controls,
  };

  return (
    <Form.Context.Consumer>
      {(form) => (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`
            formItemTargetWidth--${form.width || width || 'medium'}
            input 
            input--width--${form.width || width || 'medium'}
            ${minWidth ? `input--minWidth--${minWidth}` : ''}
            input--height--${form.height || height || 'medium'}
            input--backgroundColor--${backgroundColor}
           
            ${className}
        `}
        >
          {before && (
            <Button
              handleEvent={{
                click: () => clickBefore?.(true),
              }}
              className="input__button input__button--before"
              config={{ text: before }}
            />
          )}
          {typeFormatted.name !== 'number' ? (
            <InputAnt
              minLength={min}
              maxLength={max}
              autoComplete="off"
              variant={border ? 'outlined' : 'borderless'}
              type={typeFormatted.name}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                input?.(value);
                onChangeFormItem?.(value);
              }}
              defaultValue={defaultValue as string}
              value={valueFormItem ?? value}
              {...options}
            />
          ) : (
            <InputNumberAnt
              minLength={min}
              maxLength={max}
              type={typeFormatted.name}
              min={typeFormatted?.min}
              max={typeFormatted?.max}
              variant={border ? 'outlined' : 'borderless'}
              onChange={(value: number | null) => {
                input?.(value);
                onChangeFormItem?.(value);
              }}
              defaultValue={defaultValue as number}
              value={valueFormItem ?? value}
              {...options}
            />
          )}
          {after && (
            <Button
              handleEvent={{
                click: () => clickAfter?.(true),
              }}
              className="input__button input__button--after"
              config={{ text: after }}
            />
          )}
        </div>
      )}
    </Form.Context.Consumer>
  );
};
