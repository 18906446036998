export const cars: Record<string, string[]> = {
  Abarth: [
    '124 Spider',
    '500 / 595 / 695',
    '500C / 595C / 695C',
    '500E 3/5 portes',
    '500e Décapotable',
    'GRANDE PUNTO',
    'PUNTO',
    'PUNTO EVO',
    'RITMO'
  ],
  'Alfa Romeo': [
    '145',
    '146',
    '147',
    '155',
    '156',
    '156 Sportwagon',
    '159',
    '159 Sportwagon',
    '164',
    '166',
    '1750-2000',
    '1900',
    '2300',
    '2600 BERLINA',
    '2600 SPIDER',
    '2600 SPRINT',
    '33',
    '33 Sportwagon',
    '4C',
    '4C SPIDER',
    '6',
    '75',
    '8C',
    '8C SPIDER',
    '90',
    'ALFASUD',
    'ALFASUD Giardinetta',
    'ALFASUD Sprint',
    'ALFETTA',
    'ALFETTA GT',
    'ARNA',
    'BERLINA',
    'BRERA',
    'DISCO VOLANTE',
    'GIULIA',
    'GIULIA GT',
    'GIULIA SPIDER',
    'GIULIETTA',
    'GIULIETTA BERLINA',
    'GIULIETTA Break',
    'GIULIETTA Coupé',
    'GIULIETTA SPIDER',
    'GIULIETTA SPRINT',
    'GIULIETTA SPRINT SPECIALE',
    'GT',
    'GTA coupé',
    'GTV',
    'MATTA Véhicule tout terrain à',
    'MITO',
    'MONTREAL',
    'RZ',
    'SPIDER',
    'STELVIO',
    'SZ',
    'TONALE'
  ],
  Audi: [
    '100 C1 A trois volumes',
    '100 C1 Coupé',
    '100 C2 A trois volumes',
    '100 C2 Avant',
    '100 C3 A trois volumes',
    '100 C3 Avant',
    '100 C4 A trois volumes',
    '100 C4 Avant',
    '200 C2 A trois volumes',
    '200 C3 A trois volumes',
    '200 C3 Avant',
    '50',
    '60',
    '60 Variant',
    '75',
    '75 Variant',
    '80',
    '80 B1 A trois volumes',
    '80 B2 A trois volumes',
    '80 B3 A trois volumes',
    '80 B4 A trois volumes',
    '80 B4 Avant',
    '80 Variant',
    '90 B2',
    '90 B3',
    'A1',
    'A1 Allstreet',
    'A1 CITY CARVER',
    'A1 Sportback',
    'A2',
    'A3',
    'A3 Allstreet',
    'A3 Décapotable',
    'A3 Limousine',
    'A3 Sportback',
    'A4 Allroad B8',
    'A4 Allroad B9',
    'A4 B5',
    'A4 B5 Avant',
    'A4 B6',
    'A4 B6 Avant',
    'A4 B6 Décapotable',
    'A4 B7',
    'A4 B7 Avant',
    'A4 B7 Décapotable',
    'A4 B8',
    'A4 B8 Avant',
    'A4 B9',
    'A4 B9 Avant',
    'A5',
    'A5 Décapotable',
    'A5 Sportback',
    'A6 Allroad C6',
    'A6 Allroad C7',
    'A6 Allroad C8',
    'A6 C4',
    'A6 C4 Avant',
    'A6 C5',
    'A6 C5 Avant',
    'A6 C6',
    'A6 C6 Avant',
    'A6 C7',
    'A6 C7 Avant',
    'A6 C8',
    'A6 C8 Avant',
    'A7 Sportback',
    'A8 D2',
    'A8 D3',
    'A8 D4',
    'A8 D5',
    'ALLROAD C5',
    'CABRIOLET B3',
    'COUPE B2',
    'COUPE B3',
    'E-TRON',
    'E-TRON GT A trois volumes',
    'E-TRON Sportback',
    'F103',
    'F103 Variant',
    'Q2',
    'Q3',
    'Q3 Sportback',
    'Q4 SUV',
    'Q4 Sportback',
    'Q5',
    'Q5 Sportback',
    'Q7',
    'Q8',
    'Q8 E-TRON SUV',
    'Q8 E-TRON Sportback',
    'QUATTRO',
    'R8',
    'R8 Spyder',
    'SUPER 90',
    'TT',
    'TT Roadster',
    'V8'
  ],
  BMW: [
    '1',
    '1 Décapotable',
    '1 coupé',
    '1500-2000',
    '1502-2002',
    '1502-2002 Décapotable',
    '1502-2002 Touring',
    '1600 GT coupé',
    '2 Active Tourer',
    '2 Coupé',
    '2 Décapotable',
    '2 Gran Coupe',
    '2 Gran Tourer',
    '2.5-3.2 Coupé',
    '2000 Coupé',
    '2500-3.3',
    '3',
    '3 Compact',
    '3 Décapotable',
    '3 GRAN TURISMO',
    '3 Touring',
    '3 coupé',
    '340',
    '4 Coupé',
    '4 Décapotable',
    '4 Gran Coupe',
    '5',
    '5 GRAN TURISMO',
    '5 Touring',
    '501',
    '502 A trois volumes',
    '503 Décapotable',
    '507',
    '6',
    '6 Coupé',
    '6 Décapotable',
    '6 Gran Coupe',
    '6 Gran Turismo',
    '600',
    '7',
    '700',
    '700 coupé',
    '8',
    '8 Coupé',
    '8 Décapotable',
    '8 Gran Coupe',
    'GLAS',
    'ISETTA',
    'M1',
    'V8 Coupé',
    'X1',
    'X2',
    'X3',
    'X4',
    'X5',
    'X6',
    'X7',
    'XM',
    'Z1 Roadster',
    'Z3 Roadster',
    'Z3 coupé',
    'Z4 ROADSTER',
    'Z4 coupé',
    'Z8 Roadster',
    'i3',
    'i4',
    'i8',
    'i8 Roadster',
    'iX',
    'iX3'
  ],
  Citroën: [
    '2 CV',
    'AMI',
    'AMI Break',
    'AX',
    'AXEL',
    'BERLINGO',
    'BERLINGO / BERLINGO FIRST Mono',
    'BERLINGO MULTISPACE',
    'BX',
    'BX Break',
    'C-CROSSER',
    'C-ELYSEE',
    'C-ZERO',
    'C1',
    'C1 II',
    'C15 Break',
    'C2',
    'C3 AIRCROSS I',
    'C3 AIRCROSS II',
    'C3 I',
    'C3 II',
    'C3 III',
    'C3 Picasso',
    'C3 Pluriel',
    'C4 AIRCROSS',
    'C4 CACTUS',
    'C4 Grand Picasso I',
    'C4 Grand Picasso II',
    'C4 I',
    'C4 I A trois volumes',
    'C4 II',
    'C4 II A trois volumes',
    'C4 III',
    'C4 Picasso I Monospace',
    'C4 Picasso II',
    'C4 SPACETOURER',
    'C4 X',
    'C4 coupé',
    'C5 AIRCROSS',
    'C5 I',
    'C5 I Break',
    'C5 II',
    'C5 II Break',
    'C5 III',
    'C5 III Break',
    'C5 X',
    'C6',
    'C8',
    'CX I',
    'CX I Break',
    'CX II',
    'CX II Break',
    'DS',
    'DS Break',
    'DS Décapotable',
    'DS3',
    'DS3 Décapotable',
    'DS4',
    'DS5',
    'DYANE',
    'E-MEHARI',
    'EVASION Monospace',
    'GRAND C4 SPACETOURER',
    'GS',
    'GS Break',
    'ID',
    'ID Break',
    'ID Décapotable',
    'LNA',
    'MEHARI',
    'NEMO Monospace',
    'SAXO',
    'SM',
    'TRACTION AVANT',
    'VISA',
    'VISA Décapotable',
    'XANTIA',
    'XANTIA Break',
    'XM',
    'XM Break',
    'XSARA',
    'XSARA Break',
    'XSARA PICASSO',
    'XSARA coupé',
    'ZX',
    'ZX Break'
  ],
  Dacia: [
    '1100',
    '1210',
    '1300',
    '1300 Break',
    '1304 Pick-up',
    '1309 Pick-up',
    '1310 A trois volumes',
    '1310 Break',
    '1310 Coupé',
    '1325',
    '1410 A trois volumes',
    '1410 Break',
    'DOKKER Monospace',
    'DUSTER',
    'JOGGER',
    'LODGY',
    'LOGAN',
    'LOGAN II',
    'LOGAN III',
    'LOGAN MCV',
    'LOGAN MCV II',
    'LOGAN pick-up',
    'NOVA',
    'Pick Up',
    'SANDERO',
    'SANDERO II',
    'SANDERO III',
    'SOLENZA',
    'SPRING',
    'SUPERNOVA'
  ],
  DS: [
    'DS 3',
    'DS 3 / DS 3 CROSSBACK',
    'DS 3 Décapotable',
    'DS 4 / DS 4 CROSSBACK',
    'DS 4 II',
    'DS 5',
    'DS 7',
    'DS 7 Crossback',
    'DS 9'
  ],
  Fiat: [
    '1100-1900',
    '124',
    '124 Familiare',
    '124 Spider',
    '124 coupé',
    '125',
    '126',
    '127',
    '127 Panorama',
    '128',
    '128 A trois volumes',
    '128 Familiare',
    '128 coupé',
    '130',
    '130 coupé',
    '131',
    '131 Familiare/Panorama',
    '132',
    '133',
    '1400 Décapotable',
    '147',
    '147 Panorama',
    '1500 Décapotable',
    '1500-2300',
    '1900 Coupé',
    '500',
    '500 A / B Berlina',
    '500 B Giardiniera',
    '500 Break',
    '500 C',
    '500 C Berlina',
    '500 C Giardiniera',
    '500L',
    '500X',
    '500e',
    '500e Décapotable',
    '600',
    '600e',
    '850',
    '850 Spider',
    '850 coupé',
    'ARGENTA',
    'BALILLA 508 S Coupé',
    'BALILLA 508 Saloon',
    'BALILLA 508 Spider',
    'BALILLA 508 Tourer',
    'BARCHETTA',
    'BRAVA',
    'BRAVO I',
    'BRAVO II',
    'CAMPAGNOLA',
    'CINQUECENTO',
    'COUPE',
    'CROMA',
    'DINO Coupé',
    'DINO Spider',
    'DOBLO Monospace',
    'DUNA',
    'DUNA A trois volumes',
    'DUNA Weekend',
    'ELBA',
    'FIORINO Monospace',
    'FIORINO Pick up',
    'FREEMONT',
    'FULLBACK Pick-up',
    'GRANDE PUNTO',
    'IDEA',
    'JAGST',
    'LINEA',
    'MAREA',
    'MAREA Weekend',
    'MULTIPLA',
    'PALIO',
    'PALIO Weekend',
    'PANDA',
    'PREMIO',
    'PUNTO',
    'PUNTO Décapotable',
    'PUNTO EVO',
    'QUBO',
    'REGATA',
    'REGATA Weekend',
    'RITMO',
    'RITMO Décapotable',
    'SEDICI',
    'SEICENTO / 600',
    'SIENA',
    'STILO',
    'STILO Multi Wagon',
    'STRADA Pick-up',
    'TEMPRA',
    'TEMPRA SW',
    'TIPO',
    'TIPO 3/5 portes',
    'TIPO A trois volumes',
    'TIPO Break',
    'ULYSSE',
    'UNO',
    'X 1/9'
  ],
  Ford: [
    'B-MAX',
    'C-MAX',
    'C-MAX II',
    'CAPRI I',
    'CAPRI II',
    'CAPRI III',
    'CONSUL',
    'CONSUL Turnier',
    'CONSUL coupé',
    'CORSAIR',
    'CORSAIR Estate',
    'CORTINA',
    'CORTINA Estate',
    'CORTINA coupé',
    'COUGAR',
    'Courier pick-up',
    'ECOSPORT',
    'ESCORT CLASSIC',
    'ESCORT CLASSIC Turnier',
    'ESCORT I',
    'ESCORT I Break',
    'ESCORT II',
    'ESCORT II Break',
    'ESCORT III',
    'ESCORT III Break',
    'ESCORT III Décapotable',
    'ESCORT IV',
    'ESCORT IV Break',
    'ESCORT IV Décapotable',
    'ESCORT V',
    'ESCORT V A trois volumes',
    'ESCORT V Break',
    'ESCORT V Décapotable',
    'ESCORT VI',
    'ESCORT VI A trois volumes',
    'ESCORT VI Clipper',
    'ESCORT VI Décapotable',
    'F-250',
    'FIESTA A trois volumes',
    'FIESTA I',
    'FIESTA II',
    'FIESTA III',
    'FIESTA IV',
    'FIESTA V',
    'FIESTA VI',
    'FIESTA VII',
    'FOCUS C-MAX',
    'FOCUS I',
    'FOCUS I A trois volumes',
    'FOCUS I Clipper',
    'FOCUS II',
    'FOCUS II A trois volumes',
    'FOCUS II Décapotable',
    'FOCUS II Turnier',
    'FOCUS III',
    'FOCUS III A trois volumes',
    'FOCUS III Turnier',
    'FOCUS IV',
    'FOCUS IV A trois volumes',
    'FOCUS IV Turnier',
    'FUSION',
    'GALAXY I',
    'GALAXY II',
    'GALAXY III',
    'GRANADA',
    'GRANADA Break',
    'GRANADA I Coupé',
    'GRANADA II',
    'GRANADA Turnier',
    'GRAND C-MAX',
    'GT',
    'KA',
    'KA+ III',
    'KA+ III A trois volumes',
    'KUGA I',
    'KUGA II',
    'KUGA III',
    'MAVERICK',
    'MONDEO I',
    'MONDEO I A trois volumes',
    'MONDEO I Clipper',
    'MONDEO II',
    'MONDEO II A trois volumes',
    'MONDEO II Clipper',
    'MONDEO III',
    'MONDEO III A trois volumes',
    'MONDEO III Clipper',
    'MONDEO IV',
    'MONDEO IV A trois volumes',
    'MONDEO IV Turnier',
    'MONDEO V 3/5 portes',
    'MONDEO V A trois volumes',
    'MONDEO V Turnier',
    'ORION I',
    'ORION II',
    'ORION III',
    'P 100 I',
    'P 100 II',
    'Puma',
    'Ranger',
    'S-MAX',
    'SCORPIO I',
    'SCORPIO I A trois volumes',
    'SCORPIO I Break',
    'SCORPIO II',
    'SCORPIO II Break',
    'SIERRA Break',
    'SIERRA I 3/5 portes',
    'SIERRA II',
    'SIERRA II 3/5 portes',
    'SIERRA II Turnier',
    'STREET KA',
    'TAUNUS',
    'TAUNUS 12M I',
    'TAUNUS 12M II',
    'TAUNUS 12M II Coupé',
    'TAUNUS 12M II Turnier',
    'TAUNUS 15M I',
    'TAUNUS 15M II',
    'TAUNUS 15M II Coupé',
    'TAUNUS 15M II Turnier',
    'TAUNUS 17M I',
    'TAUNUS 17M II',
    'TAUNUS 17M II Coupé',
    'TAUNUS 17M II Turnier',
    'TAUNUS 20M II',
    'TAUNUS 20M II Coupé',
    'TAUNUS 20M II Turnier',
    'TAUNUS 20M II XL',
    'TAUNUS 20M II XL Coupe',
    'TAUNUS 26M XL',
    'TAUNUS 26M XL coupe',
    'TAUNUS I',
    'TAUNUS III Turnier',
    'TAUNUS Turnier',
    'TAUNUS V',
    'TAUNUS coupé',
    'TOURNEO CONNECT',
    'TOURNEO CONNECT / GRAND TOURNE',
    'TOURNEO COURIER B460 Monospace',
    'TRANSIT CONNECT Monospace',
    'TRANSIT COURIER B460 Monospace',
    'ZEPHYR III',
    'ZEPHYR III Station Wagon',
    'ZEPHYR IV',
    'ZEPHYR IV Station Wagon',
    'ZODIAC',
    'ZODIAC Station Wagon'
  ],
  Honda: [
    'ACCORD 3/5 portes',
    'ACCORD I',
    'ACCORD I Hatchback',
    'ACCORD II',
    'ACCORD II Hatchback',
    'ACCORD III',
    'ACCORD III Aerodeck',
    'ACCORD III Coupé',
    'ACCORD IV',
    'ACCORD IV Aerodeck',
    'ACCORD IV coupé',
    'ACCORD IX A trois volumes',
    'ACCORD V',
    'ACCORD V Aerodeck',
    'ACCORD V coupé',
    'ACCORD VI',
    'ACCORD VI Coupé',
    'ACCORD VI Hatchback',
    'ACCORD VII',
    'ACCORD VII Tourer',
    'ACCORD VIII',
    'ACCORD VIII Break',
    'BEAT',
    'CAPA',
    'CITY A trois volumes',
    'CITY I 3/5 portes',
    'CITY III A trois volumes',
    'CITY IV A trois volumes',
    'CITY V A trois volumes',
    'CIVIC A trois volumes',
    'CIVIC I A trois volumes',
    'CIVIC I Hatchback',
    'CIVIC I Shuttle',
    'CIVIC II 3/5 portes',
    'CIVIC II Break',
    'CIVIC II Shuttle',
    'CIVIC III A trois volumes',
    'CIVIC III Hatchback',
    'CIVIC IV A trois volumes',
    'CIVIC IV Hatchback',
    'CIVIC IX',
    'CIVIC IX A trois volumes',
    'CIVIC IX Coupé',
    'CIVIC IX Tourer',
    'CIVIC V A trois volumes',
    'CIVIC V Hatchback',
    'CIVIC V coupé',
    'CIVIC VI A trois volumes',
    'CIVIC VI Aerodeck',
    'CIVIC VI Fastback',
    'CIVIC VI Hatchback',
    'CIVIC VI coupé',
    'CIVIC VII A trois volumes',
    'CIVIC VII Hatchback',
    'CIVIC VII coupé',
    'CIVIC VIII A trois volumes',
    'CIVIC VIII Hatchback',
    'CIVIC X 3/5 portes',
    'CIVIC X A trois volumes',
    'CIVIC XI 3/5 portes',
    'CIVIC XI A trois volumes',
    'CONCERTO',
    'CONCERTO A trois volumes',
    'CR-V I',
    'CR-V II',
    'CR-V III',
    'CR-V IV',
    'CR-V V',
    'CR-Z',
    'CROSSROAD',
    'CROSSTOUR I',
    'CRX I',
    'CRX II',
    'CRX III',
    'DOMANI',
    'E',
    'ELEMENT',
    'FR-V',
    'HR-V',
    'INSIGHT',
    'INSPIRE',
    'INTEGRA 3/5 portes',
    'INTEGRA A trois volumes',
    'INTEGRA Coupé',
    'JAZZ I',
    'JAZZ II',
    'JAZZ III',
    'JAZZ IV',
    'JAZZ SHUTTLE',
    'JAZZ V',
    'LEGEND I',
    'LEGEND I coupé',
    'LEGEND II',
    'LEGEND II coupé',
    'LEGEND III',
    'LEGEND IV',
    'LOGO',
    'N600 III',
    'NSX I Coupé',
    'NSX I Décapotable',
    'NSX II Coupé',
    'ODYSSEY',
    'ODYSSEY Monospace',
    'ORTHIA / PARTNER',
    'PILOT',
    'PRELUDE I coupé',
    'PRELUDE II',
    'PRELUDE III',
    'PRELUDE IV',
    'PRELUDE V',
    'QUINTET',
    'RIDGELINE',
    'S2000',
    'SHUTTLE',
    'STEPWGN',
    'STREAM',
    'ZR-V',
    'e:Ny1'
  ],
  Hyundai: [
    'ACCENT II',
    'ACCENT II A trois volumes',
    'ACCENT III',
    'ACCENT III A trois volumes',
    'ACCENT IV',
    'ACCENT IV A trois volumes',
    'ACCENT V A trois volumes',
    'ATOS',
    'BAYON',
    'COUPE I',
    'COUPE II',
    'CRETA',
    'ELANTRA III',
    'ELANTRA III A trois volumes',
    'ELANTRA IV A trois volumes',
    'ELANTRA V A trois volumes',
    'ELANTRA VI A trois volumes',
    'ELANTRA VII A trois volumes',
    'EQUUS / CENTENNIAL',
    'GALLOPER I',
    'GALLOPER II',
    'GENESIS',
    'GENESIS Coupé',
    'GETZ',
    'GRAND SANTA FÉ',
    'GRANDEUR',
    'I30',
    'IONIQ',
    'IONIQ 5',
    'IONIQ 6',
    'KONA',
    'LANTRA I',
    'LANTRA II',
    'LANTRA II Break',
    'MARCIA',
    'MATRIX',
    'NEXO',
    'PONY',
    'PONY / EXCEL A trois volumes',
    'PONY A trois volumes',
    'PONY I',
    'PONY Wagon',
    'S COUPE',
    'SANTA FE IV',
    'SANTA FÉ I',
    'SANTA FÉ II',
    'SANTA FÉ III',
    'SANTAMO',
    'SOLARIS A trois volumes',
    'SONATA II',
    'SONATA III',
    'SONATA IV',
    'SONATA V',
    'SONATA VI',
    'SONATA VII',
    'SONATA VIII',
    'STELLAR',
    'TERRACAN',
    'TIBURON Coupé',
    'TRAJET',
    'TUCSON',
    'VELOSTER',
    'VENUE',
    'XG',
    'XG A trois volumes',
    'i10 I',
    'i10 II',
    'i10 II A trois volumes',
    'i10 III',
    'i20 ACTIVE',
    'i20 I',
    'i20 II',
    'i20 II Coupé',
    'i20 III',
    'i30 Break',
    'i30 Coupé',
    'i30 FASTBACK',
    'i40 I',
    'i40 I CW',
    'ix20',
    'ix35',
    'ix55'
  ],
  Kia: [
    'AVELLA',
    'AVELLA 3/5 portes',
    'BORREGO',
    'CADENZA I',
    'CADENZA II',
    'CARENS I',
    'CARENS II Monospace',
    'CARENS III Monospace',
    'CARENS IV',
    'CARNIVAL / GRAND CARNIVAL III',
    'CARNIVAL I',
    'CARNIVAL II',
    'CARNIVAL IV',
    "CEE'D",
    "CEE'D 3/5 portes",
    "CEE'D SW",
    "CEE'D Sportswagon",
    'CEED',
    'CEED Sportswagon',
    'CERATO A trois volumes',
    'CERATO I 3/5 portes',
    'CERATO I A trois volumes',
    'CERATO II A trois volumes',
    'CERATO III A trois volumes',
    'CERATO IV A trois volumes',
    'CERATO KOUP II',
    'CERATO KOUP III',
    'CLARUS',
    'CLARUS Break',
    'CONCORD A trois volumes',
    'ELAN',
    'ENTERPRISE',
    'EV6',
    'EV9',
    'JOICE',
    'K8',
    'K9 II',
    'MAGENTIS I',
    'MAGENTIS II',
    'NIRO I',
    'NIRO II',
    'OPIRUS',
    'OPTIMA',
    'OPTIMA Sportswagon',
    'PICANTO I',
    'PICANTO II',
    'PICANTO III',
    'PRIDE',
    'PRIDE A trois volumes',
    'PRIDE Break',
    "PRO CEE'D",
    'PROCEED',
    'QUORIS I',
    'RETONA Véhicule tout terrain f',
    'RIO I 3/5 portes',
    'RIO I A trois volumes',
    'RIO II',
    'RIO II A trois volumes',
    'RIO III',
    'RIO III A trois volumes',
    'RIO IV',
    'RIO IV A trois volumes',
    'ROADSTER',
    'SELTOS',
    'SEPHIA',
    'SEPHIA A trois volumes',
    'SHUMA A trois volumes',
    'SHUMA I',
    'SHUMA II',
    'SHUMA II A trois volumes',
    'SORENTO I',
    'SORENTO II',
    'SORENTO III',
    'SORENTO IV',
    'SOUL I',
    'SOUL II',
    'SOUL III',
    'SPORTAGE II',
    'SPORTAGE III',
    'SPORTAGE IV',
    'SPORTAGE SUV',
    'SPORTAGE V',
    'STINGER',
    'STONIC',
    'VENGA',
    'XCEED'
  ],
  'Lan rover': [
    '110/127',
    '110/127 Pick-up',
    '88/109 MK I Véhicule tout terr',
    '88/109 MK II Véhicule tout ter',
    '88/109 MK IIA Véhicule tout te',
    '88/109 MK III Véhicule tout te',
    '90 I',
    'DEFENDER Cabrio',
    'DEFENDER Pick Up',
    'DEFENDER Station Wagon',
    'DISCOVERY I',
    'DISCOVERY II',
    'DISCOVERY III',
    'DISCOVERY IV',
    'DISCOVERY SPORT',
    'DISCOVERY V',
    'FREELANDER 2',
    'FREELANDER I',
    'FREELANDER I Soft Top',
    'RANGE ROVER EVOQUE',
    'RANGE ROVER EVOQUE Décapotable',
    'RANGE ROVER I',
    'RANGE ROVER II',
    'RANGE ROVER III',
    'RANGE ROVER IV',
    'RANGE ROVER SPORT I',
    'RANGE ROVER SPORT II',
    'RANGE ROVER SPORT III',
    'RANGE ROVER V',
    'RANGE ROVER VELAR'
  ],
  Mazda: [
    '1000',
    '121 I',
    '121 II',
    '121 III',
    '1300',
    '1800 A trois volumes',
    '2',
    '2 3/5 portes',
    '3',
    '3 3/5 portes',
    '3 A trois volumes',
    '323 C IV',
    '323 C V',
    '323 F IV',
    '323 F V',
    '323 F VI',
    '323 I',
    '323 I Break',
    '323 II',
    '323 II Hatchback',
    '323 III',
    '323 III Break',
    '323 III Hatchback',
    '323 P V',
    '323 S IV',
    '323 S V',
    '323 S VI',
    '5',
    '6 A trois volumes',
    '6 Break',
    '6 Hatchback',
    '6 Station Wagon',
    '616',
    '626 I',
    '626 I Coupé',
    '626 II',
    '626 II Coupé',
    '626 II Hatchback',
    '626 III',
    '626 III Break',
    '626 III Hatchback',
    '626 III coupé',
    '626 IV',
    '626 IV Hatchback',
    '626 V',
    '626 V Break',
    '626 V Hatchback',
    '818 Break',
    '818 Coupé',
    '929 I',
    '929 I Break',
    '929 II',
    '929 II Break',
    '929 II coupé',
    '929 III',
    '929 IV',
    'AZ OFFROAD',
    'AZ1',
    'B-Serie',
    'BT-50 II Pick-up',
    'BT-50 Pick-up',
    'CX-3',
    'CX-30',
    'CX-5',
    'CX-60',
    'CX-7',
    'CX-9',
    'DEMIO',
    'Festiva',
    'MPV I',
    'MPV II',
    'MX-3',
    'MX-30',
    'MX-5 I',
    'MX-5 II',
    'MX-5 III',
    'MX-5 IV',
    'MX-5 RF Targa',
    'MX-6',
    'PREMACY',
    'RX-2 A trois volumes',
    'RX-2 Coupé',
    'RX-4 Coupé',
    'RX-5',
    'RX-7 I',
    'RX-7 II',
    'RX-7 II Décapotable',
    'RX-7 III',
    'RX-8',
    'TRIBUTE',
    'XEDOS 6',
    'XEDOS 9'
  ],
  'Mercedes-Benz': [
    '/8',
    '/8 coupé',
    '123 A trois volumes',
    '123 Break',
    '123 Coupé',
    '124 A trois volumes',
    '124 Break',
    '124 Décapotable',
    '124 coupé',
    '170 A trois volumes',
    '170 Décapotable',
    '190',
    '300 A trois volumes',
    '300 Cabriolet A',
    '300 Cabriolet D',
    '300 Coupé',
    '300 Roadster',
    'AMG GT',
    'AMG GT Roadster',
    'CABRIOLET',
    'CITAN Monospace',
    'CITAN Tourer',
    'CLA',
    'CLA Coupé',
    'CLA Shooting Brake',
    'CLASSE A',
    'CLASSE A A trois volumes',
    'CLASSE B Sports Tourer',
    'CLASSE C',
    'CLASSE C All-Terrain',
    'CLASSE C Break',
    'CLASSE C Coupe Sport',
    'CLASSE C Coupé',
    'CLASSE C Décapotable',
    'CLASSE C T-Model',
    'CLASSE CLC',
    'CLASSE E',
    'CLASSE E All-Terrain',
    'CLASSE E Break',
    'CLASSE E Coupé',
    'CLASSE E Décapotable',
    'CLASSE E T-Model',
    'CLASSE G',
    'CLASSE G Cabrio',
    'CLASSE GL',
    'CLASSE GLA',
    'CLASSE GLK',
    'CLASSE M',
    'CLASSE R',
    'CLASSE S',
    'CLASSE S A trois volumes',
    'CLASSE S Coupé',
    'CLASSE S Décapotable',
    'CLASSE X',
    'CLK',
    'CLK Décapotable',
    'CLS',
    'CLS Shooting Brake',
    'COUPE',
    'EQA',
    'EQB',
    'EQC',
    'EQE',
    'EQE SUV',
    'EQS',
    'EQS SUV',
    'EQT',
    'GLA',
    'GLB',
    'GLC',
    'GLC Coupe',
    'GLE',
    'GLE coupe',
    'GLS',
    'GULLWING',
    'HECKFLOSSE',
    'PAGODE',
    'PONTON',
    'PULLMANN',
    'SL',
    'SL Roadster',
    'SL coupé',
    'SLC',
    'SLK',
    'SLR',
    'SLR ROADSTER',
    'SLS AMG',
    'SLS AMG Roadster',
    'T-CLASS Monospace',
    'T2/L Véhicule municipal',
    'VANEO'
  ],
  Mini: [
    'MINI',
    'MINI CLUBMAN',
    'MINI COUNTRYMAN',
    'MINI Coupé',
    'MINI Décapotable',
    'MINI PACEMAN',
    'MINI Roadster'
  ],
  Nissan: [
    '100NX',
    '180SX Coupé',
    '200SX',
    '200SX Coupé',
    '240SX',
    '240Z',
    '260Z',
    '280ZX,ZXT',
    '300ZX',
    '300ZX Coupé',
    '350Z Coupé',
    '350Z Roadster',
    '370Z Coupé',
    '370Z Roadster',
    'ALMERA Classic',
    'ALMERA I',
    'ALMERA I Hatchback',
    'ALMERA II',
    'ALMERA II Hatchback',
    'ALMERA TINO',
    'ALTIMA',
    'ARIYA',
    'ARMADA',
    'AVENIR',
    'BLUEBIRD',
    'BLUEBIRD A trois volumes',
    'BLUEBIRD Break',
    'BLUEBIRD Coupé',
    'BLUEBIRD Hatchback',
    'BLUEBIRD SYLPHY / SYLPHY III',
    'BLUEBIRD SYLPHY II',
    'BLUEBIRD Traveller',
    'CEDRIC',
    'CEDRIC A trois volumes',
    'CEDRIC Hardtop',
    'CEFIRO II A trois volumes',
    'CEFIRO III A trois volumes',
    'CHERRY I',
    'CHERRY II Coupé',
    'CHERRY II Traveller',
    'CHERRY II hatchback',
    'CHERRY III',
    'CIMA',
    'CUBE',
    'DATSUN 100A',
    'DATSUN 100A A trois volumes',
    'DATSUN 100A Break',
    'DATSUN 100A Coupé',
    'DATSUN 120 A trois volumes',
    'DATSUN 120Y Coupé',
    'DATSUN 140J',
    'DATSUN 140Y Break',
    'DATSUN 160J',
    'DATSUN 180B',
    'DATSUN 240 coupé',
    'ELGRAND',
    'FIGARO Coupé',
    'GT-R',
    'JUKE',
    'LAUREL',
    'LEAF',
    'MAXIMA / MAXIMA QX IV',
    'MAXIMA / MAXIMA QX IV Station',
    'MAXIMA / MAXIMA QX V',
    'MAXIMA II',
    'MAXIMA III',
    'MAXIMA VI',
    'MAXIMA VII',
    'MICRA C+C III',
    'MICRA I',
    'MICRA II',
    'MICRA III',
    'MICRA IV',
    'MICRA V',
    'MURANO I',
    'MURANO II',
    'MURANO III',
    'NAVARA',
    'NOTE',
    'NP300 NAVARA',
    'NP300 NAVARA pick-up',
    'NP300 PICKUP',
    'PATHFINDER I',
    'PATHFINDER II',
    'PATHFINDER III',
    'PATHFINDER IV',
    'PATROL GR IV',
    'PATROL GR V Wagon',
    'PATROL III/1 Hardtop',
    'PATROL III/1 Station Wagon',
    'PATROL III/2 Hardtop',
    'PATROL III/2 Station Wagon',
    'PATROL VI',
    'PIXO',
    'PRAIRIE',
    'PRAIRIE LIBERTY',
    'PRAIRIE PRO',
    'PRESIDENT',
    'PRIMERA',
    'PRIMERA Break',
    'PRIMERA Hatchback',
    'PULSAR 3/5 portes',
    'Pick Up',
    'QASHQAI / QASHQAI +2 I',
    'QASHQAI II SUV',
    'QASHQAI III',
    'QUEST',
    'QUEST Mini Passenger Van',
    'ROGUE',
    'SENTRA V',
    'SENTRA VI',
    'SENTRA VII',
    'SERENA',
    'SILVIA',
    'SILVIA Coupé',
    'SKYLINE',
    'SKYLINE Coupé',
    'STAGEA',
    'STANZA',
    'STANZA A trois volumes',
    'STANZA Hatchback',
    'SUNNY',
    'SUNNY Break',
    'SUNNY I',
    'SUNNY I Break',
    'SUNNY I coupé',
    'SUNNY II',
    'SUNNY II Break',
    'SUNNY II Hatchback',
    'SUNNY II coupé',
    'SUNNY III',
    'SUNNY III Break',
    'SUNNY III Hatchback',
    'SUNNY III Liftback',
    'SUNNY IV A trois volumes',
    'TEANA I',
    'TEANA II',
    'TEANA III',
    'TERRANO',
    'TERRANO I',
    'TERRANO II',
    'TERRANO REGULUS',
    'TIIDA 3/5 portes',
    'TIIDA A trois volumes',
    'TOWNSTAR Monospace',
    'Titan',
    'WINGROAD / AD Wagon',
    'X-TRAIL I',
    'X-TRAIL II',
    'X-TRAIL III',
    'X-TRAIL IV',
    'XTERRA'
  ],
  Opel: [
    'ADAM',
    'ADMIRAL A',
    'ADMIRAL B',
    'AGILA',
    'AMPERA',
    'AMPERA-E',
    'ANTARA A',
    'ASCONA A',
    'ASCONA A Voyage',
    'ASCONA B',
    'ASCONA C',
    'ASCONA C 3/5 portes',
    'ASTRA F',
    'ASTRA F 3/5 portes',
    'ASTRA F Break',
    'ASTRA F CLASSIC 3/5 portes',
    'ASTRA F CLASSIC A trois volume',
    'ASTRA F CLASSIC Break',
    'ASTRA F Décapotable',
    'ASTRA G 3/5 portes',
    'ASTRA G A trois volumes',
    'ASTRA G Break',
    'ASTRA G CLASSIC',
    'ASTRA G CLASSIC A trois volume',
    'ASTRA G CLASSIC Caravan',
    'ASTRA G Décapotable',
    'ASTRA G coupé',
    'ASTRA H',
    'ASTRA H A trois volumes',
    'ASTRA H Break',
    'ASTRA H CLASSIC 3/5 portes',
    'ASTRA H CLASSIC A trois volume',
    'ASTRA H CLASSIC Break',
    'ASTRA H GTC',
    'ASTRA H TwinTop',
    'ASTRA J',
    'ASTRA J A trois volumes',
    'ASTRA J GTC',
    'ASTRA J Sports Tourer',
    'ASTRA K',
    'ASTRA K Sports Tourer',
    'ASTRA L',
    'ASTRA L Sports Tourer',
    'CALIBRA A',
    'CASCADA',
    'COMBO E Tour / Life',
    'COMBO Tour',
    'COMMODORE A',
    'COMMODORE A coupé',
    'COMMODORE B',
    'COMMODORE B coupé',
    'COMMODORE C',
    'COMMODORE C Break',
    'CORSA A 3/5 portes',
    'CORSA A TR',
    'CORSA B',
    'CORSA B Break',
    'CORSA C',
    'CORSA D',
    'CORSA E',
    'CORSA F',
    'CORSA UTILITY C Pick-up',
    'CROSSLAND X / CROSSLAND',
    'Campo',
    'DIPLOMAT A',
    'DIPLOMAT A coupé',
    'DIPLOMAT B',
    'FRONTERA A',
    'FRONTERA A Sport',
    'FRONTERA B',
    'GRANDLAND / GRANDLAND X',
    'GT',
    'GT Décapotable',
    'INSIGNIA A',
    'INSIGNIA A A trois volumes',
    'INSIGNIA A Country Tourer',
    'INSIGNIA A Sports Tourer',
    'INSIGNIA B Country Tourer',
    'INSIGNIA B Grand Sport',
    'INSIGNIA B Sports Tourer',
    'KADETT A',
    'KADETT A Break',
    'KADETT A coupé',
    'KADETT B',
    'KADETT B Break',
    'KADETT B coupé',
    'KADETT C',
    'KADETT C AERO',
    'KADETT C Break',
    'KADETT C City',
    'KADETT C coupé',
    'KADETT D',
    'KADETT D Break',
    'KADETT E',
    'KADETT E 3/5 portes',
    'KADETT E Break',
    'KADETT E Décapotable',
    'KAPITÄN',
    'KAPITÄN A',
    'KAPITÄN B',
    'KAPITÄN P',
    'KARL',
    'MANTA A',
    'MANTA B',
    'MANTA B CC',
    'MERIVA A Monospace',
    'MERIVA B Monospace',
    'MOKKA',
    'MOKKA / MOKKA X',
    'MONTEREY A',
    'MONTEREY B',
    'MONZA A',
    'OLYMPIA A',
    'OLYMPIA A coupé',
    'OLYMPIA REKORD',
    'OLYMPIA REKORD Break',
    'OMEGA A',
    'OMEGA A Break',
    'OMEGA B',
    'OMEGA B Break',
    'REKORD A',
    'REKORD A Break',
    'REKORD A coupé',
    'REKORD B',
    'REKORD B Break',
    'REKORD B coupé',
    'REKORD C',
    'REKORD C Break',
    'REKORD C coupé',
    'REKORD D',
    'REKORD D Break',
    'REKORD D coupé',
    'REKORD E',
    'REKORD E Break',
    'REKORD P1',
    'REKORD P1 Break',
    'REKORD P2',
    'REKORD P2 Break',
    'REKORD P2 coupé',
    'ROCKS-E',
    'SENATOR A',
    'SENATOR B',
    'SIGNUM 3/5 portes',
    'SINTRA',
    'SPEEDSTER',
    'TIGRA',
    'TIGRA TwinTop',
    'VECTRA A',
    'VECTRA A 3/5 portes',
    'VECTRA B',
    'VECTRA B 3/5 portes',
    'VECTRA B Break',
    'VECTRA C',
    'VECTRA C Break',
    'VECTRA C GTS',
    'ZAFIRA / ZAFIRA FAMILY B',
    'ZAFIRA A Monospace',
    'ZAFIRA TOURER C'
  ],
  Peugeot: [
    '1007',
    '104',
    '104 coupé',
    '106 I',
    '106 II',
    '107',
    '108',
    '2008 I',
    '2008 II',
    '204',
    '204 Break',
    '204 Décapotable',
    '204 coupé',
    '205 I',
    '205 I Décapotable',
    '205 II',
    '206 3/5 portes',
    '206 A trois volumes',
    '206 CC',
    '206 SW',
    '206+',
    '207 A trois volumes',
    '207 CC',
    '207 SW',
    '207/207+',
    '208 I',
    '208 II',
    '3008 Monospace',
    '3008 SUV',
    '301',
    '304',
    '304 Break',
    '304 Décapotable',
    '304 coupé',
    '305 I',
    '305 I Break',
    '305 II',
    '305 II Break',
    '306',
    '306 3/5 portes',
    '306 Break',
    '306 Décapotable',
    '307',
    '307 A trois volumes',
    '307 Break',
    '307 CC',
    '307 SW',
    '308 CC',
    '308 I',
    '308 II',
    '308 III',
    '308 SW I',
    '308 SW II',
    '308 SW III',
    '309 I',
    '309 II',
    '4007',
    '4008',
    '404',
    '404 Break',
    '404 Décapotable',
    '405 I',
    '405 I Break',
    '405 II',
    '405 II Break',
    '406',
    '406 Break',
    '406 coupé',
    '407',
    '407 SW',
    '407 coupé',
    '408',
    '408 II',
    '5008',
    '5008 II',
    '504',
    '504 Break',
    '504 Décapotable',
    '504 coupé',
    '504 pick-up',
    '505',
    '505 Break',
    '508 I',
    '508 II',
    '508 SW I',
    '508 SW II',
    '604',
    '605',
    '607',
    '806',
    '807',
    'BIPPER Tepee',
    'ION',
    'PARTNER Monospace',
    'PARTNER ORIGIN Monospace',
    'PARTNER Tepee',
    'RCZ',
    'RIFTER'
  ],
  Polaris: ['SLINGSHOT'],
  Renault: [
    '10',
    '11',
    '12',
    '12 Break',
    '12 TOROS A trois volumes',
    '12 TOROS Break',
    '14',
    '15',
    '16',
    '17',
    '18',
    '18 Break',
    '19 I',
    '19 I Cabriolet',
    '19 I Chamade',
    '19 II',
    '19 II Cabriolet',
    '19 II Chamade',
    '20',
    '21',
    '21 A trois volumes',
    '21 Break',
    '25',
    '30',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'ALASKAN',
    'ARKANA I',
    'AUSTRAL',
    'AVANTIME',
    'CAPTUR I',
    'CAPTUR II',
    'CLIO I',
    'CLIO II',
    'CLIO III',
    'CLIO III Grandtour',
    'CLIO IV',
    'CLIO IV Grandtour',
    'CLIO V',
    'DAUPHINE',
    'DUSTER',
    'ESPACE I',
    'ESPACE II',
    'ESPACE III',
    'ESPACE IV',
    'ESPACE V',
    'ESPACE VI',
    'EXPRESS Pick-up',
    'FLUENCE',
    'FUEGO',
    'GRAND SCÉNIC II',
    'GRAND SCÉNIC III',
    'GRAND SCÉNIC IV',
    'KADJAR',
    'KANGOO',
    'KANGOO / GRAND KANGOO II',
    'KANGOO BE BOP',
    'KANGOO III Monospace',
    'KAPTUR',
    'KOLEOS I',
    'KOLEOS II',
    'LAGUNA Coupé',
    'LAGUNA I',
    'LAGUNA I Nevada',
    'LAGUNA II',
    'LAGUNA II Grandtour',
    'LAGUNA III',
    'LAGUNA III Grandtour',
    'LATITUDE',
    'LODGY',
    'LOGAN I',
    'LOGAN I Break',
    'LOGAN II Break',
    'LOGAN/STEPWAY II',
    'MEGANE CC',
    'MEGANE E-TECH SUV',
    'MEGANE I',
    'MEGANE I Break',
    'MEGANE I Cabriolet',
    'MEGANE I Classic',
    'MEGANE I Coupé',
    'MEGANE II',
    'MEGANE II A trois volumes',
    'MEGANE II Break',
    'MEGANE II Coupé-Cabriolet',
    'MEGANE III 3/5 portes',
    'MEGANE III Coupé',
    'MEGANE III Grandtour',
    'MEGANE IV 3/5 portes',
    'MEGANE IV Estate',
    'MEGANE IV Grand Coupe',
    'MEGANE Scenic',
    'MODUS / GRAND MODUS',
    'RAFALE Coupe',
    'RODEO 4',
    'RODEO 5',
    'RODEO 6',
    'SAFRANE I',
    'SAFRANE II',
    'SANDERO/STEPWAY I',
    'SANDERO/STEPWAY II',
    'SCÉNIC I Monospace',
    'SCÉNIC II',
    'SCÉNIC III',
    'SCÉNIC IV',
    'SPORT SPIDER',
    'SUPER 5',
    'TALISMAN',
    'TALISMAN Grandtour',
    'THALIA I',
    'THALIA II',
    'THALIA III',
    'TWINGO I',
    'TWINGO II',
    'TWINGO III',
    'TWIZY',
    'VEL SATIS',
    'WIND',
    'ZOE'
  ],
  Seat: [
    '124',
    '124 Break',
    '127',
    '128',
    '131',
    '132',
    '133',
    '600',
    '850',
    'ALHAMBRA',
    'ALTEA',
    'ALTEA XL',
    'ARONA',
    'AROSA',
    'ATECA',
    'CORDOBA',
    'CORDOBA Vario',
    'EXEO',
    'EXEO ST',
    'FURA',
    'IBIZA I',
    'IBIZA II',
    'IBIZA III',
    'IBIZA IV',
    'IBIZA IV SC',
    'IBIZA IV ST',
    'IBIZA V',
    'LEON',
    'LEON SC',
    'LEON ST',
    'LEON Sportstourer',
    'MALAGA',
    'MARBELLA',
    'Mii',
    'PANDA',
    'RITMO',
    'RONDA',
    'TARRACO',
    'TERRA',
    'TOLEDO I',
    'TOLEDO II',
    'TOLEDO III',
    'TOLEDO IV'
  ],
  Skoda: [
    '100',
    '1000MB',
    '105,120',
    '110',
    '110 coupé',
    '1100MB',
    '130',
    'CITIGO',
    'E-CITIGO',
    'ENYAQ iV Coupe',
    'ENYAQ iV SUV',
    'FABIA I',
    'FABIA I A trois volumes',
    'FABIA I Combi',
    'FABIA II',
    'FABIA II Combi',
    'FABIA III',
    'FABIA III Break',
    'FABIA IV',
    'FAVORIT',
    'FAVORIT Forman',
    'FAVORIT pick-up',
    'FELICIA I',
    'FELICIA I Break',
    'FELICIA I Pick-up',
    'FELICIA II',
    'FELICIA II Break',
    'KAMIQ',
    'KAROQ',
    'KODIAQ',
    'OCTAVIA',
    'OCTAVIA I',
    'OCTAVIA I Combi',
    'OCTAVIA II',
    'OCTAVIA II Combi',
    'OCTAVIA III',
    'OCTAVIA III Combi',
    'OCTAVIA IV',
    'OCTAVIA IV Combi',
    'RAPID',
    'RAPID Spaceback',
    'ROOMSTER',
    'SCALA',
    'SUPERB I',
    'SUPERB II',
    'SUPERB II Break',
    'SUPERB III',
    'SUPERB III Break',
    'YETI'
  ],
  Subaru: [
    'BRZ',
    'FORESTER',
    'IMPREZA 3/5 portes',
    'IMPREZA A trois volumes',
    'IMPREZA Break',
    'IMPREZA coupé',
    'JUSTY I',
    'JUSTY II',
    'JUSTY III',
    'JUSTY IV',
    'LEGACY I',
    'LEGACY I Break',
    'LEGACY II',
    'LEGACY II Break',
    'LEGACY III',
    'LEGACY III Break',
    'LEGACY IV',
    'LEGACY IV Break',
    'LEGACY OUTBACK',
    'LEGACY V',
    'LEGACY V Break',
    'LEGACY VI',
    'LEONE / LOYALE Coupé',
    'LEONE 3/5 portes',
    'LEONE II',
    'LEONE II Break',
    'LEONE II Hatchback',
    'LEONE III',
    'LEONE III Break',
    'LEVORG',
    'MV',
    'OUTBACK',
    'REX I',
    'REX II',
    'REX III',
    'SOLTERRA',
    'SVX',
    'TREZIA',
    'TRIBECA',
    'VIVIO',
    'WRX A trois volumes',
    'XT Coupé',
    'XV'
  ],
  Tesla: ['MODEL 3', 'MODEL S', 'MODEL X', 'MODEL Y', 'ROADSTER'],
  Toyota: [
    '1000',
    '1000 Break',
    '4 RUNNER I',
    '4 RUNNER II',
    '4 RUNNER III',
    '4 RUNNER IV',
    '4 RUNNER V',
    'ALLION I',
    'ALLION II',
    'ALPHARD / VELLFIRE',
    'ALPHARD I',
    'ALTEZZA',
    'ALTEZZA GITA Break',
    'ARISTO',
    'AURION',
    'AURIS',
    'AURIS Break',
    'AVALON A trois volumes',
    'AVENSIS',
    'AVENSIS A trois volumes',
    'AVENSIS Break',
    'AVENSIS Liftback',
    'AVENSIS VERSO',
    'AYGO',
    'AYGO X',
    'BREVIS',
    'C-HR',
    'CALDINA Break',
    'CAMI Véhicule tout terrain fer',
    'CAMRY',
    'CAMRY A trois volumes',
    'CAMRY Break',
    'CAMRY Liftback',
    'CARIBE Break',
    'CARINA E VI',
    'CARINA E VI A trois volumes',
    'CARINA E VI Sportswagon',
    'CARINA I',
    'CARINA II',
    'CARINA III Break',
    'CARINA IV',
    'CARINA IV A trois volumes',
    'CARINA V',
    'CARINA V A trois volumes',
    'CARINA V Break',
    'CARINA VII A trois volumes',
    'CAVALIER coupé',
    'CELICA',
    'CELICA 3/5 portes',
    'CELICA Décapotable',
    'CELICA coupé',
    'CELSIOR',
    'CENTURY',
    'CHASER',
    'COROLLA',
    'COROLLA 3/5 portes',
    'COROLLA A trois volumes',
    'COROLLA Break',
    'COROLLA Compact',
    'COROLLA Cross',
    'COROLLA FX Compact',
    'COROLLA Liftback',
    'COROLLA Station wagon',
    'COROLLA Verso',
    'COROLLA coupé',
    'CORONA',
    'CORONA 3/5 portes',
    'CORONA A trois volumes',
    'CORONA Break',
    'CORONA EXIV A trois volumes',
    'CORONA Liftback',
    'CRESSIDA A trois volumes',
    'CRESSIDA Break',
    'CRESSIDA Station wagon',
    'CRESTA V A trois volumes',
    'CROWN',
    'CROWN Break',
    'CROWN Station Wagon',
    'DUET',
    'ECHO A trois volumes',
    'FJ CRUISER',
    'FORTUNER',
    'GAIA',
    'GR 86 Coupé',
    'GT 86 Coupé',
    'HARRIER',
    'HIACE I Pick-up',
    'HIACE II Pick-up',
    'HIGHLANDER',
    'HIGHLANDER / KLUGER',
    'HILUX II SUV',
    'HILUX III pick-up',
    'HILUX IV Pick-up',
    'HILUX V Pick-up',
    'HILUX VI Pick-up',
    'HILUX VII pick-up',
    'HILUX VIII Pick-up',
    'IQ',
    'ISIS',
    'IST',
    'LAND CRUISER',
    'LAND CRUISER 100',
    'LAND CRUISER 200',
    'LAND CRUISER 300',
    'LAND CRUISER 80',
    'LAND CRUISER 90',
    'LAND CRUISER Hardtop',
    'LAND CRUISER PRADO',
    'LAND CRUISER Softtop',
    'LAND CRUISER pick-up',
    'MARK II Break',
    'MARK II IX A trois volumes',
    'MARK II VI A trois volumes',
    'MARK II VIII A trois volumes',
    'MARK X I',
    'MARK X II',
    'MARK X ZIO I',
    'MATRIX',
    'MIRAI',
    'MR III',
    'MR2 I',
    'MR2 II',
    'NADIA',
    'NOAH/VOXY',
    'OPA',
    'PASEO Coupé',
    'PASEO Décapotable',
    'PICNIC',
    'PLATZ',
    'PORTE I',
    'PREMIO',
    'PREVIA I',
    'PREVIA II',
    'PREVIA III',
    'PRIUS',
    'PRIUS A trois volumes',
    'PRIUS Liftback',
    'PRIUS PHV',
    'PRIUS PLUS',
    'PROACE CITY VERSO Monospace',
    'PROBOX / SUCCEED',
    'PROGRES',
    'RACTIS',
    'RAUM Monospace',
    'RAV 4 I',
    'RAV 4 I Cabrio',
    'RAV 4 II',
    'RAV 4 III',
    'RAV 4 IV',
    'RAV 4 V',
    'RUSH Véhicule tout terrain fer',
    'SCEPTER A trois volumes',
    'SCEPTER Break',
    'SEQUOIA',
    'SIENNA',
    'SOLARA Coupé',
    'SOLARA Décapotable',
    'SPRINTER A trois volumes',
    'SPRINTER CARIB Break',
    'SPRINTER Coupé',
    'STARLET',
    'STARLET 1000',
    'STARLET 1000 Kombi',
    'STARLET Break',
    'SUPRA',
    'TACOMA Pick-up',
    'TERCEL',
    'TERCEL 3/5 portes',
    'TERCEL A trois volumes',
    'TERCEL Break',
    'TUNDRA pick-up',
    'URBAN CRUISER',
    'VENZA',
    'VEROSSA',
    'VERSO',
    'VERSO S',
    'VIOS / SOLUNA VIOS',
    'VISTA / CAMRY A trois volumes',
    'WILL CYPHA I',
    'WISH Monospace',
    'YARIS',
    'YARIS / VIOS A trois volumes',
    'YARIS CROSS',
    'YARIS VERSO',
    'bB I',
    'bB II',
    'bZ4X'
  ],
  Volkswagen: [
    '1500,1600',
    '1500,1600 3/5 portes',
    '1500,1600 Variant',
    '166',
    '181',
    '411,412',
    '412 Variant',
    'AMAROK',
    'ARTEON',
    'ARTEON SHOOTING BRAKE',
    'ATLAS',
    'BEETLE',
    'BEETLE Décapotable',
    'BORA',
    'BORA I',
    'BORA Variant',
    'CADDY ALLTRACK Monospace',
    'CADDY CALIFORNIA V Camper',
    'CADDY I',
    'CADDY II Monospace',
    'CADDY II pick-up',
    'CADDY III Monospace',
    'CADDY IV Monospace',
    'CADDY V Monospace',
    'CC B7',
    'COCCINELLE',
    'COCCINELLE Décapotable',
    'CORRADO',
    'DERBY',
    'EOS',
    'FLIGHT III CLASSIC',
    'FOX 3/5 portes',
    'GOLF ALLTRACK VII Variant',
    'GOLF ALLTRACK VIII',
    'GOLF I',
    'GOLF I Cabriolet',
    'GOLF II',
    'GOLF III',
    'GOLF III Cabriolet',
    'GOLF III Variant',
    'GOLF IV',
    'GOLF IV Cabriolet',
    'GOLF IV Variant',
    'GOLF PLUS V',
    'GOLF SPORTSVAN VII',
    'GOLF V',
    'GOLF V Variant',
    'GOLF VI',
    'GOLF VI Décapotable',
    'GOLF VI Variant',
    'GOLF VII',
    'GOLF VII Variant',
    'GOLF VIII',
    'GOLF VIII Variant',
    'ID.3',
    'ID.4',
    'ID.5',
    'ILTIS',
    'JETTA I',
    'JETTA II',
    'JETTA III',
    'JETTA IV',
    'JETTA VII A trois volumes',
    'K 70',
    'KARMANN GHIA Décapotable',
    'KARMANN GHIA coupé',
    'LUPO I',
    'NEW BEETLE',
    'NEW BEETLE Décapotable',
    'PASSAT ALLTRACK B7',
    'PASSAT ALLTRACK B8 Variant',
    'PASSAT B1',
    'PASSAT B1 A trois volumes',
    'PASSAT B1 Variant',
    'PASSAT B2',
    'PASSAT B2 A trois volumes',
    'PASSAT B2 Variant',
    'PASSAT B3/B4',
    'PASSAT B3/B4 Variant',
    'PASSAT B5',
    'PASSAT B5 Variant',
    'PASSAT B5.5',
    'PASSAT B5.5 Variant',
    'PASSAT B6',
    'PASSAT B6 Variant',
    'PASSAT B7',
    'PASSAT B7 Variant',
    'PASSAT B8',
    'PASSAT B8 Variant',
    'PASSAT CC B6',
    'PHAETON',
    'POLO',
    'POLO CLASSIC',
    'POLO II',
    'POLO III',
    'POLO IV A trois volumes',
    'POLO PLAYA',
    'POLO V',
    'POLO V A trois volumes',
    'POLO VI',
    'POLO VIVO 3/5 portes',
    'POLO Variant',
    'POLO coupé',
    'QUANTUM Variant',
    'ROUTAN',
    'SANTANA',
    'SAVEIRO I',
    'SCIROCCO',
    'SCIROCCO III',
    'SHARAN',
    'T-CROSS',
    'T-ROC',
    'T-ROC Décapotable',
    'TAIGO',
    'TAOS',
    'TARO',
    'TIGUAN',
    'TIGUAN ALLSPACE',
    'TOUAREG',
    'TOURAN',
    'UP!',
    'VARIANT I Variant',
    'VARIANT II',
    'VENTO',
    'XL1'
  ],
  Volvo: [
    '140',
    '140 Break',
    '164',
    '240',
    '240 Break',
    '260',
    '260 Break',
    '260 coupé',
    '340-360',
    '340-360 A trois volumes',
    '440',
    '460',
    '480',
    '66',
    '66 Break',
    '740',
    '740 Break',
    '760',
    '760 Break',
    '780',
    '850',
    '850 Break',
    '940',
    '940 Break',
    '940 II',
    '940 II Break',
    '960',
    '960 Break',
    '960 II',
    '960 II Break',
    'C30',
    'C40',
    'C70 I cabriolet',
    'C70 I coupé',
    'C70 II Décapotable',
    'EX30',
    'EX90',
    'P 121',
    'P 122 S AMAZON',
    'P 122 S AMAZON Break',
    'P 1800',
    'P 210 DUETT',
    'P 2200 Break',
    'PV 444 A trois volumes',
    'PV 445 DUETT',
    'PV 544',
    'S40 I',
    'S40 II',
    'S60 I',
    'S60 II',
    'S60 II Cross Country',
    'S60 III',
    'S70',
    'S80 I',
    'S80 II',
    'S90 I',
    'S90 II',
    'V40 3/5 portes',
    'V40 Break',
    'V40 Cross Country',
    'V50',
    'V60 I',
    'V60 I Cross Country',
    'V60 II',
    'V60 II Cross Country',
    'V70 I',
    'V70 II',
    'V70 III',
    'V70 XC I Cross Country',
    'V90 I Break',
    'V90 II Break',
    'V90 II Cross Country',
    'XC40',
    'XC60 I SUV',
    'XC60 II',
    'XC70 II',
    'XC90 I',
    'XC90 II'
  ],
  Suzuki: [
    "Across",
    "Alto",
    "Baleno",
    "Carry",
    "Celerio",
    "Grand Vitara",
    "Grand Vitara Xl-7",
    "Ignis",
    "Jimny",
    "Kizashi",
    "Liana",
    "S-cross",
    "Samurai",
    "Splash",
    "Sx4",
    "Sx4 S-cross",
    "Swace",
    "Swift",
    "Vitara",
    "Wagon R+",
    "X90"
  ],
  Chevrolet: [
    "G-Van",
    "HHR",
    "Impala",
    "K1500",
    "KALOS",
    "LACETTI",
    "Lumina",
    "MALIBU",
    "MATIZ",
    "Monte Carlo",
    "Nova",
    "NUBIRA",
    "ORLANDO",
    "Silverado",
    "SPARK",
    "SSR",
    "Suburban",
    "TACUMA",
    "TAHOE",
    "TRAILBLAZER",
    "TRANSSPORT",
    "TRAX",
    "Venture",
    "VOLT"
  ],
  Jaguar: [
    "E-Pace",
    "F-Pace",
    "F-Type",
    "I-Pace",
    "S-Type",
    "X-Type",
    "XE",
    "XF",
    "XJ",
    "XK"
  ]
}
