import { gql } from '@apollo/client'

export const generate2FAGql = gql`
  mutation generate2FA {
    generate2FA {
      uri
      secret
    }
  }
`
