import React, { useEffect } from 'react';
import './slider.component.scss';

export declare namespace SliderType {
  type Props = {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    config?: // | {
    //     type?: 'slick';
    //     dots?: boolean;
    //     slidesToShow?: SliderType.Config.SlidesToShow;
    //     slidesToScroll?: SliderType.Config.SlidesToScroll;
    //   }
    {
      type?: 'drag';
    };
  };

  namespace Config {
    type SlidesToShow = number;
    type SlidesToScroll = number;
  }
}

export const Slider = ({
  className = '',
  children,
  config = { type: 'drag' },
}: SliderType.Props) => {
  //! Slider - Drag de droite à gauche - Sans flèche, ni dots
  //! Slider - Flèche et dots infini - Possibilité de paramamétrer - (slidesToShow, slidesToScroll)
  // const slickSettings = useMemo((): Record<string, any> => {
  //   if (config?.type === 'slick') {
  //     const childrenFormatted = Array.isArray(children) ? children : [children];

  //     return {
  //       rtl: false,
  //       dots: config?.dots,
  //       infinite: false,
  //       speed: 500,
  //       slidesToShow:
  //         config?.slidesToShow || childrenFormatted.length > 3
  //           ? 3
  //           : childrenFormatted.length,
  //       slidesToScroll: config?.slidesToScroll,
  //       prevArrow: (
  //         <div
  //           className="slider__contain__arrow"
  //           onClick={() => console.log('prev')}
  //         >
  //           <Icon
  //             config={{
  //               type: 'faArrowLeftSolid',
  //               size: 'xxlarge',
  //               color: 'primary',
  //             }}
  //           />
  //         </div>
  //       ),
  //       nextArrow: (
  //         <div className="slider__contain__arrow">
  //           <Icon
  //             config={{
  //               type: 'faArrowRightSolid',
  //               size: 'xxlarge',
  //               color: 'primary',
  //             }}
  //           />
  //         </div>
  //       ),
  //     };
  //   } else {
  //     return {};
  //   }
  // }, []);

  useEffect(() => {
    if (config?.type === 'drag') {
      let isDown = false;
      let startX: number;
      let scrollLeft: number;
      const slider = document.querySelector(
        '.slider__draggable',
      ) as HTMLDivElement;

      const end = () => {
        if (slider) {
          isDown = false;
          slider.classList.remove('active');
        }
      };

      const start = (e: any) => {
        if (slider) {
          isDown = true;
          slider.classList.add('active');
          startX = e?.pageX || e?.touches[0].pageX - slider.offsetLeft;
          scrollLeft = slider?.scrollLeft;
        }
      };

      const move = (e: any) => {
        if (slider) {
          if (!isDown) return;
          e.preventDefault();
          const x = e?.pageX || e.touches[0].pageX - slider.offsetLeft;
          const dist = x - startX;
          slider.scrollLeft = scrollLeft - dist;
        }
      };

      slider.addEventListener('mousedown', start);
      slider.addEventListener('touchstart', start);
      slider.addEventListener('mousemove', move);
      slider.addEventListener('touchmove', move);
      slider.addEventListener('mouseleave', end);
      slider.addEventListener('mouseup', end);
      slider.addEventListener('touchend', end);

      return () => {
        slider.removeEventListener('mousedown', start);
        slider.removeEventListener('touchstart', start);
        slider.removeEventListener('mousemove', move);
        slider.removeEventListener('touchmove', move);
        slider.removeEventListener('mouseleave', end);
        slider.removeEventListener('mouseup', end);
        slider.removeEventListener('touchend', end);
      };
    }
  }, []);

  //! SI 1 alors
  return (
    <div className={`slider ${className}`}>
      {/* <div className="slider__slick">
        {slickSettings?.slidesToShow > 1 ? (
          <SliderSlick {...settings}>{children}</SliderSlick>
        ) : (
          <div className="slider__slick__single">{children}</div>
        )}
      </div> */}
      <div className="slider__draggable">
        {(Array.isArray(children) ? children : [children]).map(
          (element, index) => (
            <div
              key={`slider__draggable__item-${index}`}
              className="slider__draggable__item"
            >
              {element}
            </div>
          ),
        )}
      </div>
    </div>
  );
};
